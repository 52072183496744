import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import UserModalComponent from "./UserModalComponent";
import ClientModalComponent from "./ClientModalComponent";
import CategoriesModalComponent from "./CategoriesModalComponent";

const AddAndEditModal = ({
  userTitle,
  values,
  setValues,
  clients,
  onSelect,
  handleAddEntity,
  handleEditEntity,
  handleEditUser,
  open,
  setOpen,
  backgroundColor,
  handleEditCategory,
  inititalValues,
}) => {
  const theme = useTheme();
  const [countries, setCountries] = useState(values?.data?.countries || []);
  const [formChanged, setFormChanged] = useState(false);

  console.log(values);

  const handleInputChange = (e, property) => {
    const value = e.target.value;
    console.log(`handleInputChange - property: ${property}, value: ${value}`);
    setValues((prev) => {
      if (property === "data.countries") {
        let newCountries = [...prev.data.countries];
        if (newCountries.includes(value)) {
          newCountries = newCountries.filter((country) => country !== value);
        } else {
          newCountries.push(value);
        }
        console.log("Updated countries:", newCountries);
        return { ...prev, data: { ...prev.data, countries: newCountries } };
      } else {
        const updatedValues = { ...prev, [property]: value };
        console.log("Updated values:", updatedValues);
        return updatedValues;
      }
    });
    setFormChanged(true);
  };

  const handleChangeRole = (event) => {
    setValues((prev) => ({ ...prev, role: event.target.value }));
    setFormChanged(true);
  };

  const handleChangeCompany = (event) => {
    const selectedClient = clients.find((c) => c.name === event.target.value);
    setValues((prev) => ({ ...prev, company: selectedClient.name }));
    onSelect && onSelect(selectedClient.id);
    setFormChanged(true);
  };

  const handleChangeClient = (event) => {
    const selectedClient = clients.find((c) => c.id === event.target.value);
    setValues((prev) => ({
      ...prev,
      clientId: selectedClient.id,
      country: "",
      data: selectedClient.data || { countries: [] },
      type: selectedClient.type || "",
    }));
    setCountries(selectedClient.data?.countries || []);
    setFormChanged(true);
  };

  if (userTitle === "User Profile") {
    // Render UserModalComponent as a normal component (not a modal)
    return (
      <UserModalComponent
        userTitle={userTitle}
        values={values}
        setValues={setValues}
        clients={clients}
        onSelect={onSelect}
        handleAddEntity={handleAddEntity}
        handleEditUser={handleEditUser}
        backgroundColor={backgroundColor}
        handleInputChange={handleInputChange}
        formChanged={formChanged}
        setFormChanged={setFormChanged}
        isModal={false} // Indicate non-modal rendering
      />
    );
  }

  if (userTitle === "Edit User" || userTitle === "Add User") {
    return (
      <UserModalComponent
        userTitle={userTitle}
        values={values}
        setValues={setValues}
        clients={clients}
        onSelect={onSelect}
        handleAddEntity={handleAddEntity}
        handleEditUser={handleEditUser}
        open={open}
        setOpen={setOpen}
        backgroundColor={backgroundColor}
        handleInputChange={handleInputChange}
        formChanged={formChanged}
        setFormChanged={setFormChanged}
        isModal={true} // Indicate modal rendering
      />
    );
  }

  if (userTitle === "Add Client" || userTitle === "Edit Client") {
    return (
      <ClientModalComponent
        userTitle={userTitle}
        values={values}
        setValues={setValues}
        clients={clients}
        open={open}
        setOpen={setOpen}
        backgroundColor={backgroundColor}
        handleInputChange={handleInputChange}
        formChanged={formChanged}
        setFormChanged={setFormChanged}
        handleAddEntity={handleAddEntity}
        handleEditEntity={handleEditEntity}
      />
    );
  }

  if (userTitle === "Add Category" || userTitle === "Edit Category") {
    return (
      <CategoriesModalComponent
        userTitle={userTitle}
        values={values}
        setValues={setValues}
        open={open}
        setOpen={setOpen}
        backgroundColor={backgroundColor}
        handleAddEntity={handleAddEntity}
        handleEditCategory={handleEditCategory}
        inititalValues={inititalValues}
      />
    );
  }

  return null;
};

export default AddAndEditModal;
