import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  TextField,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { editAd } from "../../features/adSlice";

const EffectsSidebar = ({
  values,
  selectedResolution,
  selectedParent,
  editState,
}) => {
  const dispatch = useDispatch();
  const existingAd = useSelector(
    (state) => state.ads.find((ad) => ad.id === selectedParent?.id) || {}
  );

  const [localValues, setLocalValues] = useState({
    inAnimation: {
      effectName: "",
      direction: "",
      duration: "",
      stiffness: "",
      damping: "",
    },
    outAnimation: {
      effectName: "",
      direction: "",
      duration: "",
      stiffness: "",
      damping: "",
    },
  });

  useEffect(() => {
    if (selectedParent) {
      const group = selectedParent.group;
      const inAnim = values.positions[group]?.[selectedResolution]?.animation
        ?.inAnimation || {
        effectName: "",
        direction: "",
        duration: "",
        stiffness: "",
        damping: "",
      };
      const outAnim = values.positions[group]?.[selectedResolution]?.animation
        ?.outAnimation || {
        effectName: "",
        direction: "",
        duration: "",
        stiffness: "",
        damping: "",
      };
      setLocalValues({ inAnimation: inAnim, outAnimation: outAnim });
    }
  }, [selectedParent, selectedResolution, values]);

  const handleInputChange = (animationType, field) => (event) => {
    const newLocalValues = {
      ...localValues,
      [animationType]: {
        ...localValues[animationType],
        [field]: event.target.value,
      },
    };
    setLocalValues(newLocalValues);

    // Update global state after a delay
    clearTimeout(handleInputChange.timeout);
    handleInputChange.timeout = setTimeout(() => {
      const group = selectedParent.group;
      const newPositions = { ...values.positions };

      if (editState) {
        // Update all resolutions if editState is true
        values.resolutions.forEach((resolution) => {
          if (!newPositions[group]) {
            newPositions[group] = {};
          }
          if (!newPositions[group][resolution]) {
            newPositions[group][resolution] = {
              animation: { inAnimation: {}, outAnimation: {} },
            };
          } else if (!newPositions[group][resolution].animation) {
            newPositions[group][resolution].animation = {
              inAnimation: {},
              outAnimation: {},
            };
          }

          const animation = newPositions[group][resolution].animation;
          animation[animationType][field] = event.target.value;
        });
      } else {
        // Update only the selected resolution
        if (!newPositions[group]) {
          newPositions[group] = {};
        }
        if (!newPositions[group][selectedResolution]) {
          newPositions[group][selectedResolution] = {
            animation: { inAnimation: {}, outAnimation: {} },
          };
        } else if (!newPositions[group][selectedResolution].animation) {
          newPositions[group][selectedResolution].animation = {
            inAnimation: {},
            outAnimation: {},
          };
        }

        const animation = newPositions[group][selectedResolution].animation;
        animation[animationType][field] = event.target.value;
      }

      // Dispatch the action to update the ad
      dispatch(editAd({ id: existingAd.id, positions: newPositions }));

      // Log the updated values
      console.log(
        "Updated animation values:",
        newPositions[group][selectedResolution].animation
      );
    }, 500);
  };

  return (
    <Box
      sx={{
        width: "200px",
        height: "60%",
        position: "absolute",
        top: 60,
        left: 80,
        zIndex: 0,
        p: 1,
      }}
    >
      <Card
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography variant='h6' sx={{ fontSize: "0.8rem", mb: 1 }}>
          Edit Animations
        </Typography>
        <Grid container spacing={1}>
          {localValues.inAnimation && (
            <Grid item xs={6}>
              <Typography
                variant='subtitle2'
                sx={{ fontSize: "0.7rem", mb: 1 }}
              >
                In Animation
              </Typography>
              {["effectName", "duration", "stiffness", "damping"].map(
                (field) => (
                  <TextField
                    key={field}
                    label={field.charAt(0).toUpperCase() + field.slice(1)}
                    value={localValues.inAnimation[field] || ""}
                    onChange={handleInputChange("inAnimation", field)}
                    margin='dense'
                    fullWidth
                    InputLabelProps={{ style: { fontSize: "0.7rem" } }}
                    inputProps={{
                      style: { fontSize: "0.7rem", height: "20px" },
                    }}
                    variant='standard'
                  />
                )
              )}
              <FormControl margin='dense' fullWidth variant='standard'>
                <InputLabel sx={{ fontSize: "0.7rem" }}>Direction</InputLabel>
                <Select
                  label='Direction'
                  value={localValues.inAnimation.direction || ""}
                  onChange={handleInputChange("inAnimation", "direction")}
                  inputProps={{ style: { fontSize: "0.7rem", height: "20px" } }}
                  sx={{ fontSize: "0.7rem" }}
                >
                  <MenuItem value=''>
                    <em style={{ fontSize: "0.7rem" }}>None</em>
                  </MenuItem>
                  <MenuItem value='up' sx={{ fontSize: "0.7rem" }}>
                    Up
                  </MenuItem>
                  <MenuItem value='down' sx={{ fontSize: "0.7rem" }}>
                    Down
                  </MenuItem>
                  <MenuItem value='left' sx={{ fontSize: "0.7rem" }}>
                    Left
                  </MenuItem>
                  <MenuItem value='right' sx={{ fontSize: "0.7rem" }}>
                    Right
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          {localValues.outAnimation && (
            <Grid item xs={6}>
              <Typography
                variant='subtitle2'
                sx={{ fontSize: "0.7rem", mb: 1 }}
              >
                Out Animation
              </Typography>
              {["effectName", "duration", "stiffness", "damping"].map(
                (field) => (
                  <TextField
                    key={field}
                    label={field.charAt(0).toUpperCase() + field.slice(1)}
                    value={localValues.outAnimation[field] || ""}
                    onChange={handleInputChange("outAnimation", field)}
                    margin='dense'
                    fullWidth
                    InputLabelProps={{ style: { fontSize: "0.7rem" } }}
                    inputProps={{
                      style: { fontSize: "0.7rem", height: "20px" },
                    }}
                    variant='standard'
                  />
                )
              )}
              <FormControl margin='dense' fullWidth variant='standard'>
                <InputLabel sx={{ fontSize: "0.7rem" }}>Direction</InputLabel>
                <Select
                  label='Direction'
                  value={localValues.outAnimation.direction || ""}
                  onChange={handleInputChange("outAnimation", "direction")}
                  inputProps={{ style: { fontSize: "0.7rem", height: "20px" } }}
                  sx={{ fontSize: "0.7rem" }}
                >
                  <MenuItem value=''>
                    <em style={{ fontSize: "0.7rem" }}>None</em>
                  </MenuItem>
                  <MenuItem value='up' sx={{ fontSize: "0.7rem" }}>
                    Up
                  </MenuItem>
                  <MenuItem value='down' sx={{ fontSize: "0.7rem" }}>
                    Down
                  </MenuItem>
                  <MenuItem value='left' sx={{ fontSize: "0.7rem" }}>
                    Left
                  </MenuItem>
                  <MenuItem value='right' sx={{ fontSize: "0.7rem" }}>
                    Right
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Card>
    </Box>
  );
};

export default EffectsSidebar;
