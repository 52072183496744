import React, { useState, useEffect } from "react";
import {
  Switch,
  FormControlLabel,
  TextField,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axiosInstance";
import { CLIENTS_URL } from "../../../config";
import { editClient } from "../../clients/features/clientSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const FigmaSettings = ({ currentUser }) => {
  const ClientsList = useSelector((store) => store.clients);
  const [isFigmaEnabled, setIsFigmaEnabled] = useState(false);
  const [figmaClientId, setFigmaClientId] = useState("");
  const [figmaClientSecret, setFigmaClientSecret] = useState("");
  const [figmaAccessToken, setFigmaAccessToken] = useState("");
  const [figmaApiUrl, setFigmaApiUrl] = useState("");
  const [figmaFileKey, setFigmaFileKey] = useState("");
  const [showFigmaClientId, setShowFigmaClientId] = useState(false);
  const [showFigmaClientSecret, setShowFigmaClientSecret] = useState(false);
  const [showFigmaAccessToken, setShowFigmaAccessToken] = useState(false);
  const [showFigmaApiUrl, setShowFigmaApiUrl] = useState(false);
  const [showFigmaFileKey, setShowFigmaFileKey] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const clientId = currentUser?.currentUser?.clientIdUsers;

  useEffect(() => {
    const client = ClientsList.find((c) => c.id === clientId);
    if (client?.settings?.figma) {
      const settings = client.settings.figma;
      setIsFigmaEnabled(settings.isFigmaEnabled || false);
      setFigmaClientId(settings.figmaClientId || "");
      setFigmaClientSecret(settings.figmaClientSecret || "");
      setFigmaAccessToken(settings.figmaAccessToken || "");
      setFigmaApiUrl(settings.figmaApiUrl || "https://api.figma.com/v1/files/");
      setFigmaFileKey(settings.figmaFileKey || "3IAuR8TR9uPEawWo4NXY3n");
    }
  }, [ClientsList, clientId]);

  const handleFigmaToggle = (event) => {
    setIsFigmaEnabled(event.target.checked);
  };

  const handleFigmaClientIdChange = (event) => {
    setFigmaClientId(event.target.value);
  };

  const handleFigmaClientSecretChange = (event) => {
    setFigmaClientSecret(event.target.value);
  };

  const handleFigmaAccessTokenChange = (event) => {
    setFigmaAccessToken(event.target.value);
  };

  const handleFigmaApiUrlChange = (event) => {
    setFigmaApiUrl(event.target.value);
  };

  const handleFigmaFileKeyChange = (event) => {
    setFigmaFileKey(event.target.value);
  };

  const updateClientSettings = async () => {
    const payload = {
      figma: {
        isFigmaEnabled,
        figmaClientId,
        figmaClientSecret,
        figmaAccessToken,
        figmaApiUrl,
        figmaFileKey,
      },
    };

    try {
      const response = await axiosInstance.put(
        `${CLIENTS_URL}/${clientId}/settings/`,
        payload
      );

      const updatedClient = {
        id: clientId,
        settings: {
          ...ClientsList.find((c) => c.id === clientId).settings,
          figma: {
            isFigmaEnabled: response.data.figma.isFigmaEnabled,
            figmaClientId: response.data.figma.figmaClientId,
            figmaClientSecret: response.data.figma.figmaClientSecret,
            figmaAccessToken: response.data.figma.figmaAccessToken,
            figmaApiUrl: response.data.figma.figmaApiUrl,
            figmaFileKey: response.data.figma.figmaFileKey,
          },
        },
      };

      dispatch(editClient(updatedClient));
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating settings", error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={isFigmaEnabled}
            onChange={handleFigmaToggle}
            size='small'
          />
        }
        label='Figma'
        sx={{
          "& .MuiTypography-body1": { fontSize: "0.875rem" },
        }}
      />
      {isFigmaEnabled && (
        <>
          <TextField
            label='Figma Client ID'
            variant='outlined'
            fullWidth
            size='small'
            type={showFigmaClientId ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowFigmaClientId(!showFigmaClientId)}
                  >
                    {showFigmaClientId ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={figmaClientId}
            onChange={handleFigmaClientIdChange}
          />
          <TextField
            label='Figma Client Secret'
            variant='outlined'
            fullWidth
            size='small'
            type={showFigmaClientSecret ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowFigmaClientSecret(!showFigmaClientSecret)
                    }
                  >
                    {showFigmaClientSecret ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={figmaClientSecret}
            onChange={handleFigmaClientSecretChange}
          />
          <TextField
            label='Figma Access Token'
            variant='outlined'
            fullWidth
            size='small'
            type={showFigmaAccessToken ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowFigmaAccessToken(!showFigmaAccessToken)
                    }
                  >
                    {showFigmaAccessToken ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={figmaAccessToken}
            onChange={handleFigmaAccessTokenChange}
          />
          <TextField
            label='Figma API URL'
            variant='outlined'
            fullWidth
            size='small'
            type={showFigmaApiUrl ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowFigmaApiUrl(!showFigmaApiUrl)}
                  >
                    {showFigmaApiUrl ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={figmaApiUrl}
            onChange={handleFigmaApiUrlChange}
          />
          <TextField
            label='Figma File Key'
            variant='outlined'
            fullWidth
            size='small'
            type={showFigmaFileKey ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowFigmaFileKey(!showFigmaFileKey)}
                  >
                    {showFigmaFileKey ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={figmaFileKey}
            onChange={handleFigmaFileKeyChange}
          />
        </>
      )}
      <Button
        variant='contained'
        color='primary'
        onClick={updateClientSettings}
        sx={{ height: "fit-content" }}
      >
        Update
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='success'
          iconMapping={{
            success: <CheckCircleIcon style={{ color: "white" }} />,
          }}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          You updated Figma settings!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FigmaSettings;
