import React, { useState, useEffect, useContext, useRef } from "react";
import "./Quill.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../context/authContext";
import { updatePosition } from "./helpers/positionUtils";
import { cloneDeep } from "lodash";
import { isEqual } from "lodash";

import html2canvas from "html2canvas";

import RightSidebar from "../../components/RightSideBar";
import { handlePutRequest } from "./helpers/putRequest";
import { editAd } from "./features/adSlice";
import { downloadAllImages, downloadImage } from "./helpers/imageDownloader";
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { handleImageUpload } from "./helpers/handleImageUpload";
import { resolutions } from "./helpers/resolutions";
import { getFigmaData } from "./utils/getFigmaData";
import FigmaLogo from "../../images/figma_logo.png";
import { formatPositionsData } from "./utils/formatFigmaData";
import { useCallback } from "react";
import SyncFigmaStatus from "./components/SyncFigmaStatus";

import { microsoftStandardCalculation } from "./utils/microsoftStandardCalculation";
import {
  attributes,
  logos,
  networkTags,
  types,
} from "./helpers/DestrucuringAdsAndCalculateLogos";
import { setAdsData } from "./features/adsDataSlice";
import AdsViewTabs from "./components/AdsViewTabs";
import axios from "axios";
import { ACTIONS_URL, AI_URL } from "../../config";
import EditorActionCard from "./components/EditorActionCard";
import { useFetchBrandData } from "../../components/hooks/useFetchBrandData";
import axiosInstance from "../../services/axiosInstance";
import { useMemo } from "react";
import { intelStandardCalculation } from "./utils/intelStandardCalculation";
import { gigabyteStandardCalculation } from "./utils/gigabyteStandardCalculation";
import { brandCalculationMapping } from "./helpers/brandCalculationMapping";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { setEditingComponent } from "./features/editingComponentSlices";

import ColorInfoCard from "../../components/ColorInfoCard";
import EditorToolbar from "./components/EditorToolbar";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { amdStandardCalculation } from "./utils/amdStandardCalculation";
import AnimationPreview from "./animation/components/AnimationPreview";

export default function AdEditor(props) {
  const { currentUser } = useContext(AuthContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const editingComponent = useSelector((state) => state.editingComponent);
  const BrandsList = useSelector((store) => store.brands);
  const UsersList = useSelector((store) => store.users);
  const ClientsList = useSelector((store) => store.clients);

  // const AdsList = useMemo(() => {
  //   return adsFromStore || [];
  // }, [adsFromStore]);

  const adsData = useSelector((store) => store?.adsData) || [];

  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [isEditingHeadline, setIsEditingHeadline] = useState(false);
  const [isEditingSubHeadline, setIsEditingSubHeadline] = useState(false);

  const [editingResolution, setEditingResolution] = useState("1200x630");
  const [figmaData, setFigmaData] = useState(24);
  const [isLoading, setIsLoading] = useState(false);
  const [syncStatus, setSyncStatus] = useState("");
  // const [currentTab, setCurrentTab] = useState("All");
  const [translatedText, setTranslatedText] = useState("");
  const [language, setLanguage] = useState("");

  const [isLoadingOpenAi, setIsLoadingOpenAi] = useState(false);

  const [progress, setProgress] = useState(0);

  const [currentTab, setCurrentTab] = useState("All");
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedResolution, setSelectedResolution] = useState("1200x630");
  const [sortedNetworkTags, setSortedNetworkTags] = useState({});
  const [showUpdated, setShowUpdated] = useState(false);

  const [backgroundScale, setBackgroundScale] = useState(1);
  const [showScroll, setShowScroll] = useState(false);

  const [showImage, setShowImage] = useState({});

  const [state, setState] = useState({});

  const [bannerWidth, bannerHeight] = editingResolution.split("x").map(Number);
  const [editorInstance, setEditorInstance] = useState(null);

  const [activeTab, setActiveTab] = useState(0);

  const [isResizeMode, setIsResizeMode] = useState(false);

  const [isFigmaEnabled, setIsFigmaEnabled] = useState(false);

  const [isAnimationPreviewOpen, setIsAnimationPreviewOpen] = useState(false);

  const [previewLink, setPreviewLink] = useState("");
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);

  const handleGeneratePreview = async () => {
    setIsGeneratingPreview(true);
    setPreviewLink(""); // Reset any existing link

    // Specify the resolution you want to generate (e.g., "1200x630")
    const resolution = "1200x630";
    const filename = `ad${resolution}.png`;

    // Get the component reference for the specified resolution
    const componentRef = allComponents[`${filename}`];

    if (!componentRef || !componentRef.current) {
      alert("Component reference not found for the specified resolution.");
      setIsGeneratingPreview(false);
      return;
    }

    try {
      // Generate the image using html2canvas
      const blob = await downloadImage({ [filename]: componentRef });

      // Prepare form data to upload the image
      const formData = new FormData();
      formData.append("adId", existingAd.id); // Use the actual ad ID
      formData.append("resolution", resolution);
      formData.append("image", blob, filename);

      // Upload the image to the backend using the new route
      const response = await axiosInstance.post(`/upload/adImage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Generate a random hash for the link using Web Crypto API
      const array = new Uint32Array(6);
      window.crypto.getRandomValues(array);
      const hash = Array.from(array, (dec) => dec.toString(16)).join(""); // Convert to hex string

      // Get the client name from the currentUser object
      const clientName = currentUser?.clientName || "defaultClient";

      // After successful upload, set the preview link with hash and clientName
      const adPreviewLink = `http://localhost:3001/ads/serve/${existingAd.id}/${clientName}/${hash}`;
      setPreviewLink(adPreviewLink);

      alert("Preview link generated successfully!");
    } catch (error) {
      console.error("Error generating preview link:", error);
      alert("Failed to generate preview link.");
    } finally {
      setIsGeneratingPreview(false);
    }
  };

  // Handler to toggle the animation preview
  const handleToggleAnimationPreview = () => {
    setIsAnimationPreviewOpen((prev) => !prev);
  };
  // Handler to close the animation preview and show the main editor again
  const handleCloseAnimationPreview = () => {
    setIsAnimationPreviewOpen(false);
  };

  const fontsFromStore = useSelector((state) => state.fonts);

  const location = useLocation();

  // Handler to toggle the mode
  const toggleResizeMode = () => {
    setIsResizeMode(!isResizeMode);
  };

  const handleScaleChange = (newScale) => {
    setBackgroundScale(newScale);
  };

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 400) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Set a slight delay to re-check the scroll position
    setTimeout(() => {
      checkScrollTop();
    }, 500);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, []);

  useEffect(() => {
    if (location.state?.openSidebar) {
      setIsRightSidebarOpen(true); // Function to open the sidebar
    }
    if (location.state?.activeTab === "Messages") {
      setActiveTab(1); // Assuming 1 is the index for Messages tab
    }
  }, [location]);

  const [anchorEl, setAnchorEl] = useState(null);

  const params = useParams();
  const id = +params.id;

  const existingAd = useSelector(
    (state) => state.ads.find((ad) => ad.id === id) || {}
  );

  const {
    title,
    headline,
    subHeadline,
    text2,
    backgroundimage,
    productImage,
    logoThirdParty,
    logoBrand1,
    logoBrand2,
    editable,
    positions,
    status,
  } = existingAd;
  const dispatch = useDispatch();
  const initialPositions = positions ? positions : {};

  useFetchBrandData(existingAd?.brand_id);

  const adStatus = existingAd?.status;

  const [partnership, setPartnership] = useState(existingAd.partnership);
  const [partnershipColor, setPartnershipColor] = useState(
    existingAd.partnershipColor
  ); // Assuming default white color, you can adjust if needed
  const [partnershipWidth, setPartnerShipWidth] = useState(
    existingAd.partnershipWidth
  );

  const [values, setValues] = useState({
    headline,
    subHeadline,
    text2,
    backgroundimage,
    productImage,
    logoThirdParty,
    logoBrand1,
    logoBrand2,
    editable,
    partnership: existingAd?.partnership,
    partnershipColor: existingAd?.partnershipColor,
    partnershipWidth: existingAd?.partnershipWidth,
    positions: initialPositions,
  });

  const prevPositionsRef = useRef(values.positions);

  console.log(existingAd.id);

  const [logoCornersConfig, setLogoCornersConfig] = useState({
    logoBrand1:
      initialPositions?.logoBrand1?.["1200x630"].selectedCorner || "topLeft",
    logoBrand2:
      initialPositions?.logoBrand2?.["1200x630"].selectedCorner || "topRight",
    logoBrand3:
      initialPositions?.logoBrand3?.["1200x630"].selectedCorner || "bottomLeft",
    logoThirdParty:
      initialPositions?.logoThirdParty?.["1200x630"].selectedCorner ||
      "bottomRight",
  });

  const filterAndSortTags = () => {
    const availableResolutions = values.positions.resolutions || []; // Fallback to empty array if undefined
    const editingResolution = "1200x630";
    const filteredAndSorted = {};

    for (let network in networkTags) {
      // First, ensure editingResolution is included
      let resolutions = new Set([editingResolution, ...availableResolutions]);

      // Then filter based on the resolutions available
      const filtered = networkTags[network].filter((resolution) =>
        resolutions.has(resolution)
      );

      // Then sort the filtered resolutions
      filteredAndSorted[network] = filtered.sort((a, b) => {
        if (a === selectedResolution) return -1;
        if (b === selectedResolution) return 1;
        const [widthA, heightA] = a.split("x").map(Number);
        const [widthB, heightB] = b.split("x").map(Number);
        return widthB * heightB - widthA * heightA;
      });
    }

    setCurrentPage(0); // Reset pagination or any other UI control to initial state
    setSortedNetworkTags(filteredAndSorted); // Update the state with the filtered and sorted tags
  };

  useEffect(() => {
    filterAndSortTags();
    // Add values.positions.resolutions to the dependency array so that the effect runs whenever it changes
  }, [selectedResolution, values.positions.resolutions]);

  function getProp(obj, props) {
    return props.reduce((prev, prop) => prev && prev[prop], obj);
  }

  useEffect(() => {
    const updatedPositions = existingAd?.positions
      ? JSON.parse(JSON.stringify(existingAd.positions))
      : {};

    setValues({
      headline: existingAd?.headline || "",
      subHeadline: existingAd?.subHeadline || "",
      text2: existingAd?.text2 || "",
      backgroundimage: existingAd?.backgroundimage || "",
      productImage: existingAd?.productImage || "",
      logoThirdParty: existingAd?.logoThirdParty || "",
      logoBrand1: existingAd?.logoBrand1 || "",
      logoBrand2: existingAd?.logoBrand2 || "",
      editable: existingAd?.editable || false,
      partnership: existingAd?.partnership || false,
      partnershipColor: existingAd?.partnershipColor || "",
      partnershipWidth: existingAd?.partnershipWidth || "",
      positions: initialPositions,
    });

    prevPositionsRef.current = initialPositions;
  }, [existingAd]);

  const handleTranslate = async (providedLang) => {
    const langToUse = providedLang || language;
    setIsLoadingOpenAi(true);

    try {
      let updatedValues = { ...values }; // Create a shallow copy of values

      // Define the static fields
      const staticFields = ["headline", "subHeadline", "disclaimer"];

      // Dynamically generate text field names from updatedValues.positions
      const dynamicFields = Object.keys(updatedValues.positions).filter((key) =>
        key.startsWith("text")
      );

      // Combine static and dynamic fields
      const fields = [...staticFields, ...dynamicFields];

      for (let field of fields) {
        // Use the field directly as valueKey
        let valueKey = field;

        // Assuming the text in all resolutions is the same, just use the first one
        const resolution = "1200x630"; // Assuming resolutions is defined elsewhere
        const firstText =
          updatedValues.positions[valueKey]?.[resolution]?.[field];

        if (firstText) {
          const payload = {
            text: String(firstText),
            language: langToUse,
            clientId: currentUser.clientIdUsers, // Include clientId in the request body
          };
          // console.log("Payload:", payload); // Log the payload for debugging

          const response = await axios.post(AI_URL, payload);

          if (response.data && response.data.translatedText) {
            // Apply the translated text to all resolutions for the current field
            for (let resolution of resolutions) {
              // Ensure nested objects exist for the resolution
              updatedValues.positions[valueKey][resolution] =
                updatedValues.positions[valueKey][resolution] || {};

              // Update the values state for each resolution
              updatedValues.positions[valueKey][resolution][field] =
                response.data.translatedText;
            }
          } else {
            console.error(`Unexpected response format for ${field}`);
          }
        }
      }

      setValues(updatedValues); // Update the values state

      // Posting to ACTIONS_URL after translation is done
      await axiosInstance.post(ACTIONS_URL, {
        actionType: "Translate",
        objectType: "Ad",
        userId: currentUser.id, // Ensure correct path to userId
        clientId: currentUser.clientIdUsers, // Ensure correct path to clientId
        adTitle: values.title,
        content: updatedValues, // Assuming you want to send the updated values as content
      });
    } catch (error) {
      console.error("Error translating text:", error);
    } finally {
      setIsLoadingOpenAi(false); // Stop the loader
      setLanguage(""); // Reset the language state to show the dropdown again
    }
  };

  const logoBrand1Values = { height: null, width: null, partnership: true };

  const updateAdsData = useCallback(() => {
    let adsDataTemp = { ...adsData };
    let positionsTemp = cloneDeep(values.positions);
    let logoBrand1Corner;
    let sameCornerAsLogoBrand1 = false;

    const logoTypes = [
      "logoBrand1",
      "logoBrand2",
      "logoBrand3",
      "logoThirdParty",
    ];

    resolutions.forEach((position) => {
      const [xRes, yRes] = position.split("x").map(Number);

      const cornersLogoPositions = {
        topLeft: { x: 0, y: 0 },
        topRight: { x: xRes, y: 0 },
        bottomLeft: { x: 0, y: yRes },
        bottomRight: { x: xRes, y: yRes },
      };

      logoTypes.forEach((type) => {
        let newValues;

        attributes[type]?.forEach((attribute) => {
          let attributeKey = attribute;

          if (
            (type === "subHeadline" || type === "disclaimer") &&
            attribute === "headline"
          ) {
            attributeKey = type === "subHeadline" ? "subHeadline" : "headline";
          }

          let key =
            attributeKey !== "headline" && attributeKey !== "subHeadline"
              ? `${attribute}${
                  type.charAt(0).toUpperCase() + type.slice(1)
                }${position}`
              : `${type}${position}`;

          key = key.charAt(0).toLowerCase() + key.slice(1);

          if (
            logos.includes(type) &&
            ["x", "y", "width", "height"].includes(attribute)
          ) {
            const element = getProp(values, ["positions", type, position]);

            if (element && "width" in element && "height" in element) {
              if (logos.includes(type) && !newValues) {
                const selectedCorner = logoCornersConfig[type];

                if (type === "logoBrand1") {
                  logoBrand1Corner = selectedCorner;
                }

                if (
                  type !== "logoBrand1" &&
                  selectedCorner === logoBrand1Corner
                ) {
                  sameCornerAsLogoBrand1 = true;
                } else {
                  sameCornerAsLogoBrand1 = false;
                }

                const selectedBaseCorner = cornersLogoPositions[selectedCorner];

                const selectedCalculationFunction =
                  brandCalculationMapping[existingAd?.brand];
                if (selectedCalculationFunction) {
                  newValues = selectedCalculationFunction(
                    xRes,
                    yRes,
                    selectedCorner,
                    selectedBaseCorner,
                    element,
                    type,
                    sameCornerAsLogoBrand1,
                    logoBrand1Values,
                    partnership
                  );

                  // Ensure newValues don't have negative values
                  newValues.position.x = Math.max(0, newValues.position.x);
                  newValues.position.y = Math.max(0, newValues.position.y);
                  newValues.width = Math.max(0, newValues.width);
                  newValues.height = Math.max(0, newValues.height);

                  let originalElement = getProp(values, [
                    "positions",
                    type,
                    position,
                  ]);
                  let originalElementCopy = { ...originalElement };

                  originalElementCopy.height = newValues.height;
                  originalElementCopy.width = newValues.width;
                  originalElementCopy.x = newValues.position.x;
                  originalElementCopy.y = newValues.position.y;
                  originalElementCopy.selectedCorner = selectedCorner;
                  originalElementCopy.selectedCornerPosition =
                    newValues.selectedCornerPosition;

                  adsDataTemp[`selectedCorner${type}${xRes}x${yRes}`] =
                    selectedCorner;
                  adsDataTemp[`selectedCornerPosition${type}${xRes}x${yRes}`] =
                    newValues.selectedCornerPosition;

                  // Update positionsTemp with the calculated values
                  if (!positionsTemp[type]) {
                    positionsTemp[type] = {};
                  }
                  if (!positionsTemp[type][position]) {
                    positionsTemp[type][position] = {};
                  }
                  positionsTemp[type][position] = {
                    ...positionsTemp[type][position],
                    ...originalElementCopy,
                  };
                } else {
                  console.error(
                    `Calculation function for brand ${existingAd?.brand} is not available.`
                  );
                }
              }

              let data =
                newValues && newValues[attribute]
                  ? newValues[attribute]
                  : newValues && newValues.position[attribute];
              adsDataTemp[key] = data;
            }
          } else {
            let data = getProp(values, [
              "positions",
              type,
              position,
              attributeKey,
            ]);

            // Ensure data is not undefined before updating
            adsDataTemp[key] = data != null ? data : "";

            // Update positionsTemp with the data
            if (!positionsTemp[type]) {
              positionsTemp[type] = {};
            }
            if (!positionsTemp[type][position]) {
              positionsTemp[type][position] = {};
            }
            positionsTemp[type][position][attributeKey] =
              data != null ? data : "";
          }
        });
      });
    });

    if (!isEqual(prevPositionsRef.current, positionsTemp)) {
      prevPositionsRef.current = positionsTemp;
      setValues((prevValues) => ({
        ...prevValues,
        positions: positionsTemp,
      }));
    }

    setIsLoading(false);
  }, [
    logoCornersConfig,
    values.positions,
    partnership,
    adsData,
    existingAd?.brand,
    dispatch,
  ]);

  useEffect(() => {
    updateAdsData();
  }, [updateAdsData]);

  const [selectedFont, setSelectedFont] = useState("");
  const [editStates, setEditStates] = useState({
    "1200x630": initialPositions?.product?.["1200x630"].editable,
  });

  const handleMenuOpen = (event) => {
    dispatch(setEditingComponent(""));
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(0);
    const resetResolutions = {};
    for (let resolution of Object.values(networkTags).flat()) {
      resetResolutions[resolution] = false;
    }
    setSelectedResolutions(resetResolutions);
  };

  const ad160x600Ref = useRef();
  const ad320x50Ref = useRef();
  const ad300x250Ref = useRef();
  const ad300x600Ref = useRef();
  const ad600x829Ref = useRef();
  const ad728x90Ref = useRef();
  const ad875x225Ref = useRef();
  const ad960x676Ref = useRef();
  const ad970x250Ref = useRef();
  const ad1080x1080Ref = useRef();
  const ad1080x1920Ref = useRef();
  const ad1200x628Ref = useRef();
  const ad1200x630Ref = useRef();
  const ad1200x1200Ref = useRef();
  const ad1900x620Ref = useRef();

  const [selectedResolutions, setSelectedResolutions] = useState({
    all: false,
    "ad160x600.png": false,
    "ad320x50.png": false,
    "ad300x250.png": false,
    "ad300x600.png": false,
    "ad600x829.png": false,
    "ad728x90.png": false,
    "ad875x225.png": false,
    "ad960x676.png": false,
    "ad970x250.png": false,
    "ad1080x1080.png": false,
    "ad1080x1920.png": false,
    "ad1200x628.png": false,
    "ad1200x630.png": false,
    "ad1200x1200.png": false,
    "ad1900x620.png": false,
  });

  // DOWNLOAD IMAGES

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedResolutions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleDownload = async () => {
    setCurrentTab("All");
    handleMenuClose();
    const componentsToDownload = Object.entries(selectedResolutions)
      .filter(([resolution, isSelected]) => isSelected)
      .reduce((acc, [resolution, _]) => {
        const filename = `ad${resolution}.png`;
        return { ...acc, [filename]: allComponents[filename] };
      }, {});

    await downloadAllImages(componentsToDownload, (percentage) => {
      setProgress(percentage);
    });

    // Add response content if needed
    const response = {
      /* response content here if needed */
    };

    await axiosInstance.post(ACTIONS_URL, {
      actionType: "Download",
      objectType: "Image",
      userId: currentUser.id,
      clientId: currentUser.clientIdUsers,
      adTitle: values.title,
      content: response,
    });
  };

  const allComponents = {
    "ad160x600.png": ad160x600Ref,
    "ad320x50.png": ad320x50Ref,
    "ad300x250.png": ad300x250Ref,
    "ad300x600.png": ad300x600Ref,
    "ad600x829.png": ad600x829Ref,
    "ad728x90.png": ad728x90Ref,
    "ad875x225.png": ad875x225Ref,
    "ad960x676.png": ad960x676Ref,
    "ad970x250.png": ad970x250Ref,
    "ad1080x1080.png": ad1080x1080Ref,
    "ad1080x1920.png": ad1080x1920Ref,
    "ad1200x630.png": ad1200x630Ref,
    "ad1200x628.png": ad1200x630Ref,
    "ad1200x1200.png": ad1200x1200Ref,
    "ad1900x620.png": ad1900x620Ref,
  };

  const handleRightSidebarToggle = (source, sourceType, resolution) => {
    setSelectedImage(source);

    dispatch(setEditingComponent(sourceType));

    setEditingResolution(resolution);
  };

  useEffect(() => {
    const findBrandById = (id) => {
      const matchedBrand = BrandsList.find(
        (brand) => brand.id === existingAd.brand_id
      );
      return matchedBrand;
    };

    if (existingAd && existingAd.id) {
      const matchedBrand = findBrandById(existingAd.id);
      setSelectedBrand(matchedBrand);
    }
  }, [existingAd, BrandsList]);

  const userThatCreatedAd = UsersList.find(
    (user) => user.id === existingAd.uid
  );

  if (userThatCreatedAd) {
  } else {
    console.log("User not found");
  }

  const updateNestedValue = (type, resolution, value) => {
    if (!values.positions[type]) {
      // Handle this error case accordingly. Maybe:
      console.error(`Unexpected type: ${type}`);
      return values.positions; // return current positions without any change
    }

    const keyToUpdate = type; // Use the exact type as the key

    return {
      ...values.positions,
      [type]: {
        ...values.positions[type],
        [resolution]: {
          ...values.positions[type][resolution],
          [keyToUpdate]: value,
        },
      },
    };
  };

  const handleUpdate = async (newEditStates) => {
    setShowUpdated(true); // Set the button to "Updated!" state at the start

    try {
      const response = await handlePutRequest(
        id,
        values,
        currentUser,
        dispatch,
        editAd,
        newEditStates,
        updatePositions
      );

      // Include adId in the POST request
      await axiosInstance.post(ACTIONS_URL, {
        actionType: "Update",
        objectType: "Ad",
        adId: existingAd?.id, // Assuming existingAd.id is available here
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers,
        adTitle: values.title,
        content: values,
      });

      setTimeout(() => {
        setShowUpdated(false); // Revert the button to "Update" state after the complete process
        // navigate("/ads");
      }, 1000); // Adjust the time as needed
    } catch (err) {
      console.error(err);
      setShowUpdated(false); // Revert to "Update" state in case of error too.
    }
  };

  // updating positions for ad from put in values.positions
  const updatePositions = (newPositions) => {
    setValues((prevValues) => ({
      ...prevValues,
      positions: newPositions,
    }));
  };

  useEffect(() => {
    if (editStates !== undefined) {
      let newPositions = JSON.parse(JSON.stringify(values.positions)); // make a deep copy
      for (let key in editStates) {
        if (newPositions.product[key]) {
          newPositions.product[key].editable = editStates[key];
        }
      }
      setValues((prevValues) => ({ ...prevValues, positions: newPositions }));
    }
  }, [editStates]);

  const handleUpdatePosition = (newPosition, resolution, type) => {
    const { updatedState } = updatePosition(
      values,
      newPosition,
      resolution,
      type
    );

    setValues(updatedState);
  };

  const handleEditorUpdate = (formattedValue, editingComponent) => {
    const updatedPositions = updateNestedValue(
      editingComponent,
      editingResolution,
      formattedValue
    );

    setValues((prevState) => {
      const newState = { ...prevState, positions: updatedPositions };

      return newState;
    });
  };

  // const handleCategoryChange = (e) => {
  //   const newCat = e.target.value;
  //   setValues((prevState) => ({
  //     ...prevState,
  //     cat: newCat,
  //   }));
  // };

  const handleFigmaSyncProgress = useCallback(
    (processedItems, totalItems, latestItem, resolutionData) => {
      const progressValue = Math.floor((processedItems / totalItems) * 100);
      // setFigmaProgress(progressValue);

      setSyncStatus(
        <SyncFigmaStatus
          progressValue={progressValue}
          latestItem={latestItem}
          resolutionData={resolutionData}
        />
      );
    },
    []
  );

  useEffect(() => {
    const clientId = currentUser?.clientIdUsers;
    const client = ClientsList.find((c) => c.id === clientId);
    if (client && client.settings && client.settings.figma) {
      setIsFigmaEnabled(client.settings.figma.isFigmaEnabled);
    }
  }, [ClientsList, currentUser]);

  const handleFigmaDataClick = () => {
    const clientId = currentUser?.clientIdUsers;

    if (!clientId) {
      console.error("Client ID is required to fetch Figma data.");
      return;
    }

    setIsLoading(true);
    getFigmaData(clientId, handleFigmaSyncProgress)
      .then((data) => {
        setFigmaData(data);

        setValues((oldValues) => {
          const newPositionsObject = formatPositionsData(data);

          const mergedPositions = {};

          for (const [key, value] of Object.entries(newPositionsObject)) {
            if (oldValues.positions[key]) {
              mergedPositions[key] = {};

              for (const [resolutionKey, resolutionValue] of Object.entries(
                value
              )) {
                // If imageUrl exists and is not null in the new data, use it
                // Otherwise, use the old imageUrl
                const imageUrl =
                  resolutionValue.hasOwnProperty("imageUrl") &&
                  resolutionValue.imageUrl !== null
                    ? resolutionValue.imageUrl
                    : (oldValues.positions[key][resolutionKey] &&
                        oldValues.positions[key][resolutionKey].imageUrl) ||
                      null;

                // Create a copy of the new resolutionValue object without the imageUrl property
                const { imageUrl: _, ...valueWithoutImageUrl } =
                  resolutionValue;

                // Merge the old and new values, including the determined imageUrl
                mergedPositions[key][resolutionKey] = {
                  ...oldValues.positions[key][resolutionKey],
                  ...valueWithoutImageUrl,
                  imageUrl,
                };
              }
            } else {
              mergedPositions[key] = value;
            }
          }

          return { ...oldValues, positions: mergedPositions };
        });

        setSyncStatus("");
      })
      .catch((error) => {
        console.error("Error:", error);
        setSyncStatus("Sync failed!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePartnershipClick = (e) => {
    const newPartnershipValue = !partnership;

    setPartnership(newPartnershipValue);

    // Also update the value inside the values state
    setValues((prevValues) => ({
      ...prevValues,
      partnership: newPartnershipValue,
    }));
  };

  const handleColorChange = (newColor) => {
    console.log("Color before change:", newColor.hex);

    setPartnershipColor(newColor.hex);

    setValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        partnershipColor: newColor.hex,
      };
      console.log("Values after color change:", updatedValues);
      return updatedValues;
    });
  };

  const handleElementDragResize = (
    event,
    data,
    element,
    isResize,
    resolution
  ) => {
    // console.log(
    //   "aaaaaaaaaaaaaaaaaaggggggggggg",
    //   data,
    //   element,
    //   isResize,
    //   resolution
    // );
    if (element == null) {
      console.error(
        "Error: element is undefined or null.",
        data,
        element,
        isResize,
        resolution
      );
      return;
    }
    // Making the first letter of 'element' lowercase
    const formattedElement = element.charAt(0).toLowerCase() + element.slice(1);

    // Assuming data object contains x, y, width, height, and optionally rotation
    const newPosition = { x: data.x, y: data.y };

    setValues((prevValues) => {
      const updatedValues = JSON.parse(JSON.stringify(prevValues));
      if (!updatedValues.positions[formattedElement]) {
        updatedValues.positions[formattedElement] = {};
      }
      if (!updatedValues.positions[formattedElement][resolution]) {
        updatedValues.positions[formattedElement][resolution] = {};
      }
      updatedValues.positions[formattedElement][resolution].x = newPosition.x;
      updatedValues.positions[formattedElement][resolution].y = newPosition.y;
      if (isResize) {
        updatedValues.positions[formattedElement][resolution].width =
          data.width;
        updatedValues.positions[formattedElement][resolution].height =
          data.height;
      }

      // Update rotation if it's provided in data
      if (data.rotation !== undefined) {
        updatedValues.positions[formattedElement][resolution].rotation =
          data.rotation;
      }

      return updatedValues;
    });
  };

  const updateTextDimensions = (item, width, height, resolution) => {
    // Assuming the first letter should be lowercase
    const formattedItem = item.charAt(0).toLowerCase() + item.slice(1);

    setValues((prevValues) => {
      const updatedValues = JSON.parse(JSON.stringify(prevValues));

      if (!updatedValues.positions[formattedItem]) {
        updatedValues.positions[formattedItem] = {};
      }
      if (!updatedValues.positions[formattedItem][resolution]) {
        updatedValues.positions[formattedItem][resolution] = {};
      }

      updatedValues.positions[formattedItem][resolution].width = width;
      updatedValues.positions[formattedItem][resolution].height = height;

      return updatedValues;
    });
  };

  const handleElementTransparencyUpdate = (element, opacity, resolution) => {
    if (element == null) {
      console.error(
        "Error: element is undefined or null.",
        element,
        opacity,
        resolution
      );
      return;
    }

    // Making the first letter of 'element' lowercase
    const formattedElement = element.charAt(0).toLowerCase() + element.slice(1);

    setValues((prevValues) => {
      const updatedValues = JSON.parse(JSON.stringify(prevValues));

      if (!updatedValues.positions[formattedElement]) {
        updatedValues.positions[formattedElement] = {};
      }
      if (!updatedValues.positions[formattedElement][resolution]) {
        updatedValues.positions[formattedElement][resolution] = {};
      }

      // Update transparency
      updatedValues.positions[formattedElement][resolution].opacity = opacity;

      return updatedValues;
    });
  };

  const handleLockClick = (itemKey, isEditing, event) => {
    event.stopPropagation(); // Prevent triggering row click
    // Update the editable value
    const updatedValues = { ...values }; // Assuming values is your state
    updatedValues.positions[itemKey][editingResolution].editable = !isEditing;
    setValues(updatedValues); // Assuming setValues is your state updater function
  };

  //////////////////////////////////////////////////////////////////////////////////////
  // If isAnimationPreviewOpen is true, render only AnimationPreview
  if (isAnimationPreviewOpen) {
    return (
      <Box sx={{ ml: 8 }}>
        <AnimationPreview
          onClose={handleCloseAnimationPreview}
          values={values}
          setValues={setValues} // Pass a function to close the preview
        />
      </Box>
    );
  }

  return (
    <Grid
      sx={{
        pl: isMobile ? 2 : isTablet ? 5 : 10,
        pr: 2,
      }}
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sx={{ mt: 2, position: "sticky", top: 48, zIndex: 999 }}
      >
        <EditorActionCard
          data={existingAd}
          userThatCreatedAd={userThatCreatedAd}
          selectedBrand={selectedBrand}
          handlePartnershipClick={handlePartnershipClick}
          values={values}
          setValues={setValues}
          editStates={editStates}
          setEditStates={setEditStates}
          anchorEl={anchorEl}
          handleMenuOpen={handleMenuOpen}
          handleCloseDownload={handleMenuClose}
          handleCheckboxChange={handleCheckboxChange}
          handleDownload={handleDownload}
          selectedResolutions={selectedResolutions}
          progress={progress}
          toggle={handleRightSidebarToggle}
          isLoadingOpenAi={isLoadingOpenAi}
          handleTranslate={handleTranslate}
          language={language}
          setLanguage={setLanguage}
          networkTags={sortedNetworkTags}
          setIsRightSidebarOpen={setIsRightSidebarOpen}
          handleColorChange={handleColorChange}
          setPartnerShipWidth={setPartnerShipWidth}
          editingComponent={editingComponent}
          editingResolution={editingResolution}
          onUpdate={() => handleUpdate(editStates)}
          showUpdated={showUpdated}
          existingAd={existingAd}
          partnership={partnership}
        />

        <EditorToolbar
          editingComponent={editingComponent}
          values={values}
          setValues={setValues}
          editorInstance={editorInstance}
          editingResolution={editingResolution}
          existingAd={existingAd}
          selectedBrand={selectedBrand}
          fontsFromStore={fontsFromStore}
          bannerWidth={bannerWidth}
          bannerHeight={bannerHeight}
          handleElementDragResize={handleElementDragResize}
          editStates={editStates}
          toggleResizeMode={toggleResizeMode}
          onAnimateAdClick={handleToggleAnimationPreview}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mr: -2,
            pt: 2.2,
            zIndex: 1200,
            transition: "transform 0.3s ease-in-out",
            transform: isRightSidebarOpen ? `translateX(-${350}px)` : "none",
          }}
        >
          <Button
            sx={{
              minWidth: "auto",
              width: "60px",
              height: "40px",
              padding: "4px",
              fontSize: "18px",
              borderRadius: "8px 0 0 8px",
            }}
            onClick={() => setIsRightSidebarOpen(!isRightSidebarOpen)}
            variant='contained'
          >
            <MenuOpenIcon
              sx={{
                mr: 2,
                transition: "transform 0.3s ease-in-out",
                transform: isRightSidebarOpen ? "rotate(180deg)" : "none",
              }}
            />
          </Button>
        </Box>

        <RightSidebar
          partnership={partnership}
          handlePartnershipClick={handlePartnershipClick}
          logoCornersConfig={logoCornersConfig}
          setLogoCornersConfig={setLogoCornersConfig}
          values={values}
          adsData={adsData}
          existingAd={existingAd}
          isDrawerOpen={isRightSidebarOpen}
          toggle={handleRightSidebarToggle}
          selectedImage={selectedImage}
          handleImageUpload={handleImageUpload}
          editingComponent={editingComponent}
          setValues={setValues}
          handleEditorUpdate={handleEditorUpdate}
          setSelectedImage={setSelectedImage}
          selectedFont={selectedFont}
          setSelectedFont={setSelectedFont}
          editingResolution={editingResolution}
          networkTags={networkTags}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          sortedNetworkTags={sortedNetworkTags}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selectedResolution={selectedResolution}
          setSelectedResolution={setSelectedResolution}
          setIsRightSidebarOpen={setIsRightSidebarOpen}
          showUpdated={showUpdated}
          handleScaleChange={handleScaleChange}
          setShowImage={setShowImage}
          editStates={editStates}
          bannerWidth={bannerWidth}
          bannerHeight={bannerHeight}
          handleElementDragResize={handleElementDragResize}
          handleElementTransparencyUpdate={handleElementTransparencyUpdate}
          handleLockClick={handleLockClick}
          setEditingResolution={setEditingResolution}
          activeTab={activeTab}
          handleColorChange={handleColorChange}
          previewLink={previewLink}
          handleGeneratePreview={handleGeneratePreview}
        />

        {isFigmaEnabled && (
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              width: isMobile ? "100%" : 600,
              justifyContent: "flex-start",
            }}
          >
            <Button
              sx={{
                mr: 1,
                height: 35,
                "& img": {
                  width: "20px",
                  height: "auto",
                },
              }}
              variant='text'
              startIcon={
                isLoading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : (
                  <img src={FigmaLogo} alt='Figma Logo' />
                )
              }
              onClick={handleFigmaDataClick}
              disabled={isLoading}
            >
              {isLoading ? "Syncing..." : "Sync with Figma"}
            </Button>
            <Box>{syncStatus}</Box>
          </Box>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent='center'
        sx={{
          marginLeft: isRightSidebarOpen
            ? isMobile
              ? "-60px"
              : isTablet
              ? "-120px"
              : "-180px"
            : "0px",
          transition: "margin-left 0.3s ease-in-out",
        }}
      >
        <AdsViewTabs
          editable={true}
          showIt={true}
          // adsData={adsData}
          ad160x600Ref={ad160x600Ref}
          ad320x50Ref={ad320x50Ref}
          ad300x250Ref={ad300x250Ref}
          ad300x600Ref={ad300x600Ref}
          ad600x829Ref={ad600x829Ref}
          ad728x90Ref={ad728x90Ref}
          ad875x225Ref={ad875x225Ref}
          ad960x676Ref={ad960x676Ref}
          ad970x250Ref={ad970x250Ref}
          ad1080x1080Ref={ad1080x1080Ref}
          ad1080x1920Ref={ad1080x1920Ref}
          ad1200x1200Ref={ad1200x1200Ref}
          ad1200x628Ref={ad1200x628Ref}
          ad1200x630Ref={ad1200x630Ref}
          ad1900x620Ref={ad1900x620Ref}
          values={values}
          updatePosition={handleUpdatePosition}
          positions={initialPositions}
          editStates={editStates}
          setEditStates={setEditStates}
          isEditingHeadline={isEditingHeadline}
          setIsEditingHeadline={setIsEditingHeadline}
          isEditingSubHeadline={isEditingSubHeadline}
          setIsEditingSubHeadline={setIsEditingSubHeadline}
          handleRightSidebarToggle={handleRightSidebarToggle}
          setEditingResolution={setEditingResolution}
          networkTags={networkTags}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          sortedNetworkTags={sortedNetworkTags}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selectedResolution={selectedResolution}
          setSelectedResolution={setSelectedResolution}
          setValues={setValues}
          showImage={showImage}
          setShowImage={setShowImage}
          backgroundScale={backgroundScale}
          editingResolution={editingResolution}
          setEditingComponent={setEditingComponent}
          editingComponent={editingComponent}
          selectedBrand={selectedBrand}
          handleElementDragResize={handleElementDragResize}
          state={state}
          updateTextDimensions={updateTextDimensions}
          isResizeMode={isResizeMode}
          status={adStatus}
        />
      </Grid>

      {/* <AnimationPreview /> */}

      {showScroll && (
        <Fab
          color='primary'
          size='medium'
          onClick={scrollTop}
          style={{
            position: "fixed",
            bottom: "2vh",
            right: "2vh",
            zIndex: 1000,
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
    </Grid>
  );
}
