import React, { useState } from "react";
import {
  AppBar,
  Grid,
  Typography,
  IconButton,
  Toolbar,
  Box,
  Button,
  Link,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";

const GenerateAdPreviewLink = ({ handleGeneratePreview, previewLink }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(previewLink);
  };

  const handleShareLink = () => {
    if (navigator.share) {
      navigator.share({
        title: "Ad Preview",
        url: previewLink,
      });
    } else {
      alert("Your browser doesn't support the share feature.");
    }
  };

  return (
    <>
      <AppBar
        position='static'
        sx={{
          mb: 1,
          height: 30,
          justifyContent: "center",
          background: "linear-gradient(45deg, #A06EAF 30%, #80AFDE 90%)",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: 14 }} variant='h6' component='div'>
            GENERATE PREVIEW LINK
          </Typography>
          <IconButton
            sx={{ ml: 1.5 }}
            edge='end'
            color='inherit'
            onClick={handleDrawerToggle}
          >
            {drawerOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>

      {drawerOpen && (
        <Grid sx={{ p: 1 }} container spacing={1}>
          <Box>
            {previewLink && (
              <>
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontWeight: "bold",
                    color: "#555",
                    fontSize: "12px",
                    mb: 1,
                  }}
                >
                  Ad Preview Link:
                </Typography>
                <Box display='flex' alignItems='center' mb={2}>
                  <Link
                    href={previewLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    sx={{ fontSize: "12px", wordBreak: "break-all", mr: 1 }}
                  >
                    {previewLink}
                  </Link>
                </Box>
              </>
            )}
            <Box display='flex' alignItems='center' gap={1}>
              <Button
                variant='outlined'
                color='primary'
                onClick={handleGeneratePreview}
                sx={{ width: "40%", minWidth: 120 }}
              >
                Generate
              </Button>
              <IconButton
                onClick={() => navigator.clipboard.writeText(previewLink)}
                color='primary'
                size='small'
              >
                <ContentCopyIcon sx={{ fontSize: 18 }} /> {/* Smaller icon */}
              </IconButton>
              <IconButton
                onClick={() => {
                  if (navigator.share) {
                    navigator.share({
                      title: "Ad Preview",
                      url: previewLink,
                    });
                  } else {
                    alert("Your browser doesn't support the share feature.");
                  }
                }}
                color='primary'
                size='small'
              >
                <ShareIcon sx={{ fontSize: 18 }} /> {/* Smaller icon */}
              </IconButton>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default GenerateAdPreviewLink;
