import { Box } from "@mui/material";
import React from "react";
import { Grid } from "react-loader-spinner";
import CreateSolanaAccount from "../../components/CreateSolanaAccount";
import ClientSolanaAccount from "../../components/ClientSolanaAccount";

const Crypto = ({ socket }) => {
  // You can add state and useEffect here if you need to fetch data or use the socket
  return (
    <Box sx={{ display: "flex", flexDirection: "row", ml: 10 }}>
      <CreateSolanaAccount />
      <ClientSolanaAccount />
    </Box>
  );
};

export default Crypto;
