import React, { useState, useEffect } from "react";
import {
  Switch,
  FormControlLabel,
  TextField,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axiosInstance";
import { CLIENTS_URL } from "../../../config";
import { editClient } from "../../clients/features/clientSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const LinkedInSettings = ({ currentUser }) => {
  const ClientsList = useSelector((store) => store.clients);
  const [isLinkedInEnabled, setIsLinkedInEnabled] = useState(false);
  const [linkedinClientId, setLinkedinClientId] = useState("");
  const [linkedinClientSecret, setLinkedinClientSecret] = useState("");
  const [linkedinRedirectUri, setLinkedinRedirectUri] = useState("");
  const [linkedinApiUrl, setLinkedinApiUrl] = useState("");
  const [linkedinAccessToken, setLinkedinAccessToken] = useState("");
  const [showLinkedinClientId, setShowLinkedinClientId] = useState(false);
  const [showLinkedinClientSecret, setShowLinkedinClientSecret] =
    useState(false);
  const [showLinkedinRedirectUri, setShowLinkedinRedirectUri] = useState(false);
  const [showLinkedinApiUrl, setShowLinkedinApiUrl] = useState(false);
  const [showLinkedinAccessToken, setShowLinkedinAccessToken] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const clientId = currentUser?.currentUser?.clientIdUsers;

  useEffect(() => {
    console.log("Fetching client settings...");
    const client = ClientsList.find((c) => c.id === clientId);
    console.log("Client", client);
    if (client?.settings?.linkedin) {
      const settings = client.settings.linkedin;
      setIsLinkedInEnabled(settings.isLinkedInEnabled || false);
      setLinkedinClientId(settings.linkedinClientId || "");
      setLinkedinClientSecret(settings.linkedinClientSecret || "");
      setLinkedinRedirectUri(settings.linkedinRedirectUri || "");
      setLinkedinApiUrl(
        settings.linkedinApiUrl || "https://api.linkedin.com/v2"
      );
      setLinkedinAccessToken(settings.linkedinAccessToken || "");
      console.log("Initialized state with LinkedIn settings:", settings);
    }
  }, [ClientsList, clientId]);

  const handleLinkedInToggle = (event) => {
    setIsLinkedInEnabled(event.target.checked);
  };

  const handleLinkedinClientIdChange = (event) => {
    setLinkedinClientId(event.target.value);
  };

  const handleLinkedinClientSecretChange = (event) => {
    setLinkedinClientSecret(event.target.value);
  };

  const handleLinkedinRedirectUriChange = (event) => {
    setLinkedinRedirectUri(event.target.value);
  };

  const handleLinkedinApiUrlChange = (event) => {
    setLinkedinApiUrl(event.target.value);
  };

  const handleLinkedinAccessTokenChange = (event) => {
    setLinkedinAccessToken(event.target.value);
  };

  const updateClientSettings = async () => {
    const payload = {
      linkedin: {
        isLinkedInEnabled,
        linkedinClientId,
        linkedinClientSecret,
        linkedinRedirectUri,
        linkedinApiUrl,
        linkedinAccessToken,
      },
    };

    console.log("Payload to be sent:", payload);

    try {
      console.log(
        "Sending request to:",
        `${CLIENTS_URL}/${clientId}/settings/`
      );
      const response = await axiosInstance.put(
        `${CLIENTS_URL}/${clientId}/settings/`,
        payload
      );

      console.log("Response received from server:", response);

      if (!response || !response.data) {
        console.error("Invalid response from server");
        throw new Error("Invalid response from server");
      }

      console.log("Response data:", response.data);

      const updatedClient = {
        id: clientId,
        settings: {
          ...ClientsList.find((c) => c.id === clientId).settings,
          linkedin: {
            isLinkedInEnabled: response.data.linkedin.isLinkedInEnabled,
            linkedinClientId: response.data.linkedin.linkedinClientId,
            linkedinClientSecret: response.data.linkedin.linkedinClientSecret,
            linkedinRedirectUri: response.data.linkedin.linkedinRedirectUri,
            linkedinApiUrl: response.data.linkedin.linkedinApiUrl,
            linkedinAccessToken: response.data.linkedin.linkedinAccessToken,
          },
        },
      };

      console.log("Updated client to be dispatched:", updatedClient);

      dispatch(editClient(updatedClient));

      // Show Snackbar on successful update
      setSnackbarOpen(true);

      console.log("State updated with new values");
    } catch (error) {
      console.error("Error updating settings", error);
      if (error.response) {
        console.error("Response error data:", error.response.data);
        console.error("Response error status:", error.response.status);
        console.error("Response error headers:", error.response.headers);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={isLinkedInEnabled}
            onChange={handleLinkedInToggle}
            size='small'
          />
        }
        label='LinkedIn'
        sx={{
          "& .MuiTypography-body1": { fontSize: "0.875rem" },
        }}
      />
      {isLinkedInEnabled && (
        <>
          <TextField
            label='LinkedIn Client ID'
            variant='outlined'
            fullWidth
            size='small'
            type={showLinkedinClientId ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowLinkedinClientId(!showLinkedinClientId)
                    }
                  >
                    {showLinkedinClientId ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={linkedinClientId}
            onChange={handleLinkedinClientIdChange}
          />
          <TextField
            label='LinkedIn Client Secret'
            variant='outlined'
            fullWidth
            size='small'
            type={showLinkedinClientSecret ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowLinkedinClientSecret(!showLinkedinClientSecret)
                    }
                  >
                    {showLinkedinClientSecret ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={linkedinClientSecret}
            onChange={handleLinkedinClientSecretChange}
          />
          <TextField
            label='LinkedIn Redirect URI'
            variant='outlined'
            fullWidth
            size='small'
            type={showLinkedinRedirectUri ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowLinkedinRedirectUri(!showLinkedinRedirectUri)
                    }
                  >
                    {showLinkedinRedirectUri ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={linkedinRedirectUri}
            onChange={handleLinkedinRedirectUriChange}
          />
          <TextField
            label='LinkedIn API URL'
            variant='outlined'
            fullWidth
            size='small'
            type={showLinkedinApiUrl ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowLinkedinApiUrl(!showLinkedinApiUrl)}
                  >
                    {showLinkedinApiUrl ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={linkedinApiUrl}
            onChange={handleLinkedinApiUrlChange}
          />
          <TextField
            label='LinkedIn Access Token'
            variant='outlined'
            fullWidth
            size='small'
            type={showLinkedinAccessToken ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowLinkedinAccessToken(!showLinkedinAccessToken)
                    }
                  >
                    {showLinkedinAccessToken ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={linkedinAccessToken}
            onChange={handleLinkedinAccessTokenChange}
          />
        </>
      )}
      <Button
        variant='contained'
        color='primary'
        onClick={updateClientSettings}
        sx={{ height: "fit-content" }}
      >
        Update
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='success'
          iconMapping={{
            success: <CheckCircleIcon style={{ color: "white" }} />,
          }}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          You updated LinkedIn settings!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LinkedInSettings;
