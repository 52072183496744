import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Typography,
  Divider,
  Switch,
  Box,
  IconButton,
  Card,
  CardContent,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Menu,
  MenuItem,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";

import { VoiceCommand } from "../../../components/VoiceCommand";
import AdsStatusDrawer from "./AdsStatusDrawer";
import EditingResolutionSwitch from "./EditingResolutionSwitch";
import DownloadMenu from "../../../components/DownloadMenu";
import LanguageDropdown from "./LanguageDropdown";
import { countryToLanguageMapping } from "../helpers/languages";
import { CompactPicker } from "react-color";
import ColorInfoCard from "../../../components/ColorInfoCard";
import { motion } from "framer-motion";
import axiosInstance from "../../../services/axiosInstance";
import { ACTIONS_URL, ADS_URL } from "../../../config";
import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
import { useDispatch, useSelector } from "react-redux";
import { setApproverId } from "../features/adSlice";
import { useTheme } from "@mui/material/styles";
import PartnershipComponent from "./PartnershipComponent ";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import SaveLayoutModal from "./SaveLayoutModal";
import Link from "@mui/material/Link"; // Ensure correct import

const EditorActionCard = ({
  data,
  userThatCreatedAd,
  selectedBrand,
  handlePartnershipClick,
  editStates,
  setEditStates,
  handleCloseDownload,
  handleCheckboxChange,
  handleDownload,
  toggle,
  selectedResolutions,
  handleMenuOpen,
  progress,
  isLoadingOpenAi,
  handleTranslate,
  language,
  setLanguage,
  networkTags,
  setIsRightSidebarOpen,
  values,
  setValues,
  editingResolution,
  anchorEl,
  setAnchorEl,
  onUpdate,
  showUpdated,
  existingAd,
  handleColorChange,
  partnership,
}) => {
  const { currentUser } = useContext(AuthContext);
  const UsersList = useSelector((state) => state.users);

  const anchorRef = useRef(null);
  const [voiceCommandStatus, setVoiceCommandStatus] = useState("idle");
  const [languageSelectedFromMenu, setLanguageSelectedFromMenu] =
    useState(false);
  const [showSent, setShowSent] = useState(false);
  const [anchorElApproval, setAnchorElApproval] = useState(null);

  const [showPartnership, setShowPartnership] = useState(false);
  const [partnershipAnchorEl, setPartnershipAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleSaveLayout = () => {
    console.log("Saved!!!");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const togglePartnership = (event) => {
    setShowPartnership((prev) => !prev);
    // Set the anchorEl for the Menu to the button element
    setPartnershipAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorElApproval(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElApproval(null);
  };

  const theme = useTheme();

  useEffect(() => {
    let timer;
    if (showSent) {
      timer = setTimeout(() => {
        setShowSent(false);
      }, 4000); // Reset the button after 2 seconds
    }
    return () => clearTimeout(timer); // Clear timeout if the component is unmounted
  }, [showSent]);

  const handleVoiceCommand = (lang) => {
    setLanguageSelectedFromMenu(false);
    if (
      Object.values(countryToLanguageMapping).some((l) => l.language === lang)
    ) {
      handleTranslate(lang);
    } else {
      console.error(`Unsupported language: ${lang}`);
    }
  };

  const handleApproval = async (user) => {
    try {
      // First, update the ad with the new approverId and status
      const updateAdResponse = await axiosInstance.put(
        `${ADS_URL}/${existingAd.id}`,
        {
          id: existingAd.id,
          approverId: user.id,
          status: "pending", // Updating the status here as well
        }
      );

      if (updateAdResponse.status !== 200) {
        console.error("Error while updating ad:", updateAdResponse);
      } else {
        // Uncomment the following line to log the successful update response
        // console.log("Successfully updated ad:", updateAdResponse.data);
      }

      // Then, perform the SentForApproval action
      await axiosInstance.post(ACTIONS_URL, {
        actionType: "SentForApproval",
        objectType: "Ad",
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers,
        approverId: user.id,
        content: user,
      });

      // Dispatch the new action to update both the approverId and status
      dispatch(
        setApproverId({
          adId: existingAd.id,
          userId: user.id,
          status: "pending", // Updating the status in the state as well
        })
      );

      // Define your base API URL here

      // After dispatching, send the email
      // const adLink = `${ADS_PREVIEW_URL}/ad/${existingAd.id}`; // Construct the link to the ad
      const adLink = `app.adready.eu`; // Construct the link to the ad

      try {
        const emailResponse = await axiosInstance.post(
          `${ADS_URL}/sendApprovalEmail/${existingAd.id}`,
          {
            // approverEmail: user.email,
            approverEmail: user.email,
            adLink: adLink, // Make sure you include the actual ad link here
            adTitle: existingAd.title,
            adId: existingAd.id,
            createdBy: existingAd.createdBy,
            firstName: user.firstName,
            lastName: user.lastName,
            brand: existingAd.brand,
          }
        );
        // Uncomment the following line to log the email sent response
        console.log("Email sent response:", emailResponse);
      } catch (emailError) {
        console.error("Error while sending email:", emailError);
      }
    } catch (error) {
      console.error("Error while sending for approval:", error);
    }
    setShowSent(true);
    handleClose();
  };

  return (
    <Card
      sx={{
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
        height: 68,
        position: "sticky",
        top: 0,
        zIndex: 1000,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AdsStatusDrawer
                data={data}
                userThatCreatedAd={userThatCreatedAd}
                selectedBrand={selectedBrand}
              />
            </Box>

            {/* <Box sx={{ display: "flex", gap: 1 }}></Box> */}
            <Menu
              anchorEl={anchorElApproval}
              open={Boolean(anchorElApproval)}
              onClose={handleClose}
              PaperProps={{
                sx: { width: "20ch", maxHeight: "30vh", overflow: "auto" },
              }}
            >
              {UsersList.filter((user) => user.role === "manager").map(
                (user) => (
                  <MenuItem
                    key={user.id}
                    onClick={() => handleApproval(user)}
                    sx={{ fontSize: "0.8rem" }}
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </MenuItem>
                )
              )}
            </Menu>

            <Divider orientation='vertical' flexItem />
            <ColorInfoCard editingResolution={editingResolution} />
            <Divider orientation='vertical' flexItem />

            <Box sx={{ display: "flex", width: 140 }}>
              <EditingResolutionSwitch
                editStates={editStates}
                setEditStates={setEditStates}
              />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", ml: -4 }}>
              <Switch
                color='primary'
                checked={Boolean(values?.partnership)}
                onChange={handlePartnershipClick}
                size='small'
              />
              <Typography variant='body2' sx={{ fontSize: "0.8rem" }}>
                Partnership
              </Typography>

              <IconButton
                onClick={togglePartnership}
                color='primary'
                disabled={!partnership}
              >
                <ChangeHistoryIcon
                  sx={{
                    transform: showPartnership
                      ? "rotate(0deg)"
                      : "rotate(180deg)",
                    fontSize: 18,
                    mt: 0.3,
                  }}
                />
              </IconButton>

              <PartnershipComponent
                values={values}
                setValues={setValues}
                handleColorChange={handleColorChange}
                anchorEl={partnershipAnchorEl}
                open={showPartnership}
                onClose={() => setShowPartnership(false)}
              />
            </Box>

            {/* <Divider orientation='vertical' flexItem />     */}

            {/* <Divider orientation='vertical' flexItem /> */}

            <Divider orientation='vertical' flexItem />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {languageSelectedFromMenu && !isLoadingOpenAi ? (
                <Button
                  sx={{ height: 30 }}
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    handleTranslate();
                    setLanguageSelectedFromMenu(false);
                  }}
                >
                  Translate
                </Button>
              ) : (
                <LanguageDropdown
                  language={language}
                  setLanguage={(lang) => {
                    setLanguage(lang);
                    setLanguageSelectedFromMenu(true);
                  }}
                />
              )}
            </Box>

            <Box sx={{ mt: 0.3 }}>
              <Button
                sx={{ height: 30 }}
                variant='text'
                color='primary'
                onClick={handleSaveLayout}
              >
                Save as Layout
              </Button>
              <SaveLayoutModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                values={values}
              />
            </Box>

            {/*TRANSLATE WITH AI */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <VoiceCommand
                onCommandDetected={handleVoiceCommand}
                setLanguageFromVoice={setLanguage}
                voiceCommandStatus={voiceCommandStatus}
                setVoiceCommandStatus={setVoiceCommandStatus}
                language={language}
                isLoadingOpenAi={isLoadingOpenAi}
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mr: -2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              {+progress === 0 || +progress === 100 ? (
                <Button onClick={handleMenuOpen}>
                  <Typography sx={{ fontSize: "0.8rem" }}>DOWNLOAD</Typography>
                  <DownloadIcon sx={{ fontSize: "1rem" }} />
                </Button>
              ) : (
                <>
                  <LinearProgress
                    variant='determinate'
                    value={progress}
                    sx={{ width: "100px", mr: 1 }} // Adjust the width as per your requirement
                  />
                  <Typography>{`${Math.round(progress)}%`}</Typography>
                </>
              )}

              <Box
                ref={anchorRef}
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            </Box>

            <DownloadMenu
              networkTags={networkTags}
              anchorEl={anchorEl}
              handleClose={handleCloseDownload}
              handleCheckboxChange={handleCheckboxChange}
              handleDownload={handleDownload}
              selectedResolutions={selectedResolutions}
            />

            <Box sx={{ display: "flex", mt: 7 }}>
              {showSent ? (
                <motion.div
                  initial={{ opacity: 0, scale: 0.7 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 1.3 }}
                  key='sentButton'
                >
                  <Button
                    variant='contained'
                    color='success'
                    sx={{
                      fontSize: "0.7rem",
                      height: 35,
                      backgroundColor: theme.palette.primary.approve,
                    }}
                  >
                    Sent <CheckIcon sx={{ ml: 1, fontSize: "small" }} />
                  </Button>
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0, scale: 0.7 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 1.3 }}
                  key='approvalButton'
                >
                  <Button
                    onClick={handleClick}
                    variant='outlined'
                    color='primary'
                    sx={{ fontSize: "0.8rem", height: 35 }}
                  >
                    APPROVAL
                    <SendIcon sx={{ fontSize: "small", ml: 1 }} />
                  </Button>
                </motion.div>
              )}
            </Box>

            <Box>
              {showUpdated ? (
                <motion.div
                  initial={{ opacity: 0, scale: 0.7 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 1.3 }}
                >
                  <Button
                    variant='contained'
                    color='success'
                    sx={{
                      fontSize: "0.8rem", // Matched fontSize
                      height: 150, // Matched height
                      backgroundColor: theme.palette.primary.approve, // Specific color for this button
                    }}
                  >
                    Updated <CheckIcon sx={{ ml: 1, fontSize: "small" }} />
                  </Button>
                </motion.div>
              ) : (
                <Button
                  onClick={onUpdate}
                  variant='contained'
                  sx={{
                    fontSize: "0.8rem",
                    height: 150,
                    backgroundColor: theme.palette.primary.itemColorAds,
                  }}
                >
                  UPDATE
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </CardContent>
      <LinearProgress
        variant={
          isLoadingOpenAi || (+progress !== 0 && +progress !== 100)
            ? "indeterminate"
            : "determinate"
        }
        value={
          isLoadingOpenAi || (+progress !== 0 && +progress !== 100)
            ? undefined
            : 100
        }
        sx={{
          height: 6,
          width: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: "transparent",
          "& .MuiLinearProgress-bar": {
            background: "linear-gradient(90deg, #A06EAF, #80AFDE)",
          },
        }}
      />
    </Card>
  );
};

export default EditorActionCard;
