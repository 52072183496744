import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import axios from "axios";
import { cloneDeep } from "lodash";
import { isEqual } from "lodash";

import "./Quill.css";

import { Box, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { approveAd, editAd, statusAd } from "./features/adSlice";
import { AuthContext } from "../../context/authContext";
import { ACTIONS_URL, ADS_URL } from "../../config";

import { downloadAllImages } from "./helpers/imageDownloader";
import UserCard from "../../components/UserCard";
import AdReadyHelp from "../../components/AdReadyHelp";
import AdsViewTabs from "./components/AdsViewTabs";
import { resolutions } from "./helpers/resolutions";
import {
  attributes,
  logos,
  networkTags,
  types,
} from "./helpers/DestrucuringAdsAndCalculateLogos";
import { setAdsData } from "./features/adsDataSlice";
import axiosInstance from "../../services/axiosInstance";
import { useTheme } from "@mui/material/styles";
import { microsoftStandardCalculation } from "./utils/microsoftStandardCalculation";
import { intelStandardCalculation } from "./utils/intelStandardCalculation";
import { gigabyteStandardCalculation } from "./utils/gigabyteStandardCalculation";
import { brandCalculationMapping } from "./helpers/brandCalculationMapping";

export default function AdView({ handleDrawerToggle }) {
  const AdsList = useSelector((store) => store.ads);
  const CategoriesList = useSelector((store) => store.categories);
  const UsersList = useSelector((store) => store.users);

  const adsData = useSelector((store) => store?.adsData) || [];
  const { id } = useParams();

  const existingAd = AdsList?.find((ad) => {
    return ad.id === +id;
  });

  const [currentCategory, setCurrentCategory] = useState("");
  const [currentTab, setCurrentTab] = useState("All");
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedResolution, setSelectedResolution] = useState("");
  const [sortedNetworkTags, setSortedNetworkTags] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [logoCornersConfig, setLogoCornersConfig] = useState({
    logoBrand1: "topLeft",
    logoBrand2: "topRight",
    logoBrand3: "bottomLeft",
    logoThirdParty: "bottomRight",
  });
  const [partnership, setPartnership] = useState(false);

  const {
    title,
    description,
    cat,
    headline,
    subHeadline,
    backgroundimage,
    productImage,
    logoBrand1,
    logoBrand2,
    logoBrand3,
    logoThirdParty,
    sticker1,
    sticker2,
    sticker3,
    call_to_action,
    positions,
    status,
  } = existingAd;

  const updatedPositions = positions;

  useEffect(() => {
    const currentViewedCategory = CategoriesList.find(
      (cat) => cat.id === existingAd?.category_id
    );
    setCurrentCategory(currentViewedCategory);
  }, [CategoriesList, id]);

  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const theme = useTheme();

  const [values, setValues] = useState({
    title,
    description,
    cat,
    headline,
    subHeadline,
    backgroundimage,
    productImage,
    logoBrand1,
    logoBrand2,
    logoBrand3,
    logoThirdParty,
    sticker1,
    sticker2,
    sticker3,
    call_to_action,
    status,
    positions: updatedPositions,
  });

  const [progress, setProgress] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const filterAndSortTags = () => {
    const availableResolutions = values.positions.resolutions || []; // Fallback to empty array if undefined
    const editingResolution = "1200x630";
    const filteredAndSorted = {};

    for (let network in networkTags) {
      // First, ensure editingResolution is included
      let resolutions = new Set([editingResolution, ...availableResolutions]);

      // Then filter based on the resolutions available
      const filtered = networkTags[network].filter((resolution) =>
        resolutions.has(resolution)
      );

      // Then sort the filtered resolutions
      filteredAndSorted[network] = filtered.sort((a, b) => {
        if (a === selectedResolution) return -1;
        if (b === selectedResolution) return 1;
        const [widthA, heightA] = a.split("x").map(Number);
        const [widthB, heightB] = b.split("x").map(Number);
        return widthB * heightB - widthA * heightA;
      });
    }

    setCurrentPage(0); // Reset pagination or any other UI control to initial state
    setSortedNetworkTags(filteredAndSorted); // Update the state with the filtered and sorted tags
  };

  useEffect(() => {
    filterAndSortTags();
    // Add values.positions.resolutions to the dependency array so that the effect runs whenever it changes
  }, [selectedResolution, values.positions.resolutions]);

  function getProp(obj, props) {
    return props.reduce((prev, prop) => prev && prev[prop], obj);
  }

  const logoBrand1Values = { height: null, width: null, partnership: true };

  const prevPositionsRef = useRef(values.positions);

  useEffect(() => {
    const currentViewedCategory = CategoriesList.find(
      (cat) => cat.id === existingAd?.category_id
    );
    setCurrentCategory(currentViewedCategory);
  }, [CategoriesList, id]);

  const updateAdsData = useCallback(() => {
    let adsDataTemp = { ...adsData };
    let positionsTemp = cloneDeep(values.positions);
    let logoBrand1Corner;
    let sameCornerAsLogoBrand1 = false;

    const logoTypes = [
      "logoBrand1",
      "logoBrand2",
      "logoBrand3",
      "logoThirdParty",
    ];

    resolutions.forEach((position) => {
      const [xRes, yRes] = position.split("x").map(Number);

      const cornersLogoPositions = {
        topLeft: { x: 0, y: 0 },
        topRight: { x: xRes, y: 0 },
        bottomLeft: { x: 0, y: yRes },
        bottomRight: { x: xRes, y: yRes },
      };

      logoTypes.forEach((type) => {
        let newValues;

        attributes[type].forEach((attribute) => {
          let attributeKey = attribute;

          if (
            (type === "subHeadline" || type === "disclaimer") &&
            attribute === "headline"
          ) {
            attributeKey = type === "subHeadline" ? "subHeadline" : "headline";
          }

          let key =
            attributeKey !== "headline" && attributeKey !== "subHeadline"
              ? `${attribute}${
                  type.charAt(0).toUpperCase() + type.slice(1)
                }${position}`
              : `${type}${position}`;

          key = key.charAt(0).toLowerCase() + key.slice(1);

          if (
            logos.includes(type) &&
            ["x", "y", "width", "height"].includes(attribute)
          ) {
            const element = getProp(values, ["positions", type, position]);

            if (element && "width" in element && "height" in element) {
              if (logos.includes(type) && !newValues) {
                const selectedCorner = logoCornersConfig[type];

                if (type === "logoBrand1") {
                  logoBrand1Corner = selectedCorner;
                }

                if (
                  type !== "logoBrand1" &&
                  selectedCorner === logoBrand1Corner
                ) {
                  sameCornerAsLogoBrand1 = true;
                } else {
                  sameCornerAsLogoBrand1 = false;
                }

                const selectedBaseCorner = cornersLogoPositions[selectedCorner];

                const selectedCalculationFunction =
                  brandCalculationMapping[existingAd.brand];
                if (selectedCalculationFunction) {
                  newValues = selectedCalculationFunction(
                    xRes,
                    yRes,
                    selectedCorner,
                    selectedBaseCorner,
                    element,
                    type,
                    sameCornerAsLogoBrand1,
                    logoBrand1Values,
                    partnership
                  );

                  // Ensure newValues don't have negative values
                  newValues.position.x = Math.max(0, newValues.position.x);
                  newValues.position.y = Math.max(0, newValues.position.y);
                  newValues.width = Math.max(0, newValues.width);
                  newValues.height = Math.max(0, newValues.height);

                  let originalElement = getProp(values, [
                    "positions",
                    type,
                    position,
                  ]);
                  let originalElementCopy = { ...originalElement };

                  originalElementCopy.height = newValues.height;
                  originalElementCopy.width = newValues.width;
                  originalElementCopy.x = newValues.position.x;
                  originalElementCopy.y = newValues.position.y;
                  originalElementCopy.selectedCorner = selectedCorner;
                  originalElementCopy.selectedCornerPosition =
                    newValues.selectedCornerPosition;

                  adsDataTemp[`selectedCorner${type}${xRes}x${yRes}`] =
                    selectedCorner;
                  adsDataTemp[`selectedCornerPosition${type}${xRes}x${yRes}`] =
                    newValues.selectedCornerPosition;

                  // Update positionsTemp with the calculated values
                  if (!positionsTemp[type]) {
                    positionsTemp[type] = {};
                  }
                  if (!positionsTemp[type][position]) {
                    positionsTemp[type][position] = {};
                  }
                  positionsTemp[type][position] = {
                    ...positionsTemp[type][position],
                    ...originalElementCopy,
                  };
                } else {
                  console.error(
                    `Calculation function for brand ${existingAd.brand} is not available.`
                  );
                }
              }

              let data =
                newValues && newValues[attribute]
                  ? newValues[attribute]
                  : newValues && newValues.position[attribute];
              adsDataTemp[key] = data;
            }
          } else {
            let data = getProp(values, [
              "positions",
              type,
              position,
              attributeKey,
            ]);

            // Ensure data is not undefined before updating
            adsDataTemp[key] = data != null ? data : "";

            // Update positionsTemp with the data
            if (!positionsTemp[type]) {
              positionsTemp[type] = {};
            }
            if (!positionsTemp[type][position]) {
              positionsTemp[type][position] = {};
            }
            positionsTemp[type][position][attributeKey] =
              data != null ? data : "";
          }
        });
      });
    });

    // dispatch(setAdsData(adsDataTemp));

    if (!isEqual(prevPositionsRef.current, positionsTemp)) {
      prevPositionsRef.current = positionsTemp;
      setValues((prevValues) => ({
        ...prevValues,
        positions: positionsTemp,
      }));
    }

    setIsLoading(false);
  }, [
    logoCornersConfig,
    values.positions,
    partnership,
    adsData,
    existingAd.brand,
    dispatch,
  ]);

  useEffect(() => {
    updateAdsData();
  }, [updateAdsData]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(0);
    const resetResolutions = {};
    for (let resolution of Object.values(networkTags).flat()) {
      resetResolutions[resolution] = false;
    }
    setSelectedResolutions(resetResolutions);
  };

  const ad160x600Ref = useRef();
  const ad320x50Ref = useRef();
  const ad300x250Ref = useRef();
  const ad300x600Ref = useRef();
  const ad600x829Ref = useRef();
  const ad728x90Ref = useRef();
  const ad875x225Ref = useRef();
  const ad960x676Ref = useRef();
  const ad970x250Ref = useRef();
  const ad1080x1080Ref = useRef();
  const ad1080x1920Ref = useRef();
  const ad1200x630Ref = useRef();
  const ad1200x628Ref = useRef();
  const ad1200x1200Ref = useRef();
  const ad1900x620Ref = useRef();

  const [selectedResolutions, setSelectedResolutions] = useState({
    all: false,
    "ad160x600.png": false,
    "ad320x50.png": false,
    "ad300x250.png": false,
    "ad300x600.png": false,
    "ad600x829.png": false,
    "ad728x90.png": false,
    "ad875x225.png": false,
    "ad960x676.png": false,
    "ad970x250.png": false,
    "ad1080x1080.png": false,
    "ad1080x1920.png": false,
    "ad1200x630.png": false,
    "ad1200x1200.png": false,
    "ad1900x620.png": false,
  });

  // DOWNLOAD IMAGES

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedResolutions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleDownload = async () => {
    setCurrentTab("All");
    handleMenuClose();
    const componentsToDownload = Object.entries(selectedResolutions)
      .filter(([resolution, isSelected]) => isSelected)
      .reduce((acc, [resolution, _]) => {
        const filename = `ad${resolution}.png`;
        return { ...acc, [filename]: allComponents[filename] };
      }, {});

    await downloadAllImages(componentsToDownload, (percentage) => {
      setProgress(percentage);
    });

    // Add response content if needed
    const response = {
      /* response content here if needed */
    };

    await axiosInstance.post(ACTIONS_URL, {
      actionType: "Download",
      objectType: "Image",
      userId: currentUser.id,
      clientId: currentUser.clientIdUsers,
      adTitle: values.title,
      content: response,
    });
  };

  const allComponents = {
    "ad160x600.png": ad160x600Ref,
    "ad320x50.png": ad320x50Ref,
    "ad300x250.png": ad300x250Ref,
    "ad300x600.png": ad300x600Ref,
    "ad600x829.png": ad600x829Ref,
    "ad728x90.png": ad728x90Ref,
    "ad875x225.png": ad875x225Ref,
    "ad960x676.png": ad960x676Ref,
    "ad970x250.png": ad970x250Ref,
    "ad1080x1080.png": ad1080x1080Ref,
    "ad1080x1920.png": ad1080x1920Ref,
    "ad1200x630.png": ad1200x630Ref,
    "ad1200x628.png": ad1200x628Ref,

    "ad1200x1200.png": ad1200x1200Ref,
    "ad1900x620.png": ad1900x620Ref,
  };

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      id: id,
    }));
  }, [id]);

  // <<<<<<<<<<<<<<<<<<<<<<<<<< HANDLE UPDATE >>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const adStatus = existingAd?.status;

  const handleStatusApprove = async () => {
    const response = {
      /* response content here if needed */
    };
    try {
      await axiosInstance
        .put(`${ADS_URL}/status/${id}`, {
          id,
          status: "approved",
        })

        .then((response) => {
          axiosInstance.post(ACTIONS_URL, {
            actionType: "Approve",
            objectType: "Ad",
            userId: currentUser.id,
            clientId: currentUser.clientIdUsers,
            adTitle: values.title,
            content: response,
            adId: existingAd.id,
          });
        })
        .catch((error) => {
          // handle the error
        });

      dispatch(
        statusAd({
          id: existingAd.id, // Include the ad id here
          status: "approved",
        })
      );

      navigate("/ads");
    } catch (error) {
      // You can handle the error here if the update or post request fails
      console.error(error);
    }
  };

  const handleStatusReject = async () => {
    await axios
      .put(`${ADS_URL}/status/${id}`, {
        id,
        status: "rejected",
      })

      .then((response) => {
        axiosInstance.post(ACTIONS_URL, {
          actionType: "Reject",
          objectType: "Ad",
          userId: currentUser.id,
          clientId: currentUser.clientIdUsers,
          adTitle: values.title,
          content: response,
          adId: existingAd.id,
        });
      })
      .catch((error) => {
        // handle the error
      });

    dispatch(
      statusAd({
        id: existingAd.id, // Include the ad id here
        status: "rejected",
      })
    );
    navigate("/ads");
  };

  const cardColors = {
    item1: theme.palette.primary.itemColorAds,
    item2: "#777777",
    item3: "#777777",
  };

  const backgroundColor = "linear-gradient(45deg, #A06EAF 30%, #80AFDE 90%)";

  return (
    <Grid sx={{ pl: 10, pr: 2 }} container spacing={2}>
      <Grid item xs={12} md={7}>
        <UserCard
          handleDrawerToggle={handleDrawerToggle}
          handleStatusApprove={handleStatusApprove}
          handleStatusReject={handleStatusReject}
          currentCategory={currentCategory}
          rows={AdsList}
          cardColors={cardColors}
          currentUser={currentUser}
          numberOfItems={AdsList}
          cardHeader={"Created by"}
          backgroundColor={backgroundColor}
          pendingAds={2}
          networkTags={sortedNetworkTags}
          progress={progress}
          handleCheckboxChange={handleCheckboxChange}
          handleDownload={handleDownload}
          anchorEl={anchorEl}
          handleMenuOpen={handleMenuOpen}
          handleClose={handleMenuClose}
          selectedResolutions={selectedResolutions}
          anchorRef={anchorRef}
          UsersList={UsersList}
        />
      </Grid>

      <Grid item xs={12} md={5}>
        <AdReadyHelp backgroundColor={backgroundColor} />
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row" }}></Box>
        <AdsViewTabs
          editable={false}
          showIt={false}
          // adsData={adsData}
          showProduct={true}
          showHeadline={true}
          showSubheadline={true}
          showSticker1={true}
          networkTags={networkTags}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          ad160x600Ref={ad160x600Ref}
          ad320x50Ref={ad320x50Ref}
          ad300x250Ref={ad300x250Ref}
          ad300x600Ref={ad300x600Ref}
          ad600x829Ref={ad600x829Ref}
          ad728x90Ref={ad728x90Ref}
          ad875x225Ref={ad875x225Ref}
          ad960x676Ref={ad960x676Ref}
          ad970x250Ref={ad970x250Ref}
          ad1080x1080Ref={ad1080x1080Ref}
          ad1080x1920Ref={ad1080x1920Ref}
          ad1200x1200Ref={ad1200x1200Ref}
          ad1200x630Ref={ad1200x630Ref}
          ad1900x620Ref={ad1900x620Ref}
          sortedNetworkTags={sortedNetworkTags}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selectedResolution={selectedResolution}
          setSelectedResolution={setSelectedResolution}
          updateTextDimensions={"false"}
          values={values}
          status={adStatus}
        />
      </Grid>
    </Grid>
  );
}
