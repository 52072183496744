import React, { useState } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import ReplayIcon from "@mui/icons-material/Replay";
import CloseIcon from "@mui/icons-material/Close";
import EffectsIcon from "@mui/icons-material/AutoAwesome";
import EffectsGrid from "./EffectsGrid";

const ControlToolbar = ({
  isPlaying,
  resolutions,
  selectedResolution,
  handlePlay,
  handlePause,
  handleStop,
  handleRewind,
  handleResolutionChange,
  onClose,
  timelineLength,
  setTimelineLength,
  currentTime,
  applyEffect,
  toggleInOut, // New prop to handle show/hide IN/OUT
  selectedParent, // New prop to pass the selected parent item
}) => {
  const [effectsOpen, setEffectsOpen] = useState(false);

  const handleEffectsOpen = () => {
    setEffectsOpen(true);
  };

  const handleEffectsClose = () => {
    setEffectsOpen(false);
  };

  const handleEffectClick = (effect) => {
    console.log("Effect clicked:", effect);
    applyEffect(effect);
    setEffectsOpen(false);
  };

  return (
    <>
      <Toolbar
        sx={{
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: "0 8px",
          backgroundColor: "#f5f5f5",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          minHeight: "36px",
        }}
      >
        <IconButton
          onClick={handlePlay}
          disabled={isPlaying}
          color='primary'
          sx={{ padding: "4px" }}
        >
          <PlayArrowIcon fontSize='small' />
        </IconButton>
        <IconButton
          onClick={handlePause}
          disabled={!isPlaying}
          color='primary'
          sx={{ padding: "4px" }}
        >
          <PauseIcon fontSize='small' />
        </IconButton>
        <IconButton
          onClick={handleStop}
          color='primary'
          sx={{ padding: "4px" }}
        >
          <StopIcon fontSize='small' />
        </IconButton>
        <IconButton
          onClick={handleRewind}
          color='primary'
          sx={{ padding: "4px" }}
        >
          <ReplayIcon fontSize='small' />
        </IconButton>
        <Typography
          variant='body2'
          sx={{ marginLeft: "8px", marginRight: "8px", minWidth: "70px" }}
        >
          {`${String(Math.floor(currentTime / 60000)).padStart(
            2,
            "0"
          )}:${String(Math.floor((currentTime % 60000) / 1000)).padStart(
            2,
            "0"
          )}:${String(currentTime % 1000).padStart(3, "0")}`}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <TextField
          label='Length (sec)'
          type='number'
          value={timelineLength / 1000}
          onChange={(e) => setTimelineLength(e.target.value * 1000)}
          sx={{
            width: 80, // Directly set the width to make it smaller
            height: 28,
            fontSize: "0.75rem",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "0 8px",
            backgroundColor: "#fff",
            marginRight: "8px",
            "& .MuiInputBase-root": {
              height: "100%", // Ensure the input is the same height as the Select
              "& input": {
                fontSize: "0.75rem", // Match input font size
                padding: "0 8px", // Match padding
              },
            },
            "& .MuiInputLabel-root": {
              fontSize: "0.75rem", // Match label font size
              top: "-6px", // Adjust label position
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // Remove duplicate border
            },
          }}
          InputLabelProps={{ shrink: true }} // Ensure label is correctly positioned
          inputProps={{ min: 1, step: 1 }}
        />
        <Select
          value={selectedResolution}
          onChange={handleResolutionChange}
          displayEmpty
          inputProps={{ "aria-label": "Select Resolution" }}
          sx={{
            minWidth: 100,
            height: 28,
            fontSize: "0.75rem", // Smaller font size
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "0 8px", // Smaller padding
            backgroundColor: "#fff",
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  fontSize: "0.75rem", // Smaller font size for menu items
                },
              },
            },
          }}
        >
          {resolutions.map((resolution) => (
            <MenuItem
              key={resolution}
              value={resolution}
              sx={{ fontSize: "0.75rem" }} // Smaller font size for menu items
            >
              {resolution}
            </MenuItem>
          ))}
        </Select>
        {selectedParent && (
          <>
            <Button
              onClick={() => toggleInOut("in")}
              variant='contained'
              color='primary'
              sx={{ ml: 2 }}
            >
              Toggle IN
            </Button>
            <Button
              onClick={() => toggleInOut("out")}
              variant='contained'
              color='primary'
              sx={{ ml: 2 }}
            >
              Toggle OUT
            </Button>
          </>
        )}

        <IconButton onClick={onClose} color='primary' sx={{ padding: "4px" }}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </Toolbar>
      <EffectsGrid
        open={effectsOpen}
        onClose={handleEffectsClose}
        onEffectClick={handleEffectClick}
      />
    </>
  );
};

export default ControlToolbar;
