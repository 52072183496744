import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { ConditionalImage } from "../../banners/pages/components/ConditionalImage";
import { motion } from "framer-motion";
import {
  boxStyle,
  textStyle,
  imageContainStyle,
} from "../../banners/adStylesPreview";
import { ASSETS_URL } from "../../../../config";

const AdAnimationPreview = React.forwardRef(
  ({ data, visibility, resolution = "1200x630", currentTime }, ref) => {
    const [adsData, setAdsData] = useState(null);
    const [textStyles, setTextStyles] = useState({});
    const [state, setState] = useState({});
    const [bannerWidth, bannerHeight] = resolution.split("x").map(Number);

    useEffect(() => {
      if (data && data.positions) {
        try {
          const parsedData = data.positions;
          setAdsData((prevAdsData) => {
            if (JSON.stringify(prevAdsData) !== JSON.stringify(parsedData)) {
              return parsedData;
            }
            return prevAdsData;
          });
        } catch (error) {
          console.error("Unable to parse data.positions", error);
        }
      }
    }, [data]);

    useEffect(() => {
      if (adsData) {
        const keys = Object.keys(adsData);
        if (
          keys.includes("headline") ||
          keys.includes("subHeadline") ||
          keys.includes("disclaimer") ||
          keys.some((key) => key.startsWith("text"))
        ) {
          setTextStyles({
            headline: textStyle(adsData, "headline", resolution),
            subHeadline: textStyle(adsData, "subHeadline", resolution),
            disclaimer: textStyle(adsData, "disclaimer", resolution),
            ...keys
              .filter((key) => key.startsWith("text"))
              .reduce((acc, key) => {
                acc[key] = textStyle(adsData, key, resolution);
                return acc;
              }, {}),
          });
        }
      }
    }, [adsData, resolution]);

    useEffect(() => {
      if (adsData) {
        const items = [
          "product",
          "headline",
          "subHeadline",
          "logoBrand1",
          "logoBrand2",
          "logoBrand3",
          "logoThirdParty",
          "badge",
          "sticker1",
          "cta", // Added cta
          "disclaimer",
          ...Object.keys(adsData).filter((key) => key.startsWith("text")),
          ...Object.keys(adsData).filter((key) => key.startsWith("background")),
          ...Object.keys(adsData).filter((key) => key.startsWith("circle")),
          ...Object.keys(adsData).filter((key) => key.startsWith("rectangle")),
          ...Object.keys(adsData).filter((key) => key.startsWith("line")),
        ];
        const newState = items.reduce((acc, item) => {
          const elementData = adsData?.[item]?.[resolution];
          acc[`${item}Position`] = {
            x: elementData?.x,
            y: elementData?.y,
          };
          if (
            item !== "headline" &&
            item !== "subHeadline" &&
            item !== "disclaimer" &&
            !item.startsWith("text")
          ) {
            acc[`${item}Size`] = {
              width: elementData?.width,
              height: elementData?.height,
            };
          }
          return acc;
        }, {});
        setState((prevState) => {
          if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
            return newState;
          }
          return prevState;
        });
      }
    }, [adsData, resolution]);

    const getAnimationProps = (animation) => {
      if (!animation) {
        // If animation is undefined, return the default state
        return {
          initial: { opacity: 1 },
          animate: { opacity: 1 },
          transition: { duration: 0 },
        };
      }

      const inStartTime = new Date(animation.inAnimation?.start).getTime();
      const inEndTime = new Date(animation.inAnimation?.end).getTime();
      const outStartTime = new Date(animation.outAnimation?.start).getTime();
      const outEndTime = new Date(animation.outAnimation?.end).getTime();

      const currentTimeMs = currentTime;

      const getTransitionProps = (anim, duration) => {
        let transition = { duration };

        switch (
          anim?.type?.toLowerCase() // Safeguard against undefined anim
        ) {
          case "spring":
            transition = {
              type: "spring",
              stiffness: anim?.stiffness || 80,
              damping: anim?.damping || 10,
            };
            break;
          case "ease":
            transition = {
              ease: "easeInOut",
              duration,
            };
            break;
          case "linear":
            transition = {
              ease: "linear",
              duration,
            };
            break;
          case "ease in":
            transition = {
              ease: "easeIn",
              duration,
            };
            break;
          case "ease out":
            transition = {
              ease: "easeOut",
              duration,
            };
            break;
          case "ease in out":
            transition = {
              ease: "easeInOut",
              duration,
            };
            break;
          default:
            transition = { duration };
        }

        return transition;
      };

      const applyEffectAnimation = (effectName, initial, animate) => {
        switch (effectName?.toLowerCase()) {
          case "fade in":
            initial.opacity = 0;
            animate.opacity = 1;
            break;
          case "fade out":
            initial.opacity = 1;
            animate.opacity = 0;
            break;
          case "zoom in":
            initial.scale = 0;
            animate.scale = 1;
            break;
          case "zoom out":
            initial.scale = 1;
            animate.scale = 0;
            break;
          case "rotate in":
            initial.rotate = 0;
            animate.rotate = 360;
            break;
          case "rotate out":
            initial.rotate = 360;
            animate.rotate = 0;
            break;
          case "flip in":
            initial.rotateY = 0;
            animate.rotateY = 180;
            break;
          case "flip out":
            initial.rotateY = 180;
            animate.rotateY = 0;
            break;
          case "scale in":
            initial.scale = 0;
            animate.scale = 1;
            break;
          case "scale out":
            initial.scale = 1;
            animate.scale = 0;
            break;
          case "bounce in":
            initial.scale = 0.5;
            animate.scale = 1.2;
            break;
          case "bounce out":
            initial.scale = 1.2;
            animate.scale = 0.5;
            break;
          case "wobble":
            initial.x = 0;
            animate.x = [0, -20, 20, -20, 20, 0];
            animate.rotate = [0, -5, 5, -5, 5, 0];
            break;
          case "pulse":
            initial.scale = 1;
            animate.scale = [1, 1.1, 1];
            break;
          case "shake":
            initial.x = 0;
            animate.x = [0, -10, 10, -10, 10, 0];
            break;
          default:
            break;
        }
      };

      if (currentTimeMs >= inStartTime && currentTimeMs <= inEndTime) {
        const inDuration = (inEndTime - inStartTime) / 1000; // Duration in seconds
        let initial = {};
        let animate = {};

        applyEffectAnimation(
          animation.inAnimation?.effectName,
          initial,
          animate
        );

        if (animation.inAnimation?.effectName?.toLowerCase() === "position") {
          switch (animation.inAnimation?.direction) {
            case "up":
              initial = { y: bannerHeight };
              animate = { y: 0 };
              break;
            case "down":
              initial = { y: -bannerHeight };
              animate = { y: 0 };
              break;
            case "left":
              initial = { x: bannerWidth };
              animate = { x: 0 };
              break;
            case "right":
              initial = { x: -bannerWidth };
              animate = { x: 0 };
              break;
            default:
              break;
          }
        }

        return {
          initial,
          animate,
          transition: getTransitionProps(animation?.inAnimation, inDuration),
        };
      } else if (currentTimeMs >= outStartTime && currentTimeMs <= outEndTime) {
        const outDuration = (outEndTime - outStartTime) / 1000; // Duration in seconds
        let initial = {};
        let animate = {};

        applyEffectAnimation(
          animation.outAnimation?.effectName,
          initial,
          animate
        );

        if (animation.outAnimation?.effectName?.toLowerCase() === "position") {
          switch (animation.outAnimation?.direction) {
            case "up":
              initial = { y: 0 };
              animate = { y: -bannerHeight };
              break;
            case "down":
              initial = { y: 0 };
              animate = { y: bannerHeight };
              break;
            case "left":
              initial = { x: 0 };
              animate = { x: -bannerWidth };
              break;
            case "right":
              initial = { x: 0 };
              animate = { x: bannerWidth };
              break;
            default:
              break;
          }
        }

        return {
          initial,
          animate,
          transition: getTransitionProps(animation?.outAnimation, outDuration),
        };
      }

      // No animation, return default state
      return {
        initial: { opacity: 1 },
        animate: { opacity: 1 },
        transition: { duration: 0 },
      };
    };

    const renderElement = (elementKey) => {
      const elementData = adsData?.[elementKey]?.[resolution];
      if (!elementData || !elementData.visible) return null;

      const start = new Date(elementData.animation?.start).getTime() || 0;
      const end = new Date(elementData.animation?.end).getTime() || Infinity;

      if (currentTime < start || currentTime > end) {
        return null;
      }

      const style = {
        position: "absolute",
        left: `${elementData.x}px`,
        top: `${elementData.y}px`,
        width: `${elementData.width}px`,
        height: `${elementData.height}px`,
        background: elementData.color, // Gradient
        borderRadius: elementKey.includes("circle") ? "50%" : "0",
        zIndex: 1,
        ...(elementData.borderStyle && { border: elementData.borderStyle }),
        objectFit: "cover", // Ensure aspect ratio is maintained
      };

      return <Box key={elementKey} sx={style} />;
    };

    const renderConditionalImage = (item) => {
      const logoData = adsData?.[item]?.[resolution];
      if (!logoData || !logoData.visible) return null;

      const start = new Date(logoData.animation?.start).getTime() || 0;
      const end = new Date(logoData.animation?.end).getTime() || Infinity;

      if (currentTime < start || currentTime > end) {
        return null;
      }

      const style = {
        position: "absolute",
        top: `${state[`${item}Position`]?.y}px`,
        left: `${state[`${item}Position`]?.x}px`,
        width: `${state[`${item}Size`]?.width}px`,
        height: `${state[`${item}Size`]?.height}px`,
        objectFit: "contain", // Ensure the image fits within the container without distortion
        ...(logoData.borderStyle && { border: logoData.borderStyle }),
      };

      const animationProps = getAnimationProps(logoData.animation);

      return (
        <motion.div
          key={item}
          initial={animationProps.initial}
          animate={animationProps.animate}
          transition={animationProps.transition}
          style={style}
        >
          <ConditionalImage
            defaultSrc={data?.[item]}
            altSrc={`${ASSETS_URL}/${logoData?.imageUrl}`}
            style={{ width: "100%", height: "100%" }} // Ensure the image takes full size of the container
          />
        </motion.div>
      );
    };

    const renderBackground = () => {
      const bgData = adsData?.background?.[resolution];
      if (!bgData || !bgData.visible) return null;

      const start = new Date(bgData.animation?.start).getTime() || 0;
      const end = new Date(bgData.animation?.end).getTime() || Infinity;

      if (currentTime < start || currentTime > end) {
        return null;
      }

      return {
        backgroundImage: `url(${
          bgData.imageUrl
            ? `${ASSETS_URL}/${bgData.imageUrl}`
            : data?.background
        })`,
        backgroundColor: bgData.color,
        backgroundSize: `${state.backgroundSize?.width}px ${state.backgroundSize?.height}px`,
        backgroundPosition: `${state.backgroundPosition?.x}px ${state.backgroundPosition?.y}px`,
        backgroundRepeat: "no-repeat",
        objectFit: "cover", // Maintain aspect ratio for background image
      };
    };

    const renderProduct = () => {
      const productData = adsData?.product?.[resolution];
      if (!productData || !productData.visible) return null;

      const start = new Date(productData.animation?.start).getTime() || 0;
      const end = new Date(productData.animation?.end).getTime() || Infinity;

      if (currentTime < start || currentTime > end) {
        return null;
      }

      const style = {
        position: "absolute",
        top: `${state?.productPosition?.y}px`,
        left: `${state?.productPosition?.x}px`,
        width: `${state?.productSize?.width}px`,
        height: `${state?.productSize?.height}px`,
        objectFit: "contain", // Ensure the product image is displayed correctly without distortion
        pointerEvents: "none",
        ...(productData.borderStyle && {
          border: productData.borderStyle,
        }),
      };

      const animationProps = getAnimationProps(productData.animation);

      return (
        <motion.div
          key='product'
          initial={animationProps.initial}
          animate={animationProps.animate}
          transition={animationProps.transition}
          style={style}
        >
          <ConditionalImage
            altSrc={`${ASSETS_URL}/${productData?.imageUrl}`}
            style={{ width: "100%", height: "100%" }} // Ensure the image takes full size of the container
          />
        </motion.div>
      );
    };

    const renderText = (item) => {
      const elementData = adsData?.[item]?.[resolution];
      if (!elementData || !elementData.visible) return null;

      const start = new Date(elementData.animation?.start).getTime() || 0;
      const end = new Date(elementData.animation?.end).getTime() || Infinity;

      if (currentTime < start || currentTime > end) return null;

      const text = elementData[item] || "";

      const style = {
        position: "absolute",
        top: `${elementData.y}px`,
        left: `${elementData.x}px`,
        color: elementData.textColor,
        letterSpacing: `${elementData.letterSpacing}px`,
        lineHeight: elementData.lineHeight,
        textAlign: elementData.textAlign?.toLowerCase() || "left",
        fontSize: `${elementData.fontSize}px`,
        fontFamily: elementData.fontName,
        zIndex: elementData.zIndex,
        ...(elementData.borderStyle && {
          border: elementData.borderStyle,
        }),
        ...(elementData.textShadow && {
          textShadow: elementData.textShadow,
        }),
      };

      const animationProps = getAnimationProps(elementData.animation);

      return (
        <motion.div
          key={item}
          style={style}
          initial={animationProps.initial}
          animate={animationProps.animate}
          transition={animationProps.transition}
          dangerouslySetInnerHTML={{
            __html:
              typeof text === "string"
                ? text.replace(/<\/?p[^>]*>/g, "<br/>")
                : "",
          }}
        ></motion.div>
      );
    };

    return (
      <Box
        ref={ref}
        sx={{
          ...boxStyle(bannerWidth, bannerHeight),
          ...renderBackground(),
        }}
      >
        {[
          ...Object.keys(adsData || {}).filter((key) =>
            key.startsWith("background")
          ),
          ...Object.keys(adsData || {}).filter((key) =>
            key.startsWith("circle")
          ),
          ...Object.keys(adsData || {}).filter((key) =>
            key.startsWith("rectangle")
          ),
          ...Object.keys(adsData || {}).filter((key) => key.startsWith("line")),
        ].map(renderElement)}
        {[
          "logoBrand1",
          "logoBrand2",
          "logoBrand3",
          "logoThirdParty",
          "badge",
          "sticker1",
          "cta", // Added cta
        ].map(renderConditionalImage)}
        {[
          "headline",
          "subHeadline",
          "disclaimer",
          ...Object.keys(adsData || {}).filter((key) => key.startsWith("text")),
        ].map(renderText)}

        {renderProduct()}
      </Box>
    );
  }
);

export default AdAnimationPreview;
