import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axiosInstance from "../../services/axiosInstance";

import { BRANDS_URL } from "../../config";
import { setBackgrounds } from "../../pages/brands/features/backgroundReducer";
import { setProducts } from "../../pages/brands/features/productReducer";
import { setStickers } from "../../pages/brands/features/stickerReducer";
import { setCtas } from "../../pages/brands/features/ctaReducer";
import { setLogos } from "../../pages/brands/features/logoReducer";
import { setFonts } from "../../pages/brands/features/fontReducer";
import { setBadges } from "../../pages/brands/features/badgeReducer";
import { setElements } from "../../pages/brands/features/elementReducer";

export const useFetchBrandData = (brandId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async (endpoint, setDataFunction) => {
      try {
        const response = await axiosInstance.get(`${BRANDS_URL}/${endpoint}`);
        if (endpoint === "badges") {
          // console.log("Dispatching badges data:", response.data); // Log for badges data
        }
        dispatch(setDataFunction({ res: response.data }));
      } catch (error) {
        console.error("Error response:", error.response);
        console.error("Error message:", error.message);
      }
    };

    fetchData("backgrounds", setBackgrounds);
    fetchData("products", setProducts);
    fetchData("badges", setBadges); // This will now log the data being dispatched
    fetchData("stickers", setStickers);
    fetchData("ctas", setCtas);
    fetchData("logos", setLogos);
    fetchData("fonts", setFonts);
    fetchData("elements", setElements);
  }, [brandId, dispatch]);
};
