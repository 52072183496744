import React, { useRef, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Box,
  Divider,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import Banners from "./Banners";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";
import ResolutionDropdown from "./ResolutionDropdown";
import GradientLoader from "../../../components/GradientLoader";
import { useTheme } from "@mui/material/styles";
import TimelineEditor from "../animation/TimelineEditor";

const AdsViewTabs = ({
  networkTags,
  sortedNetworkTags,
  currentTab,
  setCurrentTab,
  currentPage,
  setCurrentPage,
  selectedResolution,
  setSelectedResolution,
  editable,
  values,
  backgroundScale,
  setValues,
  editingResolution,
  showImage,
  setShowImage,
  editStates,
  setEditingComponent,
  setEditingResolution,
  selectedBrand,
  showIt,
  handleElementDragResize,
  updateTextDimensions,
  state,
  isResizeMode,
  status,
  ...props
}) => {
  const bannersRef = useRef(null);
  const selectedNetworkTags = sortedNetworkTags[currentTab] || [];
  let bannersPerPage = 3;
  const totalBanners = selectedNetworkTags.length;
  const totalPages = Math.ceil(totalBanners / bannersPerPage);

  const [bannerIsLoading, setBannerIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // ...

  // <Drawer anchor='bottom' open={drawerOpen} onClose={toggleDrawer(false)}>
  //   {drawerOpen && (
  //     <TimelineEditor
  //       values={values}
  //       setValues={setValues}
  //       onClose={() => setDrawerOpen(false)}
  //     />
  //   )}
  // </Drawer>;

  // ...

  useEffect(() => {
    const timer = setTimeout(() => {
      setBannerIsLoading(false); // <-- Update state when done loading
    }, 50);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // <-- Update state when done loading
    }, 2200);
    return () => clearTimeout(timer);
  }, []);

  if (currentTab === "All") {
    bannersPerPage = selectedNetworkTags.length;
  }

  const handleTabChange = (newValue) => {
    setCurrentTab(newValue);
    setCurrentPage(0); // Reset page to 1
    setBannerIsLoading(true); // Set loading state to true when tab changes

    setTimeout(() => {
      setBannerIsLoading(false); // Reset loading state after 1 second
    }, 1000);
  };

  const iconMapping = {
    Facebook: <FacebookIcon />,
    Instagram: <InstagramIcon />,
    LinkedIn: <LinkedInIcon />,
    Web: <LanguageIcon />,
  };

  if (currentTab === "All") {
    bannersPerPage = selectedNetworkTags.length;
  }

  useEffect(() => {}, [currentPage]);

  return (
    <Box
      sx={{
        width: "100%",
        mx: "auto",
        mt: 0,
        position: "relative",
      }}
    >
      {/* Loader for isLoading */}
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            backgroundColor: theme.palette.primary.light,
          }}
        >
          <GradientLoader />
        </Box>
      ) : null}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "sticky",
          top: 0,
          zIndex: 5,
          backgroundColor: "background.default",
          flexWrap: isMobile ? "wrap" : "nowrap",
        }}
      >
        {Object.keys(sortedNetworkTags).map((tab, index) => (
          <React.Fragment key={tab}>
            <Button
              variant={currentTab === tab ? "contained" : "text"}
              color='primary'
              onClick={() => handleTabChange(tab)}
              sx={{
                maxWidth: isMobile ? "100%" : 182,
                maxHeight: 56,
                textTransform: "none",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {iconMapping[tab]}
              </Box>
              {tab}
            </Button>
            {index !== Object.keys(sortedNetworkTags).length - 1 && (
              <Divider orientation='vertical' flexItem />
            )}
          </React.Fragment>
        ))}
        <ResolutionDropdown
          sortedNetworkTags={sortedNetworkTags}
          currentTab={currentTab}
          selectedResolution={selectedResolution}
          setSelectedResolution={setSelectedResolution}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 5,
          padding: "8px 0",
          position: showIt ? "sticky" : "static",
          top: 180,
          backgroundColor: "background.default",
        }}
      >
        <Box sx={{ mt: 2 }}>
          {currentTab !== "All" ? (
            <>
              <Button
                disabled={currentPage === 0}
                onClick={() => setCurrentPage((prev) => prev - 1)}
              >
                <ArrowBack />
              </Button>
              {[...Array(totalPages)].map((_, index) => (
                <Button
                  key={index}
                  size='small'
                  variant={currentPage === index ? "contained" : "text"}
                  color={currentPage === index ? "primary" : "inherit"}
                  onClick={() => setCurrentPage(index)}
                  sx={{ margin: "0 2px" }}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                disabled={currentPage === totalPages - 1}
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                <ArrowForward />
              </Button>
            </>
          ) : (
            <Button
              size='small'
              variant='contained'
              color='primary'
              sx={{ margin: "0 2px" }}
            >
              1
            </Button>
          )}
        </Box>
      </Box>
      {/* <Drawer
        anchor='bottom'
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{ style: { height: "100%" } }}
      >
        <TimelineEditor
          values={values}
          onClose={toggleDrawer(false)}
          setValues={setValues}
        />
      </Drawer> */}

      {/* <Button variant='contained' color='primary' onClick={toggleDrawer(true)}>
        Open Timeline Drawer
      </Button> */}
      <div ref={bannersRef}>
        <Banners
          editable={editable}
          key={`banners-${currentTab}`}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          tags={selectedNetworkTags}
          networkTags={sortedNetworkTags}
          currentNetwork={currentTab}
          bannersPerPage={bannersPerPage}
          values={values}
          setValues={setValues}
          backgroundScale={backgroundScale}
          editingResolution={editingResolution}
          bannerIsLoading={bannerIsLoading}
          isLoading={isLoading}
          showImage={showImage}
          setShowImage={setShowImage}
          editStates={editStates}
          setEditingResolution={setEditingResolution}
          handleElementDragResize={handleElementDragResize}
          updateTextDimensions={updateTextDimensions}
          state={state}
          isResizeMode={isResizeMode}
          selectedBrand={selectedBrand}
          status={status}
          {...props}
        />
      </div>
    </Box>
  );
};

export default AdsViewTabs;
