import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { textStyleEdit, boxStyle } from "../adStylesPreview";
import { ASSETS_URL } from "../../../../config";
import { ConditionalImage } from "./components/ConditionalImage";
import { imageContainStyle } from "./bannerStyles";
import PartnershipBox from "../components/PartnershipBoxAmd";
import DraggableTextElement from "./components/DraggableTextElement";
import GradientLoader from "../../../../components/GradientLoader";
import DraggableResizableImage from "./components/DraggableResizableImage";
import DraggableResizableShape from "./components/DraggableResizableShape";
import { useTheme } from "@mui/material/styles";
import PartnershipBoxAmd from "../components/PartnershipBoxAmd";
import { CollectionsOutlined } from "@mui/icons-material";

const BannerDynamic = React.forwardRef(
  (
    {
      values,
      setEditingResolution,
      handleRightSidebarToggle,
      setValues,
      editable,
      editingResolution,
      resolution,
      bannerWidth,
      bannerHeight,
      bannerIsLoading,
      showImage,
      handleElementDragResize,
      updateTextDimensions,
      state,
      setResolution,
      isResizeMode,
      selectedBrand,
      editStates,
      status,
    },
    ref
  ) => {
    const editingComponent = useSelector((state) => state.editingComponent);
    const adsData = useSelector((store) => store?.adsData) || [];
    const theme = useTheme();

    const [textStyles, setTextStyles] = useState({});
    const [dynamicTexts, setDynamicTexts] = useState([]);
    const [logoDarkPercentages, setLogoDarkPercentages] = useState({});

    const toCamelCase = (str) => {
      return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
          return word.toUpperCase();
        })
        .replace(/\s+/g, "");
    };

    const updateTextContent = (item, newText) => {
      setValues((prevValues) => ({
        ...prevValues,
        positions: {
          ...prevValues.positions,
          [item]: {
            ...prevValues.positions[item],
            [resolution]: {
              ...prevValues.positions[item][resolution],
              [item]: newText,
            },
          },
        },
      }));
    };

    const logoHeight = adsData?.[`heightLogoBrand1${resolution}`] || 200;
    console.log(values);

    const verticalGuidelines = [
      logoHeight,
      bannerWidth - logoHeight,
      bannerWidth / 2,
      0,
      bannerWidth,
    ];

    const horizontalGuidelines = [
      0,
      logoHeight,
      bannerHeight - logoHeight,
      bannerHeight / 2,
      bannerHeight,
    ];

    const allItems = Object.keys(values.positions).map((key) => ({
      key,
      ...values.positions[key][resolution],
    }));

    console.log(allItems);

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          transform: "translateY(-10px)",
        }}
      >
        <Box
          ref={ref}
          sx={{
            ...boxStyle(bannerWidth, bannerHeight),
            boxShadow:
              editingResolution === resolution ? "0 0 0 2px #a06eaf" : "none",
            margin: editingResolution === resolution ? "2px" : "0px",
          }}
        >
          {bannerIsLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                position: "absolute",
                zIndex: 9999,
                backgroundColor: theme.palette.primary.light,
              }}
            >
              <GradientLoader />
            </Box>
          ) : null}

          {allItems.map((item) => (
            <React.Fragment key={item.key}>
              {["headline", "subHeadline", "disclaimer"].includes(item.key) ||
              item.key.startsWith("text") ? (
                <DraggableTextElement
                  resolution={resolution}
                  handleElementDragResize={handleElementDragResize}
                  adsData={adsData}
                  editable={editable}
                  textStyles={textStyles}
                  setTextStyles={setTextStyles}
                  setEditingResolution={setEditingResolution}
                  handleRightSidebarToggle={handleRightSidebarToggle}
                  dynamicTexts={dynamicTexts}
                  setDynamicTexts={setDynamicTexts}
                  updateTextDimensions={updateTextDimensions}
                  bannerWidth={bannerWidth}
                  bannerHeight={bannerHeight}
                  setResolution={setResolution}
                  values={values}
                  setValues={setValues}
                  editingResolution={editingResolution}
                  updateTextContent={updateTextContent}
                  verticalGuidelines={verticalGuidelines}
                  horizontalGuidelines={horizontalGuidelines}
                  isResizeMode={isResizeMode}
                  item={item.key}
                  editStates={editStates}
                />
              ) : item.key.startsWith("circle") ||
                item.key.startsWith("rectangle") ||
                item.key.startsWith("line") ? (
                <DraggableResizableShape
                  key={item.key}
                  handleElementDragResize={handleElementDragResize}
                  resolution={resolution}
                  setEditingResolution={setEditingResolution}
                  editable={editable}
                  values={values}
                  editingComponent={editingComponent}
                  verticalGuidelines={verticalGuidelines}
                  horizontalGuidelines={horizontalGuidelines}
                  item={item.key}
                />
              ) : (
                <DraggableResizableImage
                  item={item.key}
                  adsData={adsData}
                  resolution={resolution}
                  state={state}
                  handleElementDragResize={handleElementDragResize}
                  toCamelCase={toCamelCase}
                  setEditingResolution={setEditingResolution}
                  editable={editable}
                  setResolution={setResolution}
                  editingResolution={editingResolution}
                  logoDarkPercentages={logoDarkPercentages}
                  values={values}
                  verticalGuidelines={verticalGuidelines}
                  horizontalGuidelines={horizontalGuidelines}
                  status={status}
                />
              )}
            </React.Fragment>
          ))}

          {values?.partnership &&
            (selectedBrand?.name === "Amd" ? (
              <PartnershipBoxAmd values={values} resolution={resolution} />
            ) : (
              <PartnershipBox values={values} resolution={resolution} />
            ))}
        </Box>
      </Box>
    );
  }
);

export default BannerDynamic;
