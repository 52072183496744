import React, { useState, useRef, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { css, Global } from "@emotion/react";
import { Link, useParams } from "react-router-dom";
import StandardSelectDrawer from "./StandardSelectDrawer";
import BrandsTabs from "../pages/brands/components/BrandsTabs";
import LayoutsGrid from "../pages/layouts/components/LayoutsGrid";
import layoutImage from "../images/layout_4_loga_2.png";
import { Button, Tab, Tabs, Typography } from "@mui/material";
import AdsMessagesCard from "../pages/ads/AdsMessagesCard";
import ToggleButtonControl from "./ToggleButtonControl ";
import debounce from "lodash/debounce";
import ColorSettingComponent from "./ColorSettingComponent";
import GenerateAdPreviewLink from "../pages/ads/components/GenerateAdPreviewLink";

const RightSidebar = ({
  partnership,
  handlePartnershipClick,
  values,
  adsData,
  isDrawerOpen,
  toggle,
  onUpdate,
  handleEditorUpdate,
  setValues,
  editingResolution,
  existingAd,
  logoCornersConfig,
  setLogoCornersConfig,
  showUpdated,
  setShowImage,
  editStates,
  handleElementTransparencyUpdate,
  handleLockClick,
  setEditingResolution,
  activeTab,
  handleGeneratePreview,
  previewLink,
}) => {
  const editingComponent = useSelector((state) => state.editingComponent);
  const brandsList = useSelector((store) => store.brands);
  const layoutsList = useSelector((state) => state.layouts);
  const [initialLogoBrands, setInitialLogoBrands] = useState([]);

  const { id } = useParams();

  const [editorContent, setEditorContent] = useState("");

  const [brand, setBrand] = useState(null);

  const editingProduct = false;

  // Create a debounced function
  const debouncedSetEditorContent = debounce(
    (formattedValue, editingComponent) => {
      setEditorContent(formattedValue);

      handleEditorUpdate(formattedValue, editingComponent);
    },
    200
  );

  const quillRef = useRef(null);
  const [fontsCSS, setFontsCSS] = useState("");
  const [selectedFontSizes, setSelectedFontSizes] = useState("");
  const [typingContent, setTypingContent] = useState("");
  const [editorInstance, setEditorInstance] = useState(null);
  const [selectedTab, setSelectedTab] = useState(activeTab || 0);

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  // // Filter fonts based on existingAd.brand_id
  // const filteredFonts = fontsFromStore.filter(
  //   (font) => font.brandId === existingAd.brand_id
  // );

  useEffect(() => {
    const selectedBrand = brandsList?.find(
      (brand) => brand.id === existingAd.brand_id
    );
    setBrand(selectedBrand);
  }, [brandsList, id]);

  // useEffect(() => {
  //   const content =
  //     values.positions[editingComponent]?.[editingResolution]?.[
  //       editingComponent
  //     ];

  //   if (content) {
  //     setTypingContent(content);
  //   } else {
  //     // Handle the case when content is not found
  //     setTypingContent("");
  //   }
  // }, [editingComponent, editingResolution]);

  // useEffect(() => {
  //   if (filteredFonts && filteredFonts.length) {
  //     // Create a new style element
  //     const styleElement = document.createElement("style");

  //     let newFontsCSS = "";
  //     for (let font of filteredFonts) {
  //       let fontName = font.data.fileName.split(".")[0];
  //       newFontsCSS += `
  //               @font-face {
  //               font-family: "${fontName}";
  //               src: url("${ASSETS_URL}/${font.data.fileName}");
  //               }
  //               .font-${fontName} {
  //               font-family: "${fontName}", sans-serif;
  //               }
  //           `;
  //     }

  //     // Set style element inner text to be the font-face rule
  //     styleElement.innerText = newFontsCSS;

  //     // Append style element to head
  //     document.head.appendChild(styleElement);

  //     // Clean up on component unmount
  //     return () => {
  //       document.head.removeChild(styleElement);
  //     };
  //   }
  // }, [filteredFonts]);

  // const handleEditorChange = (event, editor) => {
  //   let data = editor.getData();
  //   // Remove wrapping <p> tags
  //   let formattedValue = data.replace(/^<p>|<\/p>$/g, "");
  //   debouncedSetEditorContent(formattedValue, editingComponent);
  // };

  // function stripHtmlTags(str) {
  //   if (str === null || str === "") return false;
  //   else str = str.toString();
  //   return str.replace(/<[^>]*>/g, "");
  // }

  // const handleSelectChange = (event, format) => {
  //   const selectedValue = event.target.value;
  //   setValues((prevState) => {
  //     let prevPositions = prevState.positions || {};
  //     let prevQuillElement = prevPositions[editingComponent] || {};
  //     let prevEditingResolution = prevQuillElement[editingResolution] || {};

  //     if (format === "font") {
  //       setSelectedFont(selectedValue);

  //       return {
  //         ...prevState,
  //         positions: {
  //           ...prevPositions,
  //           [editingComponent]: {
  //             ...prevQuillElement,
  //             [editingResolution]: {
  //               ...prevEditingResolution,
  //               fontName: selectedValue,
  //               [editingComponent]: stripHtmlTags(editorContent),
  //             },
  //           },
  //         },
  //       };
  //     }

  //     if (format === "size") {
  //       setSelectedFontSizes(selectedValue);

  //       return {
  //         ...prevState,
  //         positions: {
  //           ...prevPositions,
  //           [editingComponent]: {
  //             ...prevQuillElement,
  //             [editingResolution]: {
  //               ...prevEditingResolution,
  //               fontSize: selectedValue,
  //               [editingComponent]: stripHtmlTags(editorContent),
  //             },
  //           },
  //         },
  //       };
  //     }
  //   });
  // };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const rightSideBar = true;

  useEffect(() => {
    const newInitialLogoBrands = [
      values?.positions?.logoBrand1
        ? {
            key: "logoBrand1",
            value:
              values?.positions?.logoBrand1?.["1200x630"]?.selectedCorner ||
              "topLeft",
          }
        : null,
      values?.positions?.logoBrand2
        ? {
            key: "logoBrand2",
            value:
              values?.positions?.logoBrand2?.["1200x630"]?.selectedCorner ||
              "topRight",
          }
        : null,
      values?.positions?.logoBrand3
        ? {
            key: "logoBrand3",
            value:
              values?.positions?.logoBrand3?.["1200x630"]?.selectedCorner ||
              "bottomLeft",
          }
        : null,
      values?.positions?.logoThirdParty
        ? {
            key: "logoThirdParty",
            value:
              values?.positions?.logoThirdParty?.["1200x630"]?.selectedCorner ||
              "bottomRight",
          }
        : null,
    ].filter(Boolean); // Filter out null values

    const filteredLogoBrands = newInitialLogoBrands
      .filter(
        (logo) => typeof logo.value === "string" && logo.value.trim() !== ""
      )
      .map((logo) => logo.key);

    setInitialLogoBrands(filteredLogoBrands);
  }, [values]);
  return (
    <Box sx={{ maxWidth: 370 }}>
      <Global
        styles={css`
          ${fontsCSS}
        `}
      />
      <Box sx={{ position: "relative" }}>
        <Drawer
          anchor='right'
          open={isDrawerOpen}
          variant='persistent'
          PaperProps={{
            style: {
              top: "214px",
            },
          }}
        >
          <Box
            sx={{ p: 2, mt: -1, maxWidth: 350, width: "100%", mb: 23 }}
            role='presentation'
          >
            {/* <Box sx={{ position: "relative", top: 35, right: 25 }}>
              <IconButton onClick={() => setIsRightSidebarOpen(false)} sx={{}}>
                <CloseIcon
                  sx={{ fontSize: 20, mt: 1, mb: -1 }}
                  color='primary'
                />
              </IconButton>
            </Box> */}
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tab label='Edit' />
              <Tab label='Messages' />
            </Tabs>

            {selectedTab === 0 ? (
              <Box>
                <GenerateAdPreviewLink
                  previewLink={previewLink}
                  handleGeneratePreview={handleGeneratePreview}
                />
                <LayoutsGrid
                  existingAd={existingAd}
                  values={values}
                  setValues={setValues}
                  layoutsList={layoutsList}
                  layoutImage={layoutImage}
                />
                <StandardSelectDrawer
                  partnership={partnership}
                  handlePartnershipClick={handlePartnershipClick}
                  logoCornersConfig={logoCornersConfig}
                  setLogoCornersConfig={setLogoCornersConfig}
                  values={values}
                  adsData={adsData}
                  setValues={setValues}
                  initialLogoBrands={initialLogoBrands}
                  existingAd={existingAd}
                />

                <ToggleButtonControl
                  setValues={setValues}
                  onUpdate={onUpdate}
                  toggle={toggle}
                  existingAd={existingAd}
                  adsData={adsData}
                  showUpdated={showUpdated}
                  editingResolution={editingResolution}
                  setEditingResolution={setEditingResolution}
                  editStates={editStates}
                  values={values}
                  handleElementTransparencyUpdate={
                    handleElementTransparencyUpdate
                  }
                  handleLockClick={handleLockClick}
                />

                <ColorSettingComponent
                  setValues={setValues}
                  editingResolution={editingResolution}
                  editStates={editStates}
                  editingComponent={editingComponent}
                />

                <BrandsTabs
                  rightSideBar={rightSideBar}
                  existingAd={existingAd}
                  mdValue={6}
                  setValues={setValues}
                  initialLogoBrands={initialLogoBrands}
                  setShowImage={setShowImage}
                  editStates={editStates}
                  editingResolution={editingResolution}
                  values={values}
                />
              </Box>
            ) : (
              <Box sx={{ pt: 1 }}>
                <AdsMessagesCard existingAd={existingAd} />
              </Box>
            )}
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

export default RightSidebar;
