import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { toJpeg } from "html-to-image";
import axios from "axios";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { v4 as uuidv4 } from "uuid";
import { UPLOAD_MULTIPLE_URL } from "../../../config";
import { AuthContext } from "../../../context/authContext";
import PlatformSelection from "../campaign/social-media/PlatformSelection";
import SharePost from "../campaign/social-media/SharePost";
import CreateCampaignFromPost from "../campaign/social-media/CreateCampaignFromPost";
import CreateCampaign from "../campaign/social-media/CreateCampaign ";
import AdBannerPreview1200x630 from "../banners/AdBannerPreview1200x630";
import ActionSelection from "../campaign/social-media/ActionSelection ";
import CreateCampaignModal from "../campaign/CreateCampaignModal ";

const SocialNetworksShareModal = ({ open, onClose, data, onGenerate }) => {
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const [shareText, setShareText] = useState("");
  const [selectedResolution, setSelectedResolution] = useState("");
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [creativeId, setCreativeId] = useState(null);
  const [promotablePosts, setPromotablePosts] = useState([]);
  const [isFetchingPosts, setIsFetchingPosts] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState("");
  const [selectedPostImage, setSelectedPostImage] = useState("");
  const [selectedPostCaption, setSelectedPostCaption] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPostDate, setSelectedPostDate] = useState("");
  const [selectedPostDimensions, setSelectedPostDimensions] = useState("");
  const previewRef = useRef(null);
  const { width, height } = useWindowSize();
  const currentUser = useContext(AuthContext);

  const steps = [
    "Select Platform",
    "Choose Action",
    "Post Details",
    "Create Campaign",
  ];

  useEffect(() => {
    if (selectedResolution) {
      generateImage(selectedResolution);
    }
  }, [selectedResolution]);

  useEffect(() => {
    const fetchPromotablePosts = async () => {
      setIsFetchingPosts(true);
      try {
        const response = await axios.get("/api/facebook/promotable_posts", {
          params: { clientId: currentUser?.currentUser?.clientIdUsers },
        });
        setPromotablePosts(response.data.data || []);
      } catch (error) {
        console.error("Failed to fetch promotable posts:", error);
      } finally {
        setIsFetchingPosts(false);
      }
    };

    if (actionType === "campaign_from_post") {
      fetchPromotablePosts();
    }
  }, [actionType, currentUser]);

  useEffect(() => {
    if (actionType === "campaign" && selectedPostId) {
      setSelectedPostId("");
      setSelectedPostImage("");
      setSelectedPostCaption("");
    } else if (actionType === "campaign_from_post" && generatedImage) {
      setGeneratedImage(null);
      setSelectedResolution("");
    }
  }, [actionType]);

  console.log("Selected post from image in Social", selectedPostImage);

  const handlePlatformClick = (platform) => {
    setSelectedPlatform((prevSelectedPlatform) => {
      if (prevSelectedPlatform.includes(platform)) {
        return prevSelectedPlatform.filter((p) => p !== platform);
      } else {
        return [...prevSelectedPlatform, platform];
      }
    });
  };

  const handleResolutionChange = (event) => {
    setSelectedResolution(event.target.value);
  };

  const handleShare = async () => {
    if (generatedImage) {
      setIsPublishing(true);
      try {
        const imagePath = await uploadImage(generatedImage);
        await publishToPlatform(
          selectedPlatform,
          imagePath,
          shareText,
          currentUser?.currentUser?.clientIdUsers
        );
        setShowConfetti(true);
        setShowSuccessMessage(true);
        setTimeout(() => setShowConfetti(false), 5000);
      } catch (error) {
        console.error("Failed to share:", error);
      } finally {
        setIsPublishing(false);
      }
    }
  };

  const handleCreateCampaign = async () => {
    let imageUrl = "";
    if (actionType === "campaign" && generatedImage) {
      imageUrl = await uploadImage(generatedImage);
    }
    setSelectedPostImage(imageUrl);
    setCurrentStep(3);
  };

  const handleCreateCampaignFromPost = async () => {
    console.log(
      "Selected post image for campaign from post:",
      selectedPostImage
    );
    setCurrentStep(3);
  };

  const handlePostSelection = (post) => {
    const imageUrl = post.attachments?.data[0]?.media?.image?.src;
    const imageDimensions = post.attachments?.data[0]?.media?.image;

    console.log("Selected post details:", {
      id: post.id,
      imageUrl,
      caption: post.message,
      date: new Date(post.created_time).toLocaleString(),
      dimensions: imageDimensions,
    });

    setSelectedPostId(post.id);
    setSelectedPostImage(imageUrl || ""); // Ensure it's set to an empty string if undefined
    setSelectedPostCaption(post.message || ""); // Ensure it's set to an empty string if undefined
    setSelectedPostDate(new Date(post.created_time).toLocaleString());
    setSelectedPostDimensions(imageDimensions || {});
  };

  // const uploadImageToFacebook = async (imagePath, clientId) => {
  //   try {
  //     const response = await axios.post("/api/facebook/upload_image", {
  //       imagePath,
  //       clientId,
  //     });
  //     return response.data.creativeId;
  //   } catch (error) {
  //     console.error("Failed to upload image to Facebook:", error);
  //     throw error;
  //   }
  // };

  const publishToPlatform = async (platforms, imagePath, caption, clientId) => {
    const platformUrls = {
      Instagram: "/api/instagram/publish",
      Facebook: "/api/facebook/publish",
      LinkedIn: "/api/linkedin/publish",
    };
    try {
      for (const platform of platforms) {
        console.log(`Publishing to ${platform} with clientId:`, clientId);
        await axios.post(platformUrls[platform], {
          imagePath,
          caption,
          clientId,
        });
        console.log(`Successfully published to ${platform}`);
      }
    } catch (error) {
      console.error(`Failed to publish to platform:`, error);
      throw error;
    }
  };

  const generateImage = useCallback(async (resolution) => {
    setIsGenerating(true);
    setGeneratedImage(null);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const dataUrl = await toJpeg(previewRef.current, { quality: 0.95 });
      setGeneratedImage(dataUrl);
      console.log(`Generated Image Data URL: ${dataUrl}`);
    } catch (error) {
      console.error("Failed to generate image:", error);
    }
    setIsGenerating(false);
  }, []);

  const uploadImage = useCallback(
    async (imageDataUrl) => {
      try {
        const formData = new FormData();
        formData.append("brand", data?.brand);
        // Use only the first type from the selectedPlatform array
        formData.append("type", selectedPlatform[0]);
        formData.append("clientId", currentUser?.currentUser?.clientIdUsers);
        const uniqueFilename = `generated_image_${uuidv4()}.jpg`;
        formData.append("files", dataURLtoBlob(imageDataUrl), uniqueFilename);
        const response = await axios.post(UPLOAD_MULTIPLE_URL, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(`Upload progress: ${progress}%`);
          },
        });
        console.log("Response from server:", response.data);
        return response.data[0].fileName;
      } catch (error) {
        console.error("Failed to upload image:", error);
        throw error;
      }
    },
    [selectedPlatform, currentUser]
  );

  const dataURLtoBlob = (dataUrl) => {
    const [metadata, base64] = dataUrl.split(",");
    const mime = metadata.match(/:(.*?);/)[1];
    const byteString = atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mime });
  };

  const handleCancel = () => {
    setCurrentStep(0);
    setActionType(null);
    onClose();
  };

  const goBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const goNext = () => {
    if (currentStep === 0) {
      handleConfirmPlatforms();
    } else if (currentStep === 2 && actionType === "campaign_from_post") {
      handleCreateCampaignFromPost();
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleConfirmPlatforms = () => {
    setCurrentStep(1);
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth='md' fullWidth={true}>
      {showConfetti && <Confetti width={width} height={height} />}
      <DialogTitle>Share Ad</DialogTitle>
      <Stepper activeStep={currentStep} sx={{ p: 4 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <DialogContent>
        {currentStep === 0 && (
          <PlatformSelection
            selectedPlatform={selectedPlatform}
            handlePlatformClick={handlePlatformClick}
          />
        )}
        {currentStep === 1 && (
          <ActionSelection
            selectedPlatform={selectedPlatform}
            setActionType={(type) => {
              setActionType(type);
              goNext();
            }}
          />
        )}
        {currentStep === 2 && actionType === "post" && (
          <SharePost
            data={data}
            selectedResolution={selectedResolution}
            handleResolutionChange={handleResolutionChange}
            shareText={shareText}
            setShareText={setShareText}
            isGenerating={isGenerating}
            generatedImage={generatedImage}
            isPublishing={isPublishing}
          />
        )}
        {currentStep === 2 &&
          actionType === "campaign_from_post" &&
          (isFetchingPosts ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <CreateCampaignFromPost
              promotablePosts={promotablePosts}
              handlePostSelection={handlePostSelection}
              selectedPostId={selectedPostId}
              selectedPostCaption={selectedPostCaption}
              selectedPostDate={selectedPostDate}
              isGenerating={isGenerating}
              generatedImage={generatedImage}
              isPublishing={isPublishing}
            />
          ))}
        {currentStep === 2 && actionType === "campaign" && (
          <CreateCampaign
            data={data}
            selectedResolution={selectedResolution}
            handleResolutionChange={handleResolutionChange}
            isGenerating={isGenerating}
            generatedImage={generatedImage}
            isPublishing={isPublishing}
          />
        )}
        {currentStep === 3 && (
          <CreateCampaignModal
            open={true}
            onClose={handleCancel}
            creativeId={creativeId}
            clientId={currentUser?.currentUser?.clientIdUsers}
            selectedPostId={selectedPostId}
            selectedPostImage={selectedPostImage}
            selectedPostCaption={selectedPostCaption}
            selectedPostDate={selectedPostDate}
            selectedPostDimensions={selectedPostDimensions}
            selectedPlatform={selectedPlatform}
            generatedImage={generatedImage}
          />
        )}
      </DialogContent>
      {!showSuccessMessage && (
        <DialogActions>
          {currentStep > 0 && (
            <Button onClick={goBack} color='secondary'>
              Back
            </Button>
          )}
          <Button onClick={handleCancel} color='secondary'>
            Cancel
          </Button>
          <Button
            onClick={actionType === "post" ? handleShare : goNext}
            disabled={
              (currentStep === 0 && selectedPlatform.length === 0) ||
              (currentStep === 2 &&
                actionType !== "campaign_from_post" &&
                !selectedResolution) ||
              isPublishing ||
              (currentStep === 2 &&
                actionType === "campaign_from_post" &&
                !selectedPostId)
            }
            variant='contained'
            color='primary'
          >
            {isPublishing ? (
              <CircularProgress size={24} />
            ) : currentStep === 2 && actionType === "campaign_from_post" ? (
              "Next"
            ) : currentStep === 3 ? (
              "Create Campaign"
            ) : (
              "Next"
            )}
          </Button>
        </DialogActions>
      )}
      <Box sx={{ position: "absolute", top: -9999, left: -9999 }}>
        <AdBannerPreview1200x630
          ref={previewRef}
          data={data}
          resolution={selectedResolution}
        />
      </Box>
      <Snackbar
        open={showSuccessMessage}
        onClose={() => {
          setShowSuccessMessage(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
      >
        <Alert
          icon={<CheckCircleIcon fontSize='inherit' sx={{ color: "white" }} />}
          action={
            <Button
              color='inherit'
              size='small'
              onClick={() => {
                setShowSuccessMessage(false);
              }}
            >
              Ok
            </Button>
          }
          severity='success'
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          Congratulations! You just{" "}
          {actionType === "post" ? "posted to" : "created a campaign on"}{" "}
          {selectedPlatform.join(", ")}!
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default SocialNetworksShareModal;
