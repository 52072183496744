import { useEffect, useRef } from "react";
import { DataSet } from "vis-data";
import { Timeline } from "vis-timeline/standalone";
import { getTimelineItems, createGroups } from "../utils/helpers";

// Color palette for parent items
const colors = ["#A06EAF", "#6EAFA0", "#AF6E6E"];

// Fixed color for child items
const childColorIn = "#6EAFA0"; // Example color for "IN"
const childColorOut = "#6EAFA0"; // Example color for "OUT"

const useTimeline = (
  containerRef,
  values,
  selectedResolution,
  timelineLength,
  setValues,
  setCurrentTime // Added setCurrentTime as a parameter
) => {
  const timelineRef = useRef(null);
  const previousValuesRef = useRef(values);

  useEffect(() => {
    if (containerRef.current) {
      // Get timeline items and groups
      const rawItems = getTimelineItems(values?.positions, selectedResolution);
      const rawGroups = createGroups(values?.positions, selectedResolution);

      // Add child items under parent items
      const childItems = rawItems.flatMap((item) => {
        const start = new Date(item.start);
        const end = new Date(item.end);
        const durationIn = (end.getTime() - start.getTime()) / 3;
        const durationOut = (end.getTime() - start.getTime()) / 3;

        const childIn = {
          id: `${item.id}-child-in`,
          content: "IN",
          start: start,
          end: new Date(start.getTime() + durationIn), // 1/3 duration
          group: item.group,
          className: "child-item",
          editable: {
            remove: false, // Disable removal of child items
            updateTime: true,
            updateGroup: false, // Prevent dragging to another row
          },
          style: `background-color: ${childColorIn}88; font-size: 0.7em; height: ${
            item.style.match(/height: ([^;]+)/)[1]
          }; border-radius: 4px; position: absolute; z-index: 10; line-height: ${
            item.style.match(/height: ([^;]+)/)[1]
          }; text-align: left; padding-left: 5px;`, // Ensure child item is on top and text is at the beginning
        };

        const childOut = {
          id: `${item.id}-child-out`,
          content: "OUT",
          start: new Date(end.getTime() - durationOut), // 1/3 duration from the end
          end: end,
          group: item.group,
          className: "child-item",
          editable: {
            remove: false, // Disable removal of child items
            updateTime: true,
            updateGroup: false, // Prevent dragging to another row
          },
          style: `background-color: ${childColorOut}88; font-size: 0.7em; height: ${
            item.style.match(/height: ([^;]+)/)[1]
          }; border-radius: 4px; position: absolute; z-index: 10; line-height: ${
            item.style.match(/height: ([^;]+)/)[1]
          }; text-align: right; padding-right: 5px;`, // Ensure child item is on top and text is at the end
        };

        return [childIn, childOut];
      });

      const parentItems = rawItems.map((item) => ({
        ...item,
        content: "", // Remove content from parent items
      }));

      const length = timelineLength || 30000; // Default to 30 seconds if not set
      const options = {
        showCurrentTime: false,
        start: new Date(0),
        end: new Date(length),
        min: new Date(0),
        max: new Date(length),
        zoomMin: 1000,
        zoomMax: length,
        format: {
          minorLabels: {
            second: "ss",
            minute: "mm:ss",
          },
          majorLabels: {
            second: "mm:ss",
            minute: "mm:ss",
          },
        },
        editable: {
          add: true,
          updateTime: true,
          updateGroup: false, // Prevent dragging to another row
          remove: true,
        },
        stack: false,
        horizontalScroll: true,
        verticalScroll: true,
        zoomable: true,
        orientation: {
          axis: "both",
        },
        groupOrder: (a, b) => a.id - b.id,
        multiselect: true,
      };

      if (timelineRef.current) {
        timelineRef.current.destroy();
      }

      timelineRef.current = new Timeline(
        containerRef.current,
        new DataSet([...parentItems, ...childItems]),
        new DataSet(rawGroups),
        options
      );

      // Add custom time markers
      timelineRef.current.addCustomTime(new Date(0), "currentTime");

      // Attach event listener for item updates

      const handleItemUpdate = (properties) => {
        const updatedItems = timelineRef.current.itemsData.get();

        const newValues = { ...values };

        updatedItems.forEach((item) => {
          if (
            !item.id.endsWith("-child-in") &&
            !item.id.endsWith("-child-out") &&
            newValues.positions[item.id]
          ) {
            if (!newValues.positions[item.id][selectedResolution]) {
              newValues.positions[item.id][selectedResolution] = {};
            }
            if (!newValues.positions[item.id][selectedResolution].animation) {
              newValues.positions[item.id][selectedResolution].animation = {};
            }
            newValues.positions[item.id][selectedResolution].animation.start =
              item.start.toISOString();
            newValues.positions[item.id][selectedResolution].animation.end =
              item.end.toISOString();

            // Update child item positions and heights to move with the parent
            const childItemInId = `${item.id}-child-in`;
            const childItemIn =
              timelineRef.current.itemsData.get(childItemInId);
            if (childItemIn) {
              const durationIn =
                childItemIn.end.getTime() - childItemIn.start.getTime();
              if (childItemIn.start.getTime() !== item.start.getTime()) {
                childItemIn.start = item.start;
                childItemIn.end = new Date(item.start.getTime() + durationIn);
                childItemIn.style = `background-color: ${childColorIn}88; font-size: 0.7em; height: ${
                  item.style.match(/height: ([^;]+)/)[1]
                }; border-radius: 4px; position: absolute; z-index: 10; line-height: ${
                  item.style.match(/height: ([^;]+)/)[1]
                }; text-align: left; padding-left: 5px;`;
                timelineRef.current.itemsData.update(childItemIn);
              }
            }

            const childItemOutId = `${item.id}-child-out`;
            const childItemOut =
              timelineRef.current.itemsData.get(childItemOutId);
            if (childItemOut) {
              const durationOut =
                childItemOut.end.getTime() - childItemOut.start.getTime();
              if (childItemOut.end.getTime() !== item.end.getTime()) {
                childItemOut.start = new Date(item.end.getTime() - durationOut);
                childItemOut.end = item.end;
                childItemOut.style = `background-color: ${childColorOut}88; font-size: 0.7em; height: ${
                  item.style.match(/height: ([^;]+)/)[1]
                }; border-radius: 4px; position: absolute; z-index: 10; line-height: ${
                  item.style.match(/height: ([^;]+)/)[1]
                }; text-align: right; padding-right: 5px;`;
                timelineRef.current.itemsData.update(childItemOut);
              }
            }
          }

          // Update the inAnimation or outAnimation when child items are updated only if they exist
          if (item.id.endsWith("-child-in")) {
            const parentItemId = item.id.replace("-child-in", "");
            if (
              newValues.positions[parentItemId][selectedResolution].animation
                ?.inAnimation
            ) {
              newValues.positions[parentItemId][
                selectedResolution
              ].animation.inAnimation.start = item.start.toISOString();
              newValues.positions[parentItemId][
                selectedResolution
              ].animation.inAnimation.end = item.end.toISOString();
              newValues.positions[parentItemId][
                selectedResolution
              ].animation.inAnimation.duration =
                (item.end.getTime() - item.start.getTime()) / 1000;
            }
          } else if (item.id.endsWith("-child-out")) {
            const parentItemId = item.id.replace("-child-out", "");
            if (
              newValues.positions[parentItemId][selectedResolution].animation
                ?.outAnimation
            ) {
              newValues.positions[parentItemId][
                selectedResolution
              ].animation.outAnimation.start = item.start.toISOString();
              newValues.positions[parentItemId][
                selectedResolution
              ].animation.outAnimation.end = item.end.toISOString();
              newValues.positions[parentItemId][
                selectedResolution
              ].animation.outAnimation.duration =
                (item.end.getTime() - item.start.getTime()) / 1000;
            }
          }
        });

        // Compare with previous values to avoid unnecessary setValues calls
        if (
          JSON.stringify(newValues) !==
          JSON.stringify(previousValuesRef.current)
        ) {
          previousValuesRef.current = newValues;
          setValues(newValues);
        }
      };

      const handleTimeChange = (properties) => {
        const currentTime = timelineRef.current.getCustomTime("currentTime");

        setCurrentTime(currentTime); // Update the current time state

        // Check visibility of items at the current time
        const visibleItems = rawItems.filter((item) => {
          const start = new Date(item.start).getTime();
          const end = new Date(item.end).getTime();
          return currentTime >= start && currentTime <= end;
        });
      };

      timelineRef.current.on("changed", () => {
        handleItemUpdate();
      });

      timelineRef.current.on("select", (properties) => {});

      timelineRef.current.on("update", (properties) => {
        // Disallow resizing the start of child items
        properties.items.forEach((itemId) => {
          const item = timelineRef.current.itemsData.get(itemId);
          if (
            item &&
            (item.id.endsWith("-child-in") || item.id.endsWith("-child-out"))
          ) {
            const parentItemId = item.id.replace(/-child-(in|out)/, "");
            const parentItem = timelineRef.current.itemsData.get(parentItemId);
            if (parentItem && properties.start) {
              if (item.id.endsWith("-child-in")) {
                item.start = parentItem.start;
              } else if (item.id.endsWith("-child-out")) {
                item.end = parentItem.end;
              }
              timelineRef.current.itemsData.update(item);
            }
          }
        });

        handleItemUpdate(properties);
      });

      timelineRef.current.on("remove", (properties) => {
        handleItemUpdate(properties);
      });

      timelineRef.current.on("timechange", handleTimeChange); // Attach timechange event listener

      return () => {
        if (timelineRef.current) {
          timelineRef.current.destroy();
          timelineRef.current = null;
        }
      };
    }
  }, [
    containerRef,
    // values,
    selectedResolution,
    timelineLength,
    setValues,
    setCurrentTime,
  ]);

  return timelineRef;
};

export default useTimeline;
