import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Select,
  MenuItem,
  Divider,
  TextField,
  Chip,
  Dialog,
  DialogContent,
  Avatar,
  Button,
} from "@mui/material";
import { getUserData } from "../helpers/dataForModal";
import { AuthContext } from "../context/authContext";
import { useSelector } from "react-redux";

const UserModalComponent = ({
  userTitle,
  values,
  setValues,
  clients,
  onSelect,
  handleAddEntity,
  handleEditUser,
  open,
  setOpen,
  backgroundColor,
  handleInputChange,
  formChanged,
  setFormChanged,
  type,
  isModal = true, // New prop with default value true
}) => {
  const theme = useTheme();
  const userData = getUserData(values);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const currentUser = useContext(AuthContext);
  const clientsList = useSelector((store) => store.clients);

  useEffect(() => {
    if (isModal && open) {
      if (userTitle === "Edit User") {
        const selectedClient = clients.find(
          (client) => client.id === values.clientId
        );
        console.log("Editing user - initial values:", values);
        setValues((prev) => ({
          ...prev,
          company: selectedClient ? selectedClient.name : "",
          password: values.password || "",
          createdAt: values.createdAt || "",
          updatedAt: values.updatedAt || "",
          data: {
            ...prev.data,
            countries: values.data?.countries || [],
          },
        }));
        setSelectedCountries(values.data?.countries || []);
        setAvailableCountries(selectedClient?.data?.countries || []);
      } else if (userTitle === "Add User") {
        const currentClient = clientsList.find(
          (client) => client.id === currentUser.clientIdUsers
        );
        console.log("Adding user - initial client:", currentClient);
        setSelectedCountries([]);
        setAvailableCountries(currentClient?.data?.countries || []);
        setValues({
          id: "",
          firstName: "",
          lastName: "",
          username: "",
          password: "",
          email: "",
          company: currentClient ? currentClient.name : "",
          role: "",
          data: {
            countries: [],
          },
        });
        console.log("Adding user - initial values set:", {
          id: "",
          firstName: "",
          lastName: "",
          username: "",
          password: "",
          email: "",
          company: currentClient ? currentClient.name : "",
          role: "",
          data: {
            countries: [],
          },
        });
      }
    } else if (!isModal) {
      // When rendering as a normal component (User Profile)
      if (userTitle === "User Profile") {
        const selectedClient = clients.find(
          (client) => client.id === values.clientIdUsers
        );
        setValues((prev) => ({
          ...prev,
          company: selectedClient ? selectedClient.name : "",
          data: {
            ...prev.data,
            countries: values.data?.countries || [],
          },
        }));
        setSelectedCountries(values.data?.countries || []);
        setAvailableCountries(selectedClient?.data?.countries || []);
      }
    }
  }, [open, isModal]);

  const handleClose = () => {
    setOpen && setOpen(false);
  };

  const handleChangeRole = (event) => {
    setValues((prev) => ({ ...prev, role: event.target.value }));
    setFormChanged(true);
  };

  const handleChangeCompany = (event) => {
    const selectedClient = clients.find((c) => c.name === event.target.value);
    setValues((prev) => ({ ...prev, company: selectedClient.name }));
    onSelect && onSelect(selectedClient.id);
    setFormChanged(true);
    setAvailableCountries(selectedClient?.data?.countries || []);
  };

  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("Country selected from dropdown:", value);
    const newSelectedCountries =
      typeof value === "string" ? value.split(",") : value;

    setSelectedCountries(newSelectedCountries);
    setValues((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        countries: newSelectedCountries,
      },
    }));
    setFormChanged(true);
    console.log("New selected countries:", newSelectedCountries);
  };

  const handleDeleteCountry = (countryToDelete) => {
    console.log("Country to delete:", countryToDelete);
    const newSelectedCountries = selectedCountries
      .flat()
      .filter((country) => country !== countryToDelete);
    setSelectedCountries(newSelectedCountries);
    setValues((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        countries: newSelectedCountries,
      },
    }));
    setFormChanged(true);
    console.log("Countries after deletion:", newSelectedCountries);
  };

  const handleToggleCountry = (country) => {
    console.log("Country to toggle:", country);
    if (selectedCountries.flat().includes(country)) {
      handleDeleteCountry(country);
    } else {
      const newSelectedCountries = [...selectedCountries.flat(), country];
      setSelectedCountries(newSelectedCountries);
      setValues((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          countries: newSelectedCountries,
        },
      }));
      setFormChanged(true);
      console.log("Countries after adding:", newSelectedCountries);
    }
  };

  const sectionStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 64,
    borderBottom: "1px solid #D9D9D9",
  };

  const textStyle = {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.typography.fontColor,
  };

  const inputStyle = {
    width: "50%",
    color: theme.typography.fontColor,
  };

  // The content of the form
  const formContent = (
    <Card sx={{ px: 4, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)" }}>
      <CardContent>
        <Box
          sx={{
            ...sectionStyle,
            fontSize: 18,
            fontWeight: "bold",
            color: theme.typography.fontColorHeadline,
            pt: 3.9,
          }}
        >
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: "bold",
              color: theme.typography.fontColorHeadline,
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: -3,
                height: "2px",
                backgroundImage: backgroundColor,
              },
            }}
          >
            {userTitle}
          </Typography>
          <Divider orientation='horizontal' flexItem />
        </Box>
        {userData?.map((data, index) => (
          <React.Fragment key={index}>
            <Box sx={sectionStyle}>
              <Typography sx={textStyle}>{data.label}</Typography>
              <TextField
                sx={inputStyle}
                variant='standard'
                value={data.value}
                onChange={(e) => handleInputChange(e, data.property)}
                disabled={data.label === "ID"}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: theme.typography.fontColor,
                    fontWeight: "normal",
                  },
                }}
              />
            </Box>
            {data.label === "ID" && (
              <Box sx={sectionStyle}>
                <Typography sx={textStyle}>Choose Role</Typography>
                <Select
                  key={values?.role}
                  value={values?.role}
                  onChange={handleChangeRole}
                  sx={{
                    ...inputStyle,
                    color: theme.typography.fontColor,
                    fontWeight: "normal",
                  }}
                >
                  <MenuItem value='admin'>Admin</MenuItem>
                  <MenuItem value='manager'>Manager</MenuItem>
                  <MenuItem value='creator'>Creator</MenuItem>
                </Select>
              </Box>
            )}
          </React.Fragment>
        ))}
        <Box sx={sectionStyle}>
          <Typography sx={textStyle}>Company</Typography>
          <Select
            value={values?.company}
            onChange={handleChangeCompany}
            sx={{
              ...inputStyle,
              color: theme.typography.fontColor,
              fontWeight: "normal",
            }}
          >
            {clients?.map((client) => (
              <MenuItem key={client.id} value={client.name}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={sectionStyle}>
          <Typography sx={textStyle}>Countries</Typography>
          <Select
            multiple
            value={selectedCountries.flat()}
            onChange={handleCountryChange}
            sx={{
              ...inputStyle,
              color: theme.typography.fontColor,
              fontWeight: "normal",
            }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    onDelete={() => handleDeleteCountry(value)}
                  />
                ))}
              </Box>
            )}
          >
            {availableCountries.map((country) => (
              <MenuItem
                key={country}
                value={country}
                onClick={() => handleToggleCountry(country)}
              >
                {country}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={sectionStyle}>
          <Typography sx={textStyle}>Profile Photo</Typography>
          <Avatar sx={{ width: 36, height: 36 }} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
          <Button
            disabled={!formChanged}
            variant='contained'
            color='primary'
            size='small'
            style={{ color: theme.palette.primary.light }}
            onClick={() => {
              if (userTitle === "Edit User") {
                handleEditUser();
              } else {
                handleAddEntity();
              }
            }}
          >
            {userTitle === "Edit User" ? "UPDATE" : "ADD USER"}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );

  // Conditionally render as modal or normal component
  if (isModal) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { minWidth: "700px" }, p: 0 }}
      >
        <DialogContent sx={{ p: 0 }}>{formContent}</DialogContent>
      </Dialog>
    );
  } else {
    // Render as a normal component
    return <Box sx={{ p: 2 }}>{formContent}</Box>;
  }
};

export default UserModalComponent;
