import { ASSETS_URL } from "../../../../../config";

export const ConditionalImage = ({
  style,
  defaultSrc,
  altSrc,
  isHovered,
  setIsHovered,
}) => {
  let timeoutId;

  // const handleMouseEnter = () => {
  //   clearTimeout(timeoutId); // Clear the timeout if user re-enters before timeout is up
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   timeoutId = setTimeout(() => {
  //     setIsHovered(false);
  //   }, 500); // 500ms delay
  // };

  return (
    <img
      style={{
        ...style,
        border: isHovered ? "1px solid #a06eaf" : "none", // Conditional border
      }}
      alt=''
      src={altSrc ? altSrc : `${ASSETS_URL}/${defaultSrc}`}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
    />
  );
};
