import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconButton, Grid } from "@mui/material";
import { AuthContext } from "../../context/authContext";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog2, {
  confirmDialog,
} from "../../components/ConfirmationDialog";
import { ACTIONS_URL, ASSETS_URL, BRANDS_URL, USERS_URL } from "../../config";
import { formatDateToLocaleString } from "../../helpers/dateHelper";
import { deleteOneBrand, updateBrands } from "../brands/features/brandsReducer";
import BrandsTable from "../../components/Table";
import AdReadyHelp from "../../components/AdReadyHelp";
import UserCard from "../../components/UserCard";
import axiosInstance from "../../services/axiosInstance";
import { SocketContext } from "../../context/SocketContext ";
import { useTheme } from "@mui/material/styles";
import AnimationPreview from "../ads/animation/components/AnimationPreview";

export default function Brands(props) {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  // const { isLoading, getBrands } = useAds();
  const BrandsList = useSelector((store) => store.brands);
  const [brandState, setBrandState] = useState(BrandsList);

  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    const transformedBrands = BrandsList.map((brand) => {
      return {
        id: brand.id,
        name: brand.name,
        brandLogo: brand.brandLogo,
        description: brand.description,
        data: {
          industry: brand.data?.industry || "", // Include industry from data field
        },
        created: formatDateToLocaleString(brand.createdAt),
        updated: formatDateToLocaleString(brand.updatedAt),
        status: brand.status,
      };
    });

    // Now set the state
    setBrandState(transformedBrands);
  }, [BrandsList]);

  const actionsDetailButton = (params) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <IconButton onClick={() => handleDelete(params)} aria-label='delete'>
          <DeleteIcon color='primary' sx={{ fontSize: 20 }} />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate(`/brand/${params.id}`, {
              state: { userData: params.row },
              socket,
            });
          }}
        >
          <EditIcon color='primary' sx={{ fontSize: 20 }} aria-label='edit' />
        </IconButton>
      </Box>
    );
  };

  // DELETE AD FUNCTION

  const handleDelete = async (row) => {
    const id = +row.id;
    const name = row.name;

    confirmDialog(
      `Do you really want to delete "${name}" BRAND, created at ${row.created} ?`,
      async () => {
        try {
          // Log the "Delete" action for objectType "Brand"
          await axiosInstance.post(ACTIONS_URL, {
            actionType: "Delete",
            objectType: "Brand",
            userId: currentUser.id,
            clientId: currentUser.clientIdUsers,
            content: row,
          });

          // Perform the delete action after the post action
          dispatch(deleteOneBrand({ id: id }));
          await axiosInstance.delete(`${BRANDS_URL}/${id}`);
        } catch (err) {
          console.log(err);
        }
      }
    );
  };

  const cardColors = {
    item1: theme.palette.primary.itemColor,
    item2: theme.typography.fontColor,
    item3: theme.typography.fontColor,
  };

  const backgroundColor = "linear-gradient(45deg, #E9B8BF 30%, #A06EAF 90%)";

  return (
    <Grid sx={{ pl: 10, pr: 2, pt: 2 }} container spacing={2}>
      <ConfirmDialog2 />

      <Grid item xs={12} md={7}>
        <UserCard
          rows={brandState}
          cardColors={cardColors}
          currentUser={currentUser}
          numberOfItems={brandState.length}
          cardHeader={"Brands"}
          backgroundColor={backgroundColor}
          pendingAds={2}
        />
      </Grid>

      <Grid item xs={12} md={5}>
        <AdReadyHelp backgroundColor={backgroundColor} />
      </Grid>

      <Grid item xs={12}>
        <BrandsTable
          title='Brands'
          rows={brandState}
          name={brandState.name}
          actionsDetailButton={actionsDetailButton}
        />
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
}
