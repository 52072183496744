import React, { useRef, useState, useEffect } from "react";
import { Box, Button, Card } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import useTimeline from "../hooks/useTimeline";
import usePlayControl from "../hooks/usePlayControl";
import EffectsSidebar from "./EffectsSidebar";
import AdAnimationPreview from "./AdAnimationPreview";
import ControlToolbar from "./ControlToolbar";
import EffectsGrid from "./EffectsGrid";

const AnimationPreview = ({ values, onClose, setValues }) => {
  // console.log("Received setValues in AdAnimationPreview:", setValues);

  const containerRef = useRef(null);
  const [selectedResolution, setSelectedResolution] = useState("1200x630");
  const [timelineLength, setTimelineLength] = useState(30000); // Default to 30 seconds
  const [effectsOpen, setEffectsOpen] = useState(false); // State for opening effects modal
  const [effectType, setEffectType] = useState(""); // State for effect type
  const [selectedParent, setSelectedParent] = useState(null); // State for selected parent item

  console.log("animatiooon!", values);

  const resolutions = values?.positions?.resolutions || [];
  const timelineRef = useTimeline(
    containerRef,
    values,
    selectedResolution,
    timelineLength,
    setValues, // Pass setValues to the hook
    setSelectedParent // Pass setSelectedParent to the hook
  );
  const {
    isPlaying,
    currentTime,
    handlePlay,
    handlePause,
    handleStop,
    handleRewind,
  } = usePlayControl(timelineRef, timelineLength);

  useEffect(() => {
    if (timelineRef.current) {
      const handleDoubleClick = (properties) => {
        const selectedItems = properties.item ? [properties.item] : [];
        if (selectedItems.length > 0) {
          const itemId = selectedItems[0];
          const item = timelineRef.current.itemsData.get(itemId);
          if (item) {
            const isChildIn = item.id.endsWith("-child-in");
            const isChildOut = item.id.endsWith("-child-out");
            if (isChildIn || isChildOut) {
              setEffectType(isChildIn ? "IN" : "OUT");
              setEffectsOpen(true);
            }
          }
        }
      };

      const handleSelect = (properties) => {
        const selectedItems = properties.items;
        if (selectedItems.length > 0) {
          const itemId = selectedItems[0];
          const item = timelineRef.current.itemsData.get(itemId);
          if (
            item &&
            !item.id.endsWith("-child-in") &&
            !item.id.endsWith("-child-out")
          ) {
            setSelectedParent(item);
          }
        } else {
          setSelectedParent(null);
        }
      };

      timelineRef.current.on("doubleClick", handleDoubleClick);
      timelineRef.current.on("select", handleSelect);

      // Cleanup function to remove event listeners
      return () => {
        if (timelineRef.current) {
          timelineRef.current.off("doubleClick", handleDoubleClick);
          timelineRef.current.off("select", handleSelect);
        }
      };
    }
  }, [timelineRef, selectedResolution]); // Adding selectedResolution as a dependency

  const handleResolutionChange = (event) => {
    setSelectedResolution(event.target.value);

    // Log the selected resolution and the current values for debugging
    // console.log(`Selected resolution changed to: ${event.target.value}`);
    // console.log(`Current values:`, values);

    // Ensure that the animation object exists for the new resolution
    const parentId = selectedParent?.id.replace(/-child-(in|out)/, "");
    if (
      parentId &&
      values.positions[parentId] &&
      !values.positions[parentId][event.target.value]
    ) {
      values.positions[parentId][event.target.value] = { animation: {} };
    }
  };

  const applyEffect = (effect, direction, animationType) => {
    console.log(
      `Applying effect: ${effect}, direction: ${direction}, type: ${animationType}`
    );
    const selectedItems = timelineRef.current.getSelection();
    if (selectedItems.length > 0) {
      const itemId = selectedItems[0];
      const item = timelineRef.current.itemsData.get(itemId);
      if (item) {
        const newValues = { ...values };
        const parentId = item.id.replace(/-child-(in|out)/, "");
        const isChildIn = item.id.endsWith("-child-in");
        const isChildOut = item.id.endsWith("-child-out");

        if (!newValues.positions[parentId][selectedResolution]) {
          newValues.positions[parentId][selectedResolution] = {};
        }
        if (!newValues.positions[parentId][selectedResolution].animation) {
          newValues.positions[parentId][selectedResolution].animation = {};
        }

        const animationObject = {
          effectName: effect,
          start: new Date(item.start).toISOString(),
          end: new Date(item.end).toISOString(),
          duration: (item.end.getTime() - item.start.getTime()) / 1000,
          type: animationType,
          damping: animationType === "spring" ? 10 : undefined,
          stiffness: animationType === "spring" ? 80 : undefined,
          ...(direction && { direction: direction.toLowerCase() }),
        };

        if (effect === "Fade In" && isChildIn) {
          // No style update here
          newValues.positions[parentId][
            selectedResolution
          ].animation.inAnimation = animationObject;
        } else if (effect === "Fade Out" && isChildOut) {
          // No style update here
          newValues.positions[parentId][
            selectedResolution
          ].animation.outAnimation = animationObject;
        } else if (effect === "Zoom In" && isChildIn) {
          // No style update here
          newValues.positions[parentId][
            selectedResolution
          ].animation.inAnimation = animationObject;
        } else if (effect === "Zoom Out" && isChildOut) {
          // No style update here
          newValues.positions[parentId][
            selectedResolution
          ].animation.outAnimation = animationObject;
        } else if (effect === "Position" && direction) {
          if (isChildIn) {
            newValues.positions[parentId][
              selectedResolution
            ].animation.inAnimation = animationObject;
          } else if (isChildOut) {
            newValues.positions[parentId][
              selectedResolution
            ].animation.outAnimation = animationObject;
          }
        } else {
          // Additional effects handling
          if (effect === "Rotate In" && isChildIn) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.inAnimation = animationObject;
          } else if (effect === "Rotate Out" && isChildOut) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.outAnimation = animationObject;
          } else if (effect === "Flip In" && isChildIn) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.inAnimation = animationObject;
          } else if (effect === "Flip Out" && isChildOut) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.outAnimation = animationObject;
          } else if (effect === "Scale In" && isChildIn) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.inAnimation = animationObject;
          } else if (effect === "Scale Out" && isChildOut) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.outAnimation = animationObject;
          } else if (effect === "Bounce In" && isChildIn) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.inAnimation = animationObject;
          } else if (effect === "Bounce Out" && isChildOut) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.outAnimation = animationObject;
          } else if (effect === "Wobble" && isChildIn) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.inAnimation = animationObject;
          } else if (effect === "Pulse" && isChildIn) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.inAnimation = animationObject;
          } else if (effect === "Shake" && isChildIn) {
            // No style update here
            newValues.positions[parentId][
              selectedResolution
            ].animation.inAnimation = animationObject;
          }
        }

        // Update the values state, but no visual changes to the item
        setValues(newValues);
        timelineRef.current.itemsData.update(item);
      }
    }
  };

  const toggleInOut = (type) => {
    if (selectedParent) {
      const childId = `${selectedParent.id}-child-${type}`;
      const childItem = timelineRef.current.itemsData.get(childId);
      const newValues = { ...values };

      if (childItem) {
        timelineRef.current.itemsData.remove(childId);
        if (type === "in") {
          if (
            newValues.positions[selectedParent.id][selectedResolution]
              ?.animation?.inAnimation
          ) {
            delete newValues.positions[selectedParent.id][selectedResolution]
              .animation.inAnimation;
          }
        } else if (type === "out") {
          if (
            newValues.positions[selectedParent.id][selectedResolution]
              ?.animation?.outAnimation
          ) {
            delete newValues.positions[selectedParent.id][selectedResolution]
              .animation.outAnimation;
          }
        }
      } else {
        // Re-add the child item
        const start = new Date(selectedParent.start);
        const end = new Date(selectedParent.end);
        const duration = (end.getTime() - start.getTime()) / 3;
        const newChildItem = {
          id: childId,
          content: type.toUpperCase(),
          start: type === "in" ? start : new Date(end.getTime() - duration),
          end: type === "in" ? new Date(start.getTime() + duration) : end,
          group: selectedParent.group,
          className: "child-item",
          editable: {
            remove: false,
            updateTime: true,
            updateGroup: false,
          },
          style: `background-color: ${
            type === "in" ? "#6EAFA0" : "#6EAFA0"
          }88; font-size: 0.7em; height: ${
            selectedParent.style.match(/height: ([^;]+)/)[1]
          }; border-radius: 4px; position: absolute; z-index: 10; line-height: ${
            selectedParent.style.match(/height: ([^;]+)/)[1]
          }; text-align: ${type === "in" ? "left" : "right"}; padding-${
            type === "in" ? "left" : "right"
          }: 5px;`,
        };
        timelineRef.current.itemsData.add(newChildItem);
      }

      setValues(newValues);
    }
  };

  //   console.log("headline log test", values?.positions?.product?.["1200x630"]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <EffectsSidebar
        values={values}
        // setValues={setValues}
        selectedResolution={selectedResolution}
        selectedParent={selectedParent} // Pass selectedParent state
      />
      <Box
        sx={{
          flex: "0 1 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ transform: "scale(0.8)", pt: 1, mb: -10 }}>
          <AdAnimationPreview
            key={values?.id}
            data={values}
            resolution={selectedResolution}
            currentTime={currentTime}
          />
        </Box>
        {/* Close button to return to the editor */}
        <Button variant='contained' onClick={onClose}>
          Close Animation Preview
        </Button>
      </Box>
      <style>
        {`
      .vis-timeline {
        background-color: white; // Set background color to white
      }

      .vis-timeline .vis-item {
        color: white;
        font-size: 0.7em; // Slightly larger font size for timeline items
        height: 25px; // Adjusted item height
        border-radius: 8px; // Rounded corners
        position: relative;
      }

      .vis-timeline .vis-item:hover {
        filter: brightness(0.9);
      }

      .vis-timeline .vis-time-axis .vis-text {
        color: #555;
        font-size: 0.6em; // Slightly larger font size for axis labels
      }

      .vis-timeline .vis-label {
        font-size: 0.7em; // Slightly larger font size for group labels
      }

      .MuiIconButton-root {
        padding: 2px; // Smaller padding for buttons
      }

      .MuiSvgIcon-root {
        font-size: 1rem; // Smaller icon size
      }

      @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }

      @keyframes fadeOut {
        from { opacity: 1; }
        to { opacity: 0; }
      }
    `}
      </style>

      <ControlToolbar
        isPlaying={isPlaying}
        resolutions={resolutions}
        selectedResolution={selectedResolution}
        handlePlay={handlePlay}
        handlePause={handlePause}
        handleStop={handleStop}
        handleRewind={handleRewind}
        handleResolutionChange={handleResolutionChange}
        onClose={onClose}
        timelineLength={timelineLength}
        setTimelineLength={setTimelineLength}
        currentTime={currentTime}
        applyEffect={applyEffect} // Pass applyEffect function
        toggleInOut={toggleInOut} // Pass toggleInOut function
        selectedParent={selectedParent} // Pass selectedParent state
      />
      <Box
        ref={containerRef}
        sx={{ flex: "1 1 auto", height: "calc(100% - 40px)" }}
      />
      <EffectsGrid
        open={effectsOpen}
        onClose={() => setEffectsOpen(false)}
        onEffectClick={applyEffect}
        type={effectType}
      />
    </Box>
  );
};

export default AnimationPreview;
