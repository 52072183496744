import { useState, useRef, useEffect } from "react";

const usePlayControl = (timelineRef, timelineLength) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    const timeline = timelineRef.current;
    if (timeline) {
      const onTimeChange = (properties) => {
        if (properties.id === "currentTime") {
          const newTime = timeline.getCustomTime("currentTime").getTime();
          setCurrentTime(newTime);
        }
      };

      timeline.on("timechange", onTimeChange);

      return () => {
        if (timeline) {
          timeline.off("timechange", onTimeChange);
        }
      };
    }
  }, [timelineRef]);

  const handlePlay = () => {
    setIsPlaying(true);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setCurrentTime((prevTime) => {
        let newTime = prevTime + 100;
        if (newTime >= timelineLength) {
          newTime = 0; // Loop back to the beginning
        }
        timelineRef.current.setCustomTime(new Date(newTime), "currentTime");
        return newTime;
      });
    }, 100);
  };

  const handlePause = () => {
    setIsPlaying(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const handleStop = () => {
    setIsPlaying(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setCurrentTime(0);
    timelineRef.current.setCustomTime(new Date(0), "currentTime");
  };

  const handleRewind = () => {
    setCurrentTime(0);
    timelineRef.current.setCustomTime(new Date(0), "currentTime");
  };

  return {
    isPlaying,
    currentTime,
    handlePlay,
    handlePause,
    handleStop,
    handleRewind,
  };
};

export default usePlayControl;
