import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "users",
  initialState: [],
  reducers: {
    addUser: (state, action) => {
      state.push(action.payload);
    },
    setUsers: (state, action) => {
      const existingUser = action.payload.filter(
        (user) => !state.some((u) => u.id === user.id)
      );
      state.push(...existingUser);
    },
    editUser: (state, action) => {
      const {
        id,
        firstName,
        lastName,
        email,
        username,
        password,
        role,
        clientIdUsers,
        clientName,
        createdAt,
        updatedAt,
        countries,
      } = action.payload;
      const existingUser = state.find((user) => user.id === id);
      if (existingUser) {
        existingUser.firstName = firstName;
        existingUser.lastName = lastName;
        existingUser.email = email;
        existingUser.username = username;
        existingUser.password = password;
        existingUser.role = role;
        existingUser.clientIdUsers = clientIdUsers;
        existingUser.clientName = clientName;
        existingUser.createdAt = createdAt;
        existingUser.updatedAt = updatedAt;
        existingUser.countries = countries;
      }
    },
    deleteOneUser: (state, action) => {
      const { id } = action.payload;
      return state.filter((user) => user.id !== id);
    },
    deleteManyUsers: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((user) => !idsToDelete.has(user.id));
    },
    updateUsers: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addUser,
  setUsers,
  editUser,
  deleteOneUser,
  deleteManyUsers,
  updateUsers,
} = userSlice.actions;
export default userSlice.reducer;
