import React, { useState, useEffect } from "react";
import {
  Switch,
  FormControlLabel,
  TextField,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axiosInstance";
import { CLIENTS_URL } from "../../../config";
import { editClient } from "../../clients/features/clientSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const AISettings = ({ currentUser }) => {
  const ClientsList = useSelector((store) => store.clients);
  const [isAIEnabled, setIsAIEnabled] = useState(false);
  const [aiApiKey, setAiApiKey] = useState("");
  const [showAiApiKey, setShowAiApiKey] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const clientId = currentUser?.currentUser?.clientIdUsers;

  useEffect(() => {
    console.log("Fetching client settings...");
    const client = ClientsList.find((c) => c.id === clientId);
    console.log("Client", client);
    if (client?.settings?.ai) {
      const settings = client.settings.ai;
      setIsAIEnabled(settings.AIEnabled || false);
      setAiApiKey(settings.AIApiKey || "");
      console.log("Initialized state with AI settings:", settings);
    }
  }, [ClientsList, clientId]);

  const handleAIToggle = (event) => {
    setIsAIEnabled(event.target.checked);
  };

  const handleAiApiKeyChange = (event) => {
    setAiApiKey(event.target.value);
  };

  const updateClientSettings = async () => {
    const payload = {
      ai: {
        AIEnabled: isAIEnabled,
        AIApiKey: aiApiKey,
      },
    };

    console.log("Payload to be sent:", payload);

    try {
      console.log(
        "Sending request to:",
        `${CLIENTS_URL}/${clientId}/settings/`
      );
      const response = await axiosInstance.put(
        `${CLIENTS_URL}/${clientId}/settings/`,
        payload
      );

      console.log("Response received from server:", response);

      if (!response || !response.data) {
        console.error("Invalid response from server");
        throw new Error("Invalid response from server");
      }

      console.log("Response data:", response.data);

      const updatedClient = {
        id: clientId,
        settings: {
          ...ClientsList.find((c) => c.id === clientId).settings,
          ai: {
            AIEnabled: response.data.ai.AIEnabled,
            AIApiKey: response.data.ai.AIApiKey,
          },
        },
      };

      console.log("Updated client to be dispatched:", updatedClient);

      dispatch(editClient(updatedClient));

      // Show Snackbar on successful update
      setSnackbarOpen(true);

      console.log("State updated with new values");
    } catch (error) {
      console.error("Error updating settings", error);
      if (error.response) {
        console.error("Response error data:", error.response.data);
        console.error("Response error status:", error.response.status);
        console.error("Response error headers:", error.response.headers);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={isAIEnabled}
            onChange={handleAIToggle}
            size='small'
          />
        }
        label='AI'
        sx={{
          "& .MuiTypography-body1": { fontSize: "0.875rem" },
        }}
      />
      {isAIEnabled && (
        <TextField
          label='AI API Key'
          variant='outlined'
          fullWidth
          size='small'
          type={showAiApiKey ? "text" : "password"}
          InputProps={{
            sx: { fontSize: "0.875rem", padding: "2px" },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setShowAiApiKey(!showAiApiKey)}>
                  {showAiApiKey ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: { fontSize: "0.875rem" },
          }}
          value={aiApiKey}
          onChange={handleAiApiKeyChange}
        />
      )}
      <Button
        variant='contained'
        color='primary'
        onClick={updateClientSettings}
        sx={{ height: "fit-content" }}
      >
        Update
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='success'
          iconMapping={{
            success: <CheckCircleIcon style={{ color: "white" }} />,
          }}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          You updated AI settings!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AISettings;
