import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Paper,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import {
  Token,
  Refresh,
  ContentPaste,
  CheckCircle,
  ExpandMore,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { keyframes } from "@mui/system";

const TOKEN_TO_USD_RATE = 2.3; // 1 ADT = 2.3 USD
const USD_TO_EUR_RATE = 0.85; // Example fixed conversion rate
const USD_TO_GBP_RATE = 0.75; // Example fixed conversion rate
const USD_TO_JPY_RATE = 110.0; // Example fixed conversion rate

const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

const TokenBalance = () => {
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [amount, setAmount] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [transferSuccess, setTransferSuccess] = useState(false);
  const [showTransferFields, setShowTransferFields] = useState(false);
  const theme = useTheme();

  const fetchBalance = async () => {
    try {
      setLoading(true);
      setLoadingMessage("Loading AdReady (ADT) token info...");
      // Fetch token balance
      const balanceResponse = await axios.get("/api/tokens/token-balance");
      setBalance(balanceResponse.data.balance);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  const handlePasteClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setReceiverAddress(text);
    } catch (error) {
      console.error("Failed to read clipboard contents: ", error);
    }
  };

  const handleTransferClick = async () => {
    try {
      setLoading(true);
      setLoadingMessage(`Transferring ${amount} ADT tokens...`);
      const response = await axios.post("/api/tokens/send-tokens", {
        amount: parseFloat(amount),
        receiverAddress,
        clientId: 1, // replace with actual client ID if needed
      });
      console.log("Transfer successful:", response.data);
      setLoadingMessage("");
      setTransferSuccess(true);
      setLoading(false);
      setTimeout(() => {
        setTransferSuccess(false);
        fetchBalance(); // Refresh balance after transfer
      }, 2500); // Show success message for 1.5 seconds
    } catch (error) {
      console.error("Error transferring tokens:", error);
      setLoadingMessage("Error transferring tokens");
      setTimeout(() => {
        setLoadingMessage("");
        setLoading(false);
      }, 3000);
    }
  };

  const handleShowTransferFields = () => {
    setShowTransferFields((prev) => !prev);
  };

  if (loading) {
    return (
      <Container>
        <Paper
          sx={{
            borderRadius: 1,
            overflow: "hidden",
            boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
            position: "relative",
            width: "100%",
            padding: 2,
          }}
        >
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px", // Adjust as needed
                }}
              >
                <CircularProgress />
                <Typography
                  sx={{
                    mt: 2,
                    animation: `${fadeInOut} 3s infinite`,
                    color: theme.palette.primary.main,
                    fontSize: "1.2rem", // Adjust as needed
                  }}
                >
                  {loadingMessage}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </Container>
    );
  }

  if (balance === null) {
    return (
      <Container>
        <Typography variant='h6'>Error fetching data</Typography>
      </Container>
    );
  }

  // The balance fetched is already in whole tokens
  const tokenBalance = balance; // Assuming balance is in ADT, not the smallest unit
  const usdValue = tokenBalance * TOKEN_TO_USD_RATE;
  const eurValue = usdValue * USD_TO_EUR_RATE;
  const gbpValue = usdValue * USD_TO_GBP_RATE;
  const jpyValue = usdValue * USD_TO_JPY_RATE;

  if (transferSuccess) {
    return (
      <Container>
        <Paper
          sx={{
            borderRadius: 1,
            overflow: "hidden",
            boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
            position: "relative",
            width: "100%",
            padding: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Ensuring the card takes the full height of the container
          }}
        >
          <Card sx={{ width: "100%" }}>
            <CardContent sx={{ textAlign: "center" }}>
              <CheckCircle sx={{ fontSize: 40, color: "success.main" }} />
              <Typography
                variant='h6'
                sx={{
                  fontWeight: "bold",
                  mt: 2,
                  mb: 2,
                  color: theme.palette.success.main,
                }}
              >
                Successfully transferred {amount} AdReady (ADT) tokens!
              </Typography>
              <Typography
                variant='body1'
                sx={{
                  animation: `${fadeInOut} 3s infinite`,
                  color: theme.palette.primary.main,
                }}
              >
                Receiver Address: {receiverAddress}
              </Typography>
            </CardContent>
          </Card>
        </Paper>
      </Container>
    );
  }

  return (
    <Container>
      <Paper
        sx={{
          borderRadius: 1,
          overflow: "hidden",
          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
          position: "relative",
          width: "100%",
          padding: 2,
          maxHeight: showTransferFields ? "auto" : 135, // Adjusted line
        }}
      >
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box display='flex' alignItems='center'>
                  <Token sx={{ mr: 1, color: theme.palette.primary.main }} />
                  <Typography variant='body1' component='div'>
                    AdReady Token (ADT)
                  </Typography>
                </Box>
                <Typography variant='body2'>
                  USD:{" "}
                  {usdValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Typography variant='body2'>
                  GBP:{" "}
                  {gbpValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <IconButton
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                    onClick={fetchBalance}
                  >
                    <Refresh />
                  </IconButton>
                  <IconButton
                    sx={{
                      color: theme.palette.primary.main,
                      mt: 2,
                    }}
                    onClick={handleShowTransferFields}
                  >
                    <ExpandMore />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                  }}
                  variant='body1'
                  component='div'
                >
                  Balance: {tokenBalance.toLocaleString()}
                </Typography>

                <Typography variant='body2' sx={{ textAlign: "center" }}>
                  EUR:{" "}
                  {eurValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Typography variant='body2' sx={{ textAlign: "center" }}>
                  JPY:{" "}
                  {jpyValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
            {showTransferFields && (
              <Box sx={{ mt: 4, position: "relative" }}>
                <TextField
                  label='Amount'
                  type='number'
                  fullWidth
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label='Receiver Address'
                  fullWidth
                  value={receiverAddress}
                  onChange={(e) => setReceiverAddress(e.target.value)}
                  sx={{ mb: 2 }}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handlePasteClick}>
                        <ContentPaste />
                      </IconButton>
                    ),
                  }}
                />
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={handleTransferClick}
                >
                  Transfer
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default TokenBalance;
