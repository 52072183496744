import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "../pages/users/features/userSlice";
import adsReducer from "../pages/ads/features/adSlice";
import clientsReducer from "../pages/clients/features/clientSlice";
import templatesReducer from "../pages/templates/features/templateSlice";
import brandsReducer from "../pages/brands/features/brandsReducer";
import categoriesReducer from "../pages/categories/features/categoriesReducer";
import backgroundsReducer from "../pages/brands/features/backgroundReducer";
import productReducer from "../pages/brands/features/productReducer";
import stickerReducer from "../pages/brands/features/stickerReducer";
import ctaReducer from "../pages/brands/features/ctaReducer";
import logoReducer from "../pages/brands/features/logoReducer";
import fontReducer from "../pages/brands/features/fontReducer";
import adsDataReducer from "../pages/ads/features/adsDataSlice";
import layoutsReducer from "../pages/ads/features/layoutSlice";
import commentsReducer from "../pages/ads/features/commentSlice";
import actionsReducer from "../actions/features/actionSlice";
import notificationsReducer from "../notifications/features/notificationSlice";
import editingComponentReducer from "../pages/ads/features/editingComponentSlices.js";
import zIndexesReducer from "../pages/ads/features/zIndexesSlice.js";
import badgeReducer from "../pages/brands/features/badgeReducer.js";
import elementReducer from "../pages/brands/features/elementReducer.js";

export const store = configureStore({
  reducer: {
    users: usersReducer,
    ads: adsReducer,
    adsData: adsDataReducer,
    clients: clientsReducer,
    brands: brandsReducer,
    templates: templatesReducer,
    categories: categoriesReducer,
    backgrounds: backgroundsReducer,
    products: productReducer,
    badges: badgeReducer,
    stickers: stickerReducer,
    ctas: ctaReducer,
    logos: logoReducer,
    fonts: fontReducer,
    elements: elementReducer,
    layouts: layoutsReducer,
    comments: commentsReducer,
    actions: actionsReducer,
    notifications: notificationsReducer,
    editingComponent: editingComponentReducer,
    zIndexes: zIndexesReducer,
  },
});
