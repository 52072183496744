import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Paper,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { AuthContext } from "../context/authContext";
import {
  AccountBalanceWallet,
  ContentCopy,
  Refresh,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const ClientSolanaAccount = () => {
  const { currentUser } = useContext(AuthContext);
  const clientsList = useSelector((store) => store.clients);
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(null);

  useEffect(() => {
    const clientId = currentUser?.clientIdUsers;

    if (!clientId) {
      console.error("Client ID is missing or undefined.");
      setLoading(false);
      return;
    }

    const client = clientsList.find((client) => client.id === clientId);

    if (client) {
      setClientData(client);
      fetchTokenBalance(client);
    } else {
      const fetchClientData = async () => {
        try {
          const response = await axios.get(`/api/clients/${clientId}`);
          setClientData(response.data);
          fetchTokenBalance(response.data);
        } catch (error) {
          console.error("Error fetching client data:", error);
          setLoading(false);
        }
      };

      fetchClientData();
    }
  }, [clientsList, currentUser?.clientIdUsers]);

  const fetchTokenBalance = async (client) => {
    try {
      const walletAddress =
        client?.data?.wallet?.solana?.solanaPublicKey || null;

      if (!walletAddress) {
        console.warn("No Solana wallet address found for this client.");
        setTokenBalance(0);
        setLoading(false);
        return;
      }

      const response = await axios.get(
        `/api/tokens/client-token-balance/${client.id}`
      );
      setTokenBalance(response.data.balance);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching token balance:", error);
      setLoading(false);
    }
  };

  const refreshClientData = async () => {
    setLoading(true);
    const clientId = currentUser?.clientIdUsers;
    if (!clientId) {
      console.error("Client ID is missing or undefined.");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get(`/api/clients/${clientId}`);
      setClientData(response.data);
      fetchTokenBalance(response.data);
    } catch (error) {
      console.error("Error fetching client data:", error);
      setLoading(false);
    }
  };

  const handleCopy = () => {
    const walletAddress =
      clientData?.data?.wallet?.solana?.solanaPublicKey || null;
    if (walletAddress) {
      navigator.clipboard.writeText(walletAddress);
    } else {
      console.warn("No wallet address to copy.");
    }
  };

  if (loading) {
    return (
      <Container sx={{ mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!clientData) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant='h6' color='error'>
          Error loading client data
        </Typography>
      </Container>
    );
  }

  const walletAddress =
    clientData?.data?.wallet?.solana?.solanaPublicKey || null;

  return (
    <Container sx={{ mt: 4 }}>
      <Paper
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
          position: "relative",
        }}
      >
        <Card>
          <CardContent>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb={2}
            >
              <Box display='flex' alignItems='center'>
                <AccountBalanceWallet
                  sx={{ mr: 1, color: theme.palette.primary.main }}
                />
                <Typography variant='h5' component='div'>
                  Solana Account Information
                </Typography>
              </Box>
              <Box>
                <Tooltip title='Copy Wallet Address'>
                  <IconButton onClick={handleCopy} disabled={!walletAddress}>
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Refresh Data'>
                  <IconButton onClick={refreshClientData}>
                    <Refresh />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {walletAddress ? (
              <>
                <Typography variant='body1' component='div'>
                  <strong>Wallet Address:</strong> {walletAddress}
                </Typography>
                <Typography variant='body1' component='div'>
                  <strong>Token Balance:</strong>{" "}
                  {tokenBalance !== null
                    ? tokenBalance.toFixed(9)
                    : "Loading..."}
                </Typography>
              </>
            ) : (
              <Typography variant='body1' color='textSecondary'>
                No Solana wallet associated with this account.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default ClientSolanaAccount;
