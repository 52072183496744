import React from "react";
import { Grid, Typography, LinearProgress, Button, Box } from "@mui/material";

const renderFileInput = (
  label,
  field,
  handleImageChange,
  values,
  uploadFunction,
  accept = "image/*"
) => {
  // console.log(
  //   `Rendering file input for field: ${field}, with accept type: ${accept}`
  // );

  return (
    <>
      <input
        accept={accept}
        id={`${field}-button-file`}
        type='file'
        hidden
        onChange={(e) => {
          console.log(`Inside input onChange for field: ${field}`);
          console.log(`Event target files: `, e.target.files);
          if (field === "fonts") {
            // console.log(`File details for fonts: `, e.target.files[0]);
          }
          if (uploadFunction) {
            uploadFunction(e);
          } else {
            handleImageChange(e, field);
          }
        }}
        multiple
      />
      <label htmlFor={`${field}-button-file`}>
        <Button
          variant='outlined'
          component='span'
          style={{
            height: "40px",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
          }}
        >
          {label}
        </Button>
      </label>
    </>
  );
};

const FileUploadGrid = ({
  uploadProgress,
  uploadFiles,
  values,
  handleImageChange,
}) => {
  // console.log("Inside FileUploadGrid component");

  const fileFields = [
    { label: "Upload Backgrounds", field: "backgrounds" },
    { label: "Upload Products", field: "products" },
    { label: "Upload Stickers", field: "stickers" },
    { label: "Upload CTA`s", field: "ctas" },
    { label: "Upload Logos", field: "logos" },
    {
      label: "Upload Fonts",
      field: "fonts",
      accept: ".ttf,.otf,.woff,.woff2",
    },
    { label: "Upload Badges", field: "badges" },
    { label: "Upload Elements", field: "elements" }, // Added this line
  ];

  // console.log(`Current uploadProgress: ${uploadProgress}`);

  return (
    <Box sx={{ m: 3, pl: 10 }}>
      {uploadProgress >= 0 ? (
        <Grid
          item
          xs={12}
          container
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <Grid item xs={10}>
            <LinearProgress variant='determinate' value={uploadProgress} />
          </Grid>
          <Grid item xs={2}>
            <Typography>{`${uploadProgress}%`}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction='row' alignItems='center' spacing={2}>
          {fileFields.map(({ label, field, accept }) => {
            // console.log(`Mapping through fileFields: ${field}, ${accept}`);
            return (
              <Grid item xs={4} sm={3} md={2} key={field}>
                {renderFileInput(
                  label,
                  field,
                  (e) => {
                    // console.log(`Uploading files for field: ${field}`);
                    // console.log(`Event: `, e);
                    uploadFiles(e, field);
                  },
                  values,
                  null,
                  accept
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

export default FileUploadGrid;
