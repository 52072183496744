import React, { useState, useEffect } from "react";
import {
  Switch,
  FormControlLabel,
  TextField,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axiosInstance";
import { CLIENTS_URL } from "../../../config";
import { editClient } from "../../clients/features/clientSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ShutterstockSettings = ({ currentUser }) => {
  const ClientsList = useSelector((store) => store.clients);
  const [isShutterstockEnabled, setIsShutterstockEnabled] = useState(false);
  const [shutterstockToken, setShutterstockToken] = useState("");
  const [consumerKey, setConsumerKey] = useState("");
  const [consumerSecret, setConsumerSecret] = useState("");
  const [shutterstockOauthTokenEndpoint, setShutterstockOauthTokenEndpoint] =
    useState("");
  const [shutterstockApiEndpoint, setShutterstockApiEndpoint] = useState("");
  const [showToken, setShowToken] = useState(false);
  const [showConsumerKey, setShowConsumerKey] = useState(false);
  const [showConsumerSecret, setShowConsumerSecret] = useState(false);
  const [showOauthEndpoint, setShowOauthEndpoint] = useState(false);
  const [showApiEndpoint, setShowApiEndpoint] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const clientId = currentUser?.currentUser?.clientIdUsers;

  useEffect(() => {
    console.log("Fetching client settings...");
    const client = ClientsList.find((c) => c.id === clientId);
    console.log("Client", client);
    if (client?.settings?.shutterstock) {
      const settings = client.settings.shutterstock;
      setIsShutterstockEnabled(settings.isShutterstockEnabled || false);
      setShutterstockToken(settings.shutterstockToken || "");
      setConsumerKey(settings.consumerKey || "");
      setConsumerSecret(settings.consumerSecret || "");
      setShutterstockOauthTokenEndpoint(
        settings.shutterstockOauthTokenEndpoint || ""
      );
      setShutterstockApiEndpoint(settings.shutterstockApiEndpoint || "");
      console.log("Initialized state with client settings:", settings);
    }
  }, [ClientsList, clientId]);

  const handleShutterstockToggle = (event) => {
    setIsShutterstockEnabled(event.target.checked);
  };

  const handleShutterstockTokenChange = (event) => {
    setShutterstockToken(event.target.value);
  };

  const handleConsumerKeyChange = (event) => {
    setConsumerKey(event.target.value);
  };

  const handleConsumerSecretChange = (event) => {
    setConsumerSecret(event.target.value);
  };

  const handleShutterstockOauthTokenEndpointChange = (event) => {
    setShutterstockOauthTokenEndpoint(event.target.value);
  };

  const handleShutterstockApiEndpointChange = (event) => {
    setShutterstockApiEndpoint(event.target.value);
  };

  const updateClientSettings = async () => {
    const updatedSettings = {
      shutterstock: {
        isShutterstockEnabled,
        shutterstockToken,
        consumerKey,
        consumerSecret,
        shutterstockOauthTokenEndpoint,
        shutterstockApiEndpoint,
      },
    };

    console.log("Updated settings to be sent:", updatedSettings);

    try {
      console.log(
        "Sending request to:",
        `${CLIENTS_URL}/${clientId}/settings/`
      );
      const response = await axiosInstance.put(
        `${CLIENTS_URL}/${clientId}/settings/`,
        updatedSettings
      );

      console.log("Response received from server:", response);

      if (!response || !response.data) {
        console.error("Invalid response from server");
        throw new Error("Invalid response from server");
      }

      console.log("Response data:", response.data);

      const {
        isShutterstockEnabled,
        shutterstockToken,
        consumerKey,
        consumerSecret,
        shutterstockOauthTokenEndpoint,
        shutterstockApiEndpoint,
      } = response.data.shutterstock;

      console.log("Extracted values from response:", {
        isShutterstockEnabled,
        shutterstockToken,
        consumerKey,
        consumerSecret,
        shutterstockOauthTokenEndpoint,
        shutterstockApiEndpoint,
      });

      const updatedClient = {
        id: clientId,
        settings: {
          ...ClientsList.find((c) => c.id === clientId).settings,
          shutterstock: {
            isShutterstockEnabled,
            shutterstockToken,
            consumerKey,
            consumerSecret,
            shutterstockOauthTokenEndpoint,
            shutterstockApiEndpoint,
          },
        },
      };

      console.log("Updated client to be dispatched:", updatedClient);

      dispatch(editClient(updatedClient));

      // Show Snackbar on successful update
      setSnackbarOpen(true);

      // Update the state with the response from the server
      setIsShutterstockEnabled(isShutterstockEnabled);
      setShutterstockToken(shutterstockToken || "");
      setConsumerKey(consumerKey || "");
      setConsumerSecret(consumerSecret || "");
      setShutterstockOauthTokenEndpoint(shutterstockOauthTokenEndpoint || "");
      setShutterstockApiEndpoint(shutterstockApiEndpoint || "");

      console.log("State updated with new values");
    } catch (error) {
      console.error("Error updating settings", error);
      if (error.response) {
        console.error("Response error data:", error.response.data);
        console.error("Response error status:", error.response.status);
        console.error("Response error headers:", error.response.headers);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={isShutterstockEnabled}
            onChange={handleShutterstockToggle}
            size='small'
          />
        }
        label='Shutterstock'
        sx={{
          "& .MuiTypography-body1": { fontSize: "0.875rem" },
        }}
      />
      {isShutterstockEnabled && (
        <>
          <TextField
            label='Token'
            variant='outlined'
            fullWidth
            size='small'
            type={showToken ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowToken(!showToken)}>
                    {showToken ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={shutterstockToken}
            onChange={handleShutterstockTokenChange}
          />
          <TextField
            label='Consumer Key'
            variant='outlined'
            fullWidth
            size='small'
            type={showConsumerKey ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowConsumerKey(!showConsumerKey)}
                  >
                    {showConsumerKey ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={consumerKey}
            onChange={handleConsumerKeyChange}
          />
          <TextField
            label='Consumer Secret'
            variant='outlined'
            fullWidth
            size='small'
            type={showConsumerSecret ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowConsumerSecret(!showConsumerSecret)}
                  >
                    {showConsumerSecret ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={consumerSecret}
            onChange={handleConsumerSecretChange}
          />
          <TextField
            label='OAuth Token Endpoint'
            variant='outlined'
            fullWidth
            size='small'
            type={showOauthEndpoint ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowOauthEndpoint(!showOauthEndpoint)}
                  >
                    {showOauthEndpoint ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={shutterstockOauthTokenEndpoint}
            onChange={handleShutterstockOauthTokenEndpointChange}
          />
          <TextField
            label='API Endpoint'
            variant='outlined'
            fullWidth
            size='small'
            type={showApiEndpoint ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowApiEndpoint(!showApiEndpoint)}
                  >
                    {showApiEndpoint ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={shutterstockApiEndpoint}
            onChange={handleShutterstockApiEndpointChange}
          />
        </>
      )}
      <Button
        variant='contained'
        color='primary'
        onClick={updateClientSettings}
        sx={{ height: "fit-content" }}
      >
        Update
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='success'
          iconMapping={{
            success: <CheckCircleIcon style={{ color: "white" }} />,
          }}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          You updated Shutterstock settings!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ShutterstockSettings;
