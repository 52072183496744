import React, { useState, useEffect } from "react";
import {
  Switch,
  FormControlLabel,
  TextField,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axiosInstance";
import { CLIENTS_URL } from "../../../config";
import { editClient } from "../../clients/features/clientSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CloudinarySettings = ({ currentUser }) => {
  const ClientsList = useSelector((store) => store.clients);
  const [isCloudinaryEnabled, setIsCloudinaryEnabled] = useState(false);
  const [cloudName, setCloudName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [cloudinaryUrl, setCloudinaryUrl] = useState("");
  const [showCloudName, setShowCloudName] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false);
  const [showApiSecret, setShowApiSecret] = useState(false);
  const [showCloudinaryUrl, setShowCloudinaryUrl] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const clientId = currentUser?.currentUser?.clientIdUsers;

  useEffect(() => {
    console.log("Fetching client settings...");
    const client = ClientsList.find((c) => c.id === clientId);
    console.log("Client", client);
    if (client?.settings?.cloudinary) {
      const settings = client.settings.cloudinary;
      setIsCloudinaryEnabled(settings.isCloudinaryEnabled || false);
      setCloudName(settings.cloudName || "");
      setApiKey(settings.apiKey || "");
      setApiSecret(settings.apiSecret || "");
      setCloudinaryUrl(settings.cloudinaryUrl || "");
      console.log("Initialized state with Cloudinary settings:", settings);
    }
  }, [ClientsList, clientId]);

  const handleCloudinaryToggle = (event) => {
    setIsCloudinaryEnabled(event.target.checked);
  };

  const handleCloudNameChange = (event) => {
    setCloudName(event.target.value);
  };

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };

  const handleApiSecretChange = (event) => {
    setApiSecret(event.target.value);
  };

  const handleCloudinaryUrlChange = (event) => {
    setCloudinaryUrl(event.target.value);
  };

  const updateClientSettings = async () => {
    const payload = {
      cloudinary: {
        isCloudinaryEnabled,
        cloudName,
        apiKey,
        apiSecret,
        cloudinaryUrl,
      },
    };

    console.log("Payload to be sent:", payload);

    try {
      console.log(
        "Sending request to:",
        `${CLIENTS_URL}/${clientId}/settings/`
      );
      const response = await axiosInstance.put(
        `${CLIENTS_URL}/${clientId}/settings/`,
        payload
      );

      console.log("Response received from server:", response);

      if (!response || !response.data) {
        console.error("Invalid response from server");
        throw new Error("Invalid response from server");
      }

      console.log("Response data:", response.data);

      const updatedClient = {
        id: clientId,
        settings: {
          ...ClientsList.find((c) => c.id === clientId).settings,
          cloudinary: {
            isCloudinaryEnabled: response.data.cloudinary.isCloudinaryEnabled,
            cloudName: response.data.cloudinary.cloudName,
            apiKey: response.data.cloudinary.apiKey,
            apiSecret: response.data.cloudinary.apiSecret,
            cloudinaryUrl: response.data.cloudinary.cloudinaryUrl,
          },
        },
      };

      console.log("Updated client to be dispatched:", updatedClient);

      dispatch(editClient(updatedClient));

      // Show Snackbar on successful update
      setSnackbarOpen(true);

      console.log("State updated with new values");
    } catch (error) {
      console.error("Error updating settings", error);
      if (error.response) {
        console.error("Response error data:", error.response.data);
        console.error("Response error status:", error.response.status);
        console.error("Response error headers:", error.response.headers);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={isCloudinaryEnabled}
            onChange={handleCloudinaryToggle}
            size='small'
          />
        }
        label='Cloudinary'
        sx={{
          "& .MuiTypography-body1": { fontSize: "0.875rem" },
        }}
      />
      {isCloudinaryEnabled && (
        <>
          <TextField
            label='Cloudinary Cloud Name'
            variant='outlined'
            fullWidth
            size='small'
            type={showCloudName ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowCloudName(!showCloudName)}>
                    {showCloudName ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={cloudName}
            onChange={handleCloudNameChange}
          />
          <TextField
            label='Cloudinary API Key'
            variant='outlined'
            fullWidth
            size='small'
            type={showApiKey ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowApiKey(!showApiKey)}>
                    {showApiKey ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={apiKey}
            onChange={handleApiKeyChange}
          />
          <TextField
            label='Cloudinary API Secret'
            variant='outlined'
            fullWidth
            size='small'
            type={showApiSecret ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowApiSecret(!showApiSecret)}>
                    {showApiSecret ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={apiSecret}
            onChange={handleApiSecretChange}
          />
          <TextField
            label='Cloudinary URL'
            variant='outlined'
            fullWidth
            size='small'
            type={showCloudinaryUrl ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowCloudinaryUrl(!showCloudinaryUrl)}
                  >
                    {showCloudinaryUrl ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={cloudinaryUrl}
            onChange={handleCloudinaryUrlChange}
          />
        </>
      )}
      <Button
        variant='contained'
        color='primary'
        onClick={updateClientSettings}
        sx={{ height: "fit-content" }}
      >
        Update
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='success'
          iconMapping={{
            success: <CheckCircleIcon style={{ color: "white" }} />,
          }}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          You updated Cloudinary settings!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CloudinarySettings;
