import { createSlice } from "@reduxjs/toolkit";

const elementSlice = createSlice({
  name: "elements",
  initialState: [],
  reducers: {
    addElement: (state, action) => {
      state.push(action.payload);
    },
    setElements: (state, action) => {
      const existingElements = action.payload.res.filter(
        (element) => !state.some((e) => e.id === element.id)
      );
      state.push(...existingElements);
    },
    editElement: (state, action) => {
      const elementToUpdate = action.payload;
      const index = state.findIndex(
        (element) => element.id === elementToUpdate.id
      );
      if (index !== -1) {
        state[index] = elementToUpdate; // Update the entire element object
      }
    },
    deleteOneElement: (state, action) => {
      const { id } = action.payload;
      const index = state.findIndex((element) => element.id === id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    deleteManyElements: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((element, index) => {
        if (idsToDelete.has(element.id)) {
          state.splice(index, 1);
        }
      });
    },
    updateElements: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addElement,
  setElements,
  editElement,
  deleteOneElement,
  updateElements,
  deleteManyElements,
} = elementSlice.actions;
export default elementSlice.reducer;
