import React, { useContext, useState } from "react";
import { Typography, Tabs, Tab, Box } from "@mui/material";
import { AuthContext } from "../../context/authContext";
import AISettings from "./components/AISettings";
import ShutterstockSettings from "./components/ShutterstockSettings";
import UserProfile from "../users/UserProfile";
import FacebookSettings from "./components/FacebookSettings";
import InstagramSettings from "./components/InstagramSettings";
import LinkedInSettings from "./components/LinkedInSettings";
import FigmaSettings from "./components/FigmaSettings";
import CloudinarySettings from "./components/Cloudinary Settings";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ flexGrow: 1 }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Settings = () => {
  const currentUser = useContext(AuthContext);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        mt: 2,
        ml: 10,
      }}
    >
      <Tabs
        orientation='horizontal'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Horizontal tabs example'
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label='Profile' {...a11yProps(0)} />
        <Tab label='AI' {...a11yProps(1)} />
        <Tab label='Shutterstock' {...a11yProps(2)} />
        <Tab label='Facebook' {...a11yProps(3)} />
        <Tab label='Instagram' {...a11yProps(4)} />
        <Tab label='LinkedIn' {...a11yProps(5)} />
        <Tab label='Cloudinary' {...a11yProps(6)} />
        <Tab label='Figma' {...a11yProps(7)} /> {/* Add Figma Tab */}
      </Tabs>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <TabPanel value={value} index={0}>
          <UserProfile currentUser={currentUser} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AISettings currentUser={currentUser} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ShutterstockSettings currentUser={currentUser} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Typography variant='h6'>
            <FacebookSettings currentUser={currentUser} />
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Typography variant='h6'>
            <InstagramSettings currentUser={currentUser} />
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Typography variant='h6'>
            <LinkedInSettings currentUser={currentUser} />
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Typography variant='h6'>
            <CloudinarySettings currentUser={currentUser} />
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Typography variant='h6'>
            <FigmaSettings currentUser={currentUser} />{" "}
            {/* Add FigmaSettings TabPanel */}
          </Typography>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Settings;
