import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  Button,
  LinearProgress,
  Tooltip,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GradientOutlineButton from "../../../components/GradientOutlinedButton";
import AdBannerPreview1200x630 from "../banners/AdBannerPreview1200x630";
import { useTheme } from "@mui/material/styles";
import { formatDateToLocaleString } from "../../../helpers/dateHelper";
import ShareOptionsModal from "./ShareOptionsModal"; // Adjust the path as needed
import { AuthContext } from "../../../context/authContext";
import axiosInstance from "../../../services/axiosInstance";
import { ADS_URL } from "../../../config";
import MouseIcon from "@mui/icons-material/Mouse";
import { SocketContext } from "../../../context/SocketContext ";
import { motion, useAnimation } from "framer-motion";
import { statusAd } from "../features/adSlice";

// import AdsClickIcon from "@mui/icons-material/AdsClick";

const AdCard = ({
  CategoriesList,
  username,
  createdAt,
  updatedAt,
  data,
  status,
  route,
  id,
  boxShadow,
  isLoading = false,
  approverName,
  thumbnail,
  handleDelete,
}) => {
  const { currentUser } = useContext(AuthContext);
  const socket = useContext(SocketContext);

  const params = useParams();
  const theme = useTheme();

  console.log(data);

  const [category, setCategory] = useState(null);
  const [titleWidth, setTitleWidth] = useState(0);
  const titleRef = useRef(null);

  const navigate = useNavigate();

  const { approverId } = data;
  const [trigger, setTrigger] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openShareOptionsModal, setOpenShareOptionsModal] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState("1200x630");

  const [clicksCount, setClicksCount] = useState(0);
  const [impressionsCount, setImpressionsCount] = useState(0);
  const [loadingAnalytics, setLoadingAnalytics] = useState(true);
  const [analyticsError, setAnalyticsError] = useState(null);

  const clicksAnimation = useAnimation();
  const impressionsAnimation = useAnimation();

  // Create motion-enhanced icon components
  const MotionMouseIcon = motion(MouseIcon);
  const MotionVisibilityIcon = motion(VisibilityIcon);

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch clicks and impressions counts
    const fetchAnalytics = async () => {
      setLoadingAnalytics(true);

      try {
        const headers = {
          clientIdUsers: parseInt(currentUser?.clientIdUsers, 10),
          user_id: parseInt(currentUser?.id, 10),
          role: currentUser?.role,
        };

        const { data: analytics } = await axiosInstance.get(
          `${ADS_URL}/${data.id}/analytics`,
          { headers }
        );

        setClicksCount(analytics.clicks);
        setImpressionsCount(analytics.impressions);
      } catch (error) {
        console.error("Error fetching analytics:", error);
        setAnalyticsError("Failed to load analytics");
      } finally {
        setLoadingAnalytics(false);
      }
    };

    fetchAnalytics();
  }, [data.id, currentUser]);

  // New useEffect to set up the Socket.IO listener
  useEffect(() => {
    if (!socket) return;

    const handleUpdateAnalytics = (data) => {
      if (data.adId === id) {
        setClicksCount(data.clicks);
        setImpressionsCount(data.impressions);
      }
    };

    socket.on("updateAnalytics", handleUpdateAnalytics);

    // Cleanup the listener on component unmount
    return () => {
      socket.off("updateAnalytics", handleUpdateAnalytics);
    };
  }, [socket, id]);

  useEffect(() => {
    clicksAnimation.start({
      scale: [1, 1.5, 1],
      transition: { duration: 0.5 },
    });
  }, [clicksCount]);

  useEffect(() => {
    impressionsAnimation.start({
      scale: [1, 1.5, 1],
      transition: { duration: 0.5 },
    });
  }, [impressionsCount]);

  const handleEditClick = () => {
    if (status === "approved") {
      setOpenDialog(true);
    } else {
      navigate(`${route}/${id}`);
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "approved":
        return (
          <CheckCircleIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.approve }}
          />
        );
      case "rejected":
        return (
          <CancelIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.rejected }}
          />
        );
      case "pending":
      default:
        return (
          <QueryBuilderOutlinedIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.pending }}
          />
        );
    }
  };

  useEffect(() => {
    // Trigger the animation logic whenever approverId or status changes
    setTrigger(true);

    // Reset it back after 1 second (or however long your animation lasts)
    setTimeout(() => setTrigger(false), 1000);
  }, [approverId, status]);

  useEffect(() => {
    const category = CategoriesList.find(
      (category) => category.id === data.category_id
    );
    setCategory(category);
  }, [data, CategoriesList]);

  useEffect(() => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.offsetWidth);
    }
  }, [data?.title]);

  const StatusCell = ({ status, comment }) => {
    return (
      <Tooltip title={comment}>
        <div>{getStatusText(status)}</div>
      </Tooltip>
    );
  };

  // Function to change the status to "pending" and dispatch the action
  // Function to change the status to "pending" and dispatch the action
  const changeStatusToPending = async (id) => {
    try {
      // Update the status to "pending" in the backend
      await axiosInstance.put(`${ADS_URL}/status/${id}`, {
        id,
        status: "pending",
      });

      // Dispatch the action to update the status in the frontend state
      dispatch(
        statusAd({
          id,
          status: "pending",
        })
      );

      console.log("Status dispatch action completed for ad:", id);
    } catch (error) {
      // Handle any errors that occur during the status update
      console.error("Error updating ad status:", error);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: 400,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            boxShadow: { boxShadow },
            boxSizing: "border-box",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 277,
              left: 22,
              fontSize: 14,
              lineHeight: "150%",
              whiteSpace: "pre-wrap",
              textAlign: "left",
            }}
          >
            <Typography color='text.secondary' variant='body1'>
              <Box component='span' sx={{ fontWeight: 600, ml: 4 }}>
                User:{" "}
              </Box>
              <Box component='span'>{username}</Box>
            </Typography>
            <Typography color='text.secondary' variant='body1'>
              <Box component='span' sx={{ fontWeight: 600 }}>
                Updated:
              </Box>
              <Box component='span'> {formatDateToLocaleString(updatedAt)}</Box>
            </Typography>
            <Typography color='text.secondary' variant='body1'>
              <Box component='span' sx={{ fontWeight: 600 }}>
                Created:
              </Box>
              <Box component='span'>{formatDateToLocaleString(createdAt)}</Box>
            </Typography>
          </Box>
          <Avatar
            alt=''
            src='/avatar-28@2x.png'
            sx={{
              position: "absolute",
              height: "5.85%",
              width: "6.23%",
              top: "68.60%",
              right: "88.21%",
              bottom: "25.91%",
              left: "5.56%",
              maxWidth: "100%",
              overflow: "hidden",
              maxHeight: "100%",
              objectFit: "cover",
              mt: 0.4,
            }}
          />

          <Typography
            color='text.secondary'
            variant='h6'
            ref={titleRef}
            sx={{
              position: "absolute",
              top: 226,
              left: 22,
              fontSize: 18,
              lineHeight: "140%",
              textAlign: "left",
            }}
          >
            {data?.title}
            <IconButton
              sx={{ marginLeft: 1 }}
              onClick={() => setOpenShareOptionsModal(true)}
              aria-label='share'
            >
              <ShareIcon color='primary' sx={{ fontSize: 18 }} />
            </IconButton>
            {/* Adding clicks and impressions after the share icon */}
            <Box sx={{ ml: 7, mt: -3.5 }}>
              {loadingAnalytics ? (
                <CircularProgress size={20} />
              ) : analyticsError ? (
                <Typography variant='body2' color='error'>
                  {analyticsError}
                </Typography>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    ml: 8, // Add margin-left for spacing after share icon
                  }}
                >
                  {/* Icon representing Clicks with Tooltip */}
                  <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                    <Tooltip title='Clicks'>
                      <MotionMouseIcon
                        color='primary'
                        sx={{ fontSize: 20, mr: 0.5 }}
                        animate={clicksAnimation}
                      />
                    </Tooltip>
                    <Typography variant='body1' color='text.secondary'>
                      {clicksCount}
                    </Typography>
                  </Box>

                  {/* Icon representing Impressions with Tooltip */}
                  <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                    <Tooltip title='Impressions'>
                      <MotionVisibilityIcon
                        color='primary'
                        sx={{ fontSize: 20, mr: 0.5 }}
                        animate={impressionsAnimation}
                      />
                    </Tooltip>
                    <Typography variant='body1' color='text.secondary'>
                      {impressionsCount}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Typography>

          <Typography
            color='text.secondary'
            variant='body1'
            sx={{
              position: "absolute",
              top: 231,
              left: 285,
              fontSize: 12,
              lineHeight: "150%",
              fontWeight: 600,
              textAlign: "left",
            }}
          >
            Category:{" "}
            <Box component='span' sx={{ color: category?.color }}>
              {category?.name}
            </Box>
            <IconButton
              sx={{ position: "absolute", top: 37, left: 0 }}
              onClick={() => handleDelete(data)}
              aria-label='delete'
            >
              <DeleteIcon color='primary' sx={{ fontSize: 25 }} />
            </IconButton>
          </Typography>

          <Box
            sx={{
              position: "absolute",
              top: "calc(226px + 1em + 15px)", // adjust as needed
              left: 22,
              width: titleWidth,
              height: 2,
              backgroundImage: "linear-gradient(to right, #F37979, #A06EAF)",
              zIndex: 1,
            }}
          />

          <Box
            sx={{
              position: "absolute",
              top: "calc(226px + 1em + 15px)",
              left: 22,
              width: 336,
              height: 2,
              backgroundColor: theme.palette.primary.tableBorderColor,
            }}
          />

          <Box
            sx={{
              position: "absolute",
              top: "calc(226px + 1em + 15px)",
              left: 22,
              width: 336,
              height: 2,
              backgroundColor: theme.palette.primary.tableBorderColor,
            }}
          />

          <Box
            sx={{
              position: "absolute",
              top: 25,
              width: "100%",
              height: 180,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            {isLoading ? (
              <CircularProgress sx={{ mt: 30, ml: 60 }} size={200} />
            ) : (
              <div
                style={{
                  transform: "scale(0.28)",
                  transformOrigin: "center center",
                }}
              >
                <AdBannerPreview1200x630
                  key={data.id}
                  data={data}
                  resolution={selectedResolution}
                />
              </div>
            )}
          </Box>
          {/* {Dialog for Edit when Edit is Disabled when ad is approved} */}
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {"Edit Confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                This Ad is already approved. Are you sure you want to edit it?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
              <Button
                onClick={async () => {
                  // Change the status to "pending" before navigating
                  await changeStatusToPending(id);

                  // Close the dialog
                  setOpenDialog(false);

                  // Navigate to the specified route
                  navigate(`${route}/${id}`);
                }}
                autoFocus
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>

          {status === "approved" && (
            <Box
              onClick={handleEditClick}
              sx={{
                position: "absolute",
                top: 354,
                left: 286,
                width: 75,
                height: 27,
                cursor: "pointer",
                zIndex: 1,
              }}
            />
          )}

          <Button
            variant='contained'
            onClick={handleEditClick}
            sx={{
              position: "absolute",
              top: 354,
              left: 286,
              width: 75,
              height: 27,
              color: theme.palette.primary.light,
              fontWeight: 600,
              zIndex: 0,
            }}
            disabled={status === "approved"}
          >
            <EditIcon />
            EDIT
          </Button>

          <GradientOutlineButton
            data={data}
            variant='outlined'
            sx={{
              position: "absolute",
              top: 322,
              left: 287.09,
              width: 75,
              height: 27,
              fontWeight: 600,
            }}
          >
            <VisibilityIcon />
            VIEW
          </GradientOutlineButton>

          <Box
            sx={{
              position: "absolute",
              top: 275,
              left: 325,
            }}
          >
            <Typography color='text.secondary' variant='body1'>
              <Box component='span' sx={{ fontWeight: 600 }}>
                ID:
              </Box>
              <Box component='span'>{data.id}</Box>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              color='text.secondary'
              variant='text'
              sx={{
                position: "absolute",
                top: 354,
                left: 23,
                width: 68.97,
                height: 21,
                fontWeight: 600,
              }}
            >
              STATUS:
            </Box>
            <Box
              sx={{
                position: "relative",
                top: 355,
                left: 90,
                width: 68.97,
                height: 21,
                fontWeight: 600,
              }}
            >
              <StatusCell status={status} />
            </Box>
            {["pending", "approved", "rejected"].includes(status) && (
              <Box
                sx={{
                  position: "absolute",
                  top: 355,
                  left: 122,
                  fontWeight: 600,
                }}
              >
                <Typography
                  sx={{
                    color:
                      status === "approved"
                        ? theme.palette.approveIcons.approve
                        : status === "rejected"
                        ? theme.palette.approveIcons.rejected
                        : theme.palette.approveIcons.pending,
                  }}
                >
                  {status === "pending" && `Waiting for ${approverName}`}
                  {status === "approved" && `Approved by ${approverName}`}
                  {status === "rejected" && `Rejected by ${approverName}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <LinearProgress
          variant={"determinate"}
          value={100}
          sx={{
            position: "relative",
            top: 397,
            left: 0,
            width: 378,
            height: 6,
            transform: "rotate(0deg)",
            transformOrigin: "0 0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: isLoading ? category?.color : category?.color,
            },
          }}
        />
      </Box>
      <ShareOptionsModal
        open={openShareOptionsModal}
        onClose={() => setOpenShareOptionsModal(false)}
        data={data}
      />
    </Box>
  );
};

export default AdCard;
