export const hasMeaningfulData = (obj) => {
  return Object.values(obj).some(
    (value) => value && value.toString().trim() !== ""
  );
};

// Color palette for items
const colors = ["#A06EAF"];

export const createGroups = (data, selectedResolution) => {
  const filteredKeys = ["productData", "adType", "resolutions"];
  return Object.keys(data)
    .filter(
      (key) =>
        !filteredKeys.includes(key) &&
        data[key][selectedResolution] &&
        hasMeaningfulData(data[key][selectedResolution])
    )
    .map((key, index) => ({
      id: key,
      content: key,
      style: "border-top: 1px dotted #ddd; font-size: 0.7em; height: 25px;",
    }));
};

export const getTimelineItems = (data, selectedResolution) => {
  const relevantItems = [];
  const now = new Date(0); // Start at 0 seconds (UNIX epoch start)
  const itemDuration = 2000; // 2 seconds duration

  Object.keys(data).forEach((key, index) => {
    if (
      typeof data[key] === "object" &&
      data[key] !== null &&
      data[key][selectedResolution] &&
      hasMeaningfulData(data[key][selectedResolution])
    ) {
      const itemData = data[key][selectedResolution];
      const animationData = itemData.animation || {};
      const start = animationData.start ? new Date(animationData.start) : now;
      const end = animationData.end
        ? new Date(animationData.end)
        : new Date(start.getTime() + itemDuration);

      relevantItems.push({
        id: key,
        group: key,
        content: key,
        start: start,
        end: end,
        style: `background-color: ${
          colors[index % colors.length]
        }; font-size: 0.7em; height: 25px;`,
      });
    }
  });

  return relevantItems;
};
