import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import {
  Transform as TransitionIcon,
  Whatshot as EffectIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  ArrowBack as ArrowBackIcon,
  RotateRight as RotateRightIcon,
  RotateLeft as RotateLeftIcon,
  Flip as FlipIcon,
} from "@mui/icons-material";

const EffectsGrid = ({ open, onClose, onEffectClick, type }) => {
  const [animationType, setAnimationType] = useState("spring");
  const [tabValue, setTabValue] = useState(0); // 0 for Image Effects, 1 for Text Effects

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEffectClick = (effect, direction) => {
    onEffectClick(effect, direction, animationType);
    onClose();
  };

  const imageEffects = [
    {
      name: "Fade In",
      type: "IN",
      icon: <TransitionIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Fade Out",
      type: "OUT",
      icon: <TransitionIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Zoom In",
      type: "IN",
      icon: <EffectIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Zoom Out",
      type: "OUT",
      icon: <EffectIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Rotate In",
      type: "IN",
      icon: <RotateRightIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Rotate Out",
      type: "OUT",
      icon: <RotateLeftIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Flip In",
      type: "IN",
      icon: <FlipIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Flip Out",
      type: "OUT",
      icon: <FlipIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Slide In",
      type: "IN",
      icon: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => handleEffectClick("Position", "Up")}
            sx={{ color: "primary.main" }}
          >
            <ArrowUpwardIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <IconButton
              onClick={() => handleEffectClick("Position", "Left")}
              sx={{ color: "primary.main" }}
            >
              <ArrowBackIcon sx={{ fontSize: 40 }} />
            </IconButton>
            <IconButton
              onClick={() => handleEffectClick("Position", "Right")}
              sx={{ color: "primary.main" }}
            >
              <ArrowForwardIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </Box>
          <IconButton
            onClick={() => handleEffectClick("Position", "Down")}
            sx={{ color: "primary.main" }}
          >
            <ArrowDownwardIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Box>
      ),
    },
    {
      name: "Slide Out",
      type: "OUT",
      icon: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => handleEffectClick("Position", "Up")}
            sx={{ color: "primary.main" }}
          >
            <ArrowUpwardIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <IconButton
              onClick={() => handleEffectClick("Position", "Left")}
              sx={{ color: "primary.main" }}
            >
              <ArrowBackIcon sx={{ fontSize: 40 }} />
            </IconButton>
            <IconButton
              onClick={() => handleEffectClick("Position", "Right")}
              sx={{ color: "primary.main" }}
            >
              <ArrowForwardIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </Box>
          <IconButton
            onClick={() => handleEffectClick("Position", "Down")}
            sx={{ color: "primary.main" }}
          >
            <ArrowDownwardIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Box>
      ),
    },
    {
      name: "Scale In",
      type: "IN",
      icon: <EffectIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Scale Out",
      type: "OUT",
      icon: <EffectIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Bounce In",
      type: "IN",
      icon: <EffectIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Bounce Out",
      type: "OUT",
      icon: <EffectIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Wobble",
      type: "IN",
      icon: <EffectIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Pulse",
      type: "IN",
      icon: <EffectIcon sx={{ fontSize: 40 }} />,
    },
    {
      name: "Shake",
      type: "IN",
      icon: <EffectIcon sx={{ fontSize: 40 }} />,
    },
  ];

  const filteredEffects =
    tabValue === 0 ? imageEffects.filter((effect) => effect.type === type) : []; // Empty array for Text Effects for now

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 360,
          bgcolor: "background.paper",
          border: "2px solid",
          borderColor: "primary.main",
          boxShadow: 24,
          p: 2,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant='h6' sx={{ textAlign: "center" }}>
            Select Animation Type
          </Typography>
          <Select
            value={animationType}
            onChange={(e) => setAnimationType(e.target.value)}
            fullWidth
          >
            <MenuItem value='spring'>Spring</MenuItem>
            <MenuItem value='ease'>Ease</MenuItem>
            <MenuItem value='linear'>Linear</MenuItem>
            <MenuItem value='easeIn'>Ease In</MenuItem>
            <MenuItem value='easeOut'>Ease Out</MenuItem>
            <MenuItem value='easeInOut'>Ease In Out</MenuItem>
          </Select>
        </Box>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant='fullWidth'
          sx={{ mb: 2 }}
        >
          <Tab label='Image Effects' />
          <Tab label='Text Effects' />
        </Tabs>
        <Grid container spacing={2}>
          {filteredEffects.map((effect, index) => (
            <Grid item xs={3} key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() =>
                    handleEffectClick(
                      effect.name,
                      effect.name === "Position" ? effect.direction : null
                    )
                  }
                  sx={{
                    width: 60,
                    height: 60,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid",
                    borderColor: "primary.main",
                    borderRadius: 2,
                    mb: 1,
                    color: "primary.main",
                  }}
                >
                  {effect.icon}
                </IconButton>
                <Typography variant='caption' sx={{ textAlign: "center" }}>
                  {effect.name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  );
};

export default EffectsGrid;
