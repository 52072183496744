import React, { useState, useEffect } from "react";
import {
  Switch,
  FormControlLabel,
  TextField,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axiosInstance";
import { CLIENTS_URL } from "../../../config";
import { editClient } from "../../clients/features/clientSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const InstagramSettings = ({ currentUser }) => {
  const ClientsList = useSelector((store) => store.clients);
  const [isInstagramEnabled, setIsInstagramEnabled] = useState(false);
  const [instagramAccessToken, setInstagramAccessToken] = useState("");
  const [facebookApiUrl, setFacebookApiUrl] = useState("");
  const [facebookAppId, setFacebookAppId] = useState("");
  const [facebookAppSecret, setFacebookAppSecret] = useState("");
  const [oauthTokenEndpoint, setOauthTokenEndpoint] = useState("");
  const [showInstagramAccessToken, setShowInstagramAccessToken] =
    useState(false);
  const [showFacebookApiUrl, setShowFacebookApiUrl] = useState(false);
  const [showFacebookAppId, setShowFacebookAppId] = useState(false);
  const [showFacebookAppSecret, setShowFacebookAppSecret] = useState(false);
  const [showOauthTokenEndpoint, setShowOauthTokenEndpoint] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const clientId = currentUser?.currentUser?.clientIdUsers;

  useEffect(() => {
    console.log("Fetching client settings...");
    const client = ClientsList.find((c) => c.id === clientId);
    console.log("Client", client);
    if (client?.settings?.instagram) {
      const settings = client.settings.instagram;
      setIsInstagramEnabled(settings.isInstagramEnabled || false);
      setInstagramAccessToken(settings.instagramAccessToken || "");
      setFacebookApiUrl(
        settings.facebookApiUrl || "https://graph.facebook.com/v16.0"
      );
      setFacebookAppId(settings.facebookAppId || "");
      setFacebookAppSecret(settings.facebookAppSecret || "");
      setOauthTokenEndpoint(
        settings.oauthTokenEndpoint ||
          "https://graph.facebook.com/v16.0/oauth/access_token"
      );
      console.log("Initialized state with Instagram settings:", settings);
    }
  }, [ClientsList, clientId]);

  const handleInstagramToggle = (event) => {
    setIsInstagramEnabled(event.target.checked);
  };

  const handleInstagramAccessTokenChange = (event) => {
    setInstagramAccessToken(event.target.value);
  };

  const handleFacebookApiUrlChange = (event) => {
    setFacebookApiUrl(event.target.value);
  };

  const handleFacebookAppIdChange = (event) => {
    setFacebookAppId(event.target.value);
  };

  const handleFacebookAppSecretChange = (event) => {
    setFacebookAppSecret(event.target.value);
  };

  const handleOauthTokenEndpointChange = (event) => {
    setOauthTokenEndpoint(event.target.value);
  };

  const updateClientSettings = async () => {
    const payload = {
      instagram: {
        isInstagramEnabled,
        instagramAccessToken,
        facebookApiUrl,
        facebookAppId,
        facebookAppSecret,
        oauthTokenEndpoint,
      },
    };

    console.log("Payload to be sent:", payload);

    try {
      console.log(
        "Sending request to:",
        `${CLIENTS_URL}/${clientId}/settings/`
      );
      const response = await axiosInstance.put(
        `${CLIENTS_URL}/${clientId}/settings/`,
        payload
      );

      console.log("Response received from server:", response);

      if (!response || !response.data) {
        console.error("Invalid response from server");
        throw new Error("Invalid response from server");
      }

      console.log("Response data:", response.data);

      const updatedClient = {
        id: clientId,
        settings: {
          ...ClientsList.find((c) => c.id === clientId).settings,
          instagram: {
            isInstagramEnabled: response.data.instagram.isInstagramEnabled,
            instagramAccessToken: response.data.instagram.instagramAccessToken,
            facebookApiUrl: response.data.instagram.facebookApiUrl,
            facebookAppId: response.data.instagram.facebookAppId,
            facebookAppSecret: response.data.instagram.facebookAppSecret,
            oauthTokenEndpoint: response.data.instagram.oauthTokenEndpoint,
          },
        },
      };

      console.log("Updated client to be dispatched:", updatedClient);

      dispatch(editClient(updatedClient));

      // Show Snackbar on successful update
      setSnackbarOpen(true);

      console.log("State updated with new values");
    } catch (error) {
      console.error("Error updating settings", error);
      if (error.response) {
        console.error("Response error data:", error.response.data);
        console.error("Response error status:", error.response.status);
        console.error("Response error headers:", error.response.headers);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={isInstagramEnabled}
            onChange={handleInstagramToggle}
            size='small'
          />
        }
        label='Instagram'
        sx={{
          "& .MuiTypography-body1": { fontSize: "0.875rem" },
        }}
      />
      {isInstagramEnabled && (
        <>
          <TextField
            label='Instagram Access Token'
            variant='outlined'
            fullWidth
            size='small'
            type={showInstagramAccessToken ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowInstagramAccessToken(!showInstagramAccessToken)
                    }
                  >
                    {showInstagramAccessToken ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={instagramAccessToken}
            onChange={handleInstagramAccessTokenChange}
          />
          <TextField
            label='Facebook API URL'
            variant='outlined'
            fullWidth
            size='small'
            type={showFacebookApiUrl ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowFacebookApiUrl(!showFacebookApiUrl)}
                  >
                    {showFacebookApiUrl ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={facebookApiUrl}
            onChange={handleFacebookApiUrlChange}
          />
          <TextField
            label='Facebook App ID'
            variant='outlined'
            fullWidth
            size='small'
            type={showFacebookAppId ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowFacebookAppId(!showFacebookAppId)}
                  >
                    {showFacebookAppId ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={facebookAppId}
            onChange={handleFacebookAppIdChange}
          />
          <TextField
            label='Facebook App Secret'
            variant='outlined'
            fullWidth
            size='small'
            type={showFacebookAppSecret ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowFacebookAppSecret(!showFacebookAppSecret)
                    }
                  >
                    {showFacebookAppSecret ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={facebookAppSecret}
            onChange={handleFacebookAppSecretChange}
          />
          <TextField
            label='OAuth Token Endpoint'
            variant='outlined'
            fullWidth
            size='small'
            type={showOauthTokenEndpoint ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowOauthTokenEndpoint(!showOauthTokenEndpoint)
                    }
                  >
                    {showOauthTokenEndpoint ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={oauthTokenEndpoint}
            onChange={handleOauthTokenEndpointChange}
          />
        </>
      )}
      <Button
        variant='contained'
        color='primary'
        onClick={updateClientSettings}
        sx={{ height: "fit-content" }}
      >
        Update
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='success'
          iconMapping={{
            success: <CheckCircleIcon style={{ color: "white" }} />,
          }}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          You updated Instagram settings!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InstagramSettings;
