import React, { useState, useEffect } from "react";
import {
  Switch,
  FormControlLabel,
  TextField,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axiosInstance";
import { CLIENTS_URL } from "../../../config";
import { editClient } from "../../clients/features/clientSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const FacebookSettings = ({ currentUser }) => {
  const ClientsList = useSelector((store) => store.clients);
  const [isFacebookEnabled, setIsFacebookEnabled] = useState(false);
  const [facebookAccessToken, setFacebookAccessToken] = useState("");
  const [facebookAppSecret, setFacebookAppSecret] = useState("");
  const [facebookPageId, setFacebookPageId] = useState("");
  const [facebookApiUrl, setFacebookApiUrl] = useState(
    "https://graph.facebook.com/v16.0"
  );
  const [facebookOauthTokenEndpoint, setFacebookOauthTokenEndpoint] = useState(
    "https://graph.facebook.com/v16.0/oauth/access_token"
  );
  const [facebookAccountId, setFacebookAccountId] = useState("");
  const [showAccessToken, setShowAccessToken] = useState(false);
  const [showAppSecret, setShowAppSecret] = useState(false);
  const [showPageId, setShowPageId] = useState(false);
  const [showApiUrl, setShowApiUrl] = useState(false);
  const [showOauthTokenEndpoint, setShowOauthTokenEndpoint] = useState(false);
  const [showAccountId, setShowAccountId] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const clientId = currentUser?.currentUser?.clientIdUsers;

  useEffect(() => {
    console.log("Fetching client settings...");
    const client = ClientsList.find((c) => c.id === clientId);
    console.log("Client", client);
    if (client?.settings?.facebook) {
      const settings = client.settings.facebook;
      setIsFacebookEnabled(settings.isFacebookEnabled || false);
      setFacebookAccessToken(settings.facebookAccessToken || "");
      setFacebookAppSecret(settings.facebookAppSecret || "");
      setFacebookPageId(settings.facebookPageId || "");
      setFacebookApiUrl(
        settings.facebookApiUrl || "https://graph.facebook.com/v16.0"
      );
      setFacebookOauthTokenEndpoint(
        settings.facebookOauthTokenEndpoint ||
          "https://graph.facebook.com/v16.0/oauth/access_token"
      );
      setFacebookAccountId(settings.facebookAccountId || "");
      console.log("Initialized state with Facebook settings:", settings);
    }
  }, [ClientsList, clientId]);

  const handleFacebookToggle = (event) => {
    setIsFacebookEnabled(event.target.checked);
  };

  const handleFacebookAccessTokenChange = (event) => {
    setFacebookAccessToken(event.target.value);
  };

  const handleFacebookAppSecretChange = (event) => {
    setFacebookAppSecret(event.target.value);
  };

  const handleFacebookPageIdChange = (event) => {
    setFacebookPageId(event.target.value);
  };

  const handleFacebookApiUrlChange = (event) => {
    setFacebookApiUrl(event.target.value);
  };

  const handleFacebookOauthTokenEndpointChange = (event) => {
    setFacebookOauthTokenEndpoint(event.target.value);
  };

  const handleFacebookAccountIdChange = (event) => {
    setFacebookAccountId(event.target.value);
  };

  const updateClientSettings = async () => {
    const payload = {
      facebook: {
        isFacebookEnabled,
        facebookAccessToken,
        facebookAppSecret,
        facebookPageId,
        facebookApiUrl,
        facebookOauthTokenEndpoint,
        facebookAccountId,
      },
    };

    console.log("Payload to be sent:", payload);

    try {
      console.log(
        "Sending request to:",
        `${CLIENTS_URL}/${clientId}/settings/`
      );
      const response = await axiosInstance.put(
        `${CLIENTS_URL}/${clientId}/settings/`,
        payload
      );

      console.log("Response received from server:", response);

      if (!response || !response.data) {
        console.error("Invalid response from server");
        throw new Error("Invalid response from server");
      }

      console.log("Response data:", response.data);

      const updatedClient = {
        id: clientId,
        settings: {
          ...ClientsList.find((c) => c.id === clientId).settings,
          facebook: {
            isFacebookEnabled: response.data.facebook.isFacebookEnabled,
            facebookAccessToken: response.data.facebook.facebookAccessToken,
            facebookAppSecret: response.data.facebook.facebookAppSecret,
            facebookPageId: response.data.facebook.facebookPageId,
            facebookApiUrl: response.data.facebook.facebookApiUrl,
            facebookOauthTokenEndpoint:
              response.data.facebook.facebookOauthTokenEndpoint,
            facebookAccountId: response.data.facebook.facebookAccountId,
          },
        },
      };

      console.log("Updated client to be dispatched:", updatedClient);

      dispatch(editClient(updatedClient));

      // Show Snackbar on successful update
      setSnackbarOpen(true);

      console.log("State updated with new values");
    } catch (error) {
      console.error("Error updating settings", error);
      if (error.response) {
        console.error("Response error data:", error.response.data);
        console.error("Response error status:", error.response.status);
        console.error("Response error headers:", error.response.headers);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={isFacebookEnabled}
            onChange={handleFacebookToggle}
            size='small'
          />
        }
        label='Facebook'
        sx={{
          "& .MuiTypography-body1": { fontSize: "0.875rem" },
        }}
      />
      {isFacebookEnabled && (
        <>
          <TextField
            label='Facebook Access Token'
            variant='outlined'
            fullWidth
            size='small'
            type={showAccessToken ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowAccessToken(!showAccessToken)}
                  >
                    {showAccessToken ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={facebookAccessToken}
            onChange={handleFacebookAccessTokenChange}
          />
          <TextField
            label='Facebook App Secret'
            variant='outlined'
            fullWidth
            size='small'
            type={showAppSecret ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowAppSecret(!showAppSecret)}>
                    {showAppSecret ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={facebookAppSecret}
            onChange={handleFacebookAppSecretChange}
          />
          <TextField
            label='Facebook Page ID'
            variant='outlined'
            fullWidth
            size='small'
            type={showPageId ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPageId(!showPageId)}>
                    {showPageId ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={facebookPageId}
            onChange={handleFacebookPageIdChange}
          />
          <TextField
            label='Facebook API URL'
            variant='outlined'
            fullWidth
            size='small'
            type={showApiUrl ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowApiUrl(!showApiUrl)}>
                    {showApiUrl ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={facebookApiUrl}
            onChange={handleFacebookApiUrlChange}
          />
          <TextField
            label='Facebook OAuth Token Endpoint'
            variant='outlined'
            fullWidth
            size='small'
            type={showOauthTokenEndpoint ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowOauthTokenEndpoint(!showOauthTokenEndpoint)
                    }
                  >
                    {showOauthTokenEndpoint ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={facebookOauthTokenEndpoint}
            onChange={handleFacebookOauthTokenEndpointChange}
          />
          <TextField
            label='Facebook Ad Account ID'
            variant='outlined'
            fullWidth
            size='small'
            type={showAccountId ? "text" : "password"}
            InputProps={{
              sx: { fontSize: "0.875rem", padding: "2px" },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowAccountId(!showAccountId)}>
                    {showAccountId ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: { fontSize: "0.875rem" },
            }}
            value={facebookAccountId}
            onChange={handleFacebookAccountIdChange}
          />
        </>
      )}
      <Button
        variant='contained'
        color='primary'
        onClick={updateClientSettings}
        sx={{ height: "fit-content" }}
      >
        Update
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='success'
          iconMapping={{
            success: <CheckCircleIcon style={{ color: "white" }} />,
          }}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          You updated Facebook settings!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FacebookSettings;
